import React from "react";
import { Menu, Dropdown } from "antd";

import { DownOutlined } from "@ant-design/icons";

import styled from "styled-components";

const FilterSpace = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  && > * + * {
    margin-left: 10px;
  }
`;

const FilterDropdownWrapper = styled(Dropdown)`
  display: flex;
  padding: 8px 8px;
  border-radius: 8px;
  border: 1px solid #dce1e8;
  align-items: center;
  justify-content: space-between;
  min-width: 250px;
  margin-bottom: 24px;

  && > div {
    display: flex;
  }
`;

const MenuLink = styled.a`
  cursor: pointer;
`;

const sortData = {
  default: {
    name: "по умолчанию",
    sort: (data) => data.sort((a, b) => a?.ordering - b?.ordering),
  },
  desc: {
    name: "по убыванию",
    sort: (data) => data.sort((a, b) => b?.value - a?.value),
  },
  asc: {
    name: "по возрастанию",
    sort: (data) => data.sort((a, b) => a?.value - b?.value),
  },
};

const MenuFilter = ({ data, setFilterSort }) => {
  return (
    <Menu>
      {data &&
        Object.keys(data).map((key) => (
          <Menu.Item
            key={`menu:filter::${key}`}
            onClick={() => setFilterSort(key)}
          >
            <MenuLink>{data[key]?.name}</MenuLink>
          </Menu.Item>
        ))}
    </Menu>
  );
};

const FilterDropdown = ({ filterSort, setFilterSort }) => {
  return (
    <FilterSpace>
      <FilterDropdownWrapper
        overlay={
          <MenuFilter data={sortData} {...{ filterSort, setFilterSort }} />
        }
      >
        <div>
          <span>Упорядочить: {filterSort && sortData[filterSort].name}</span>
          <DownOutlined />
        </div>
      </FilterDropdownWrapper>
    </FilterSpace>
  );
};

export { FilterDropdown, sortData };
