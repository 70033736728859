import React from "react";
import ReactDOM from "react-dom";

import "./globals";
import "./methods";
import App from "./App";
import YMCounter from "./components/yandex-metrika-counter";

const counter = process.env.REACT_APP_YANDEX_METRIKA;

ReactDOM.render(
  <>
    {counter && <YMCounter id={parseInt(counter)} />}
    <App />
  </>,
  document.getElementById("app")
);
