import React from "react";

import { Card, Checkbox } from "antd";
import styled from "styled-components";

import { List } from "antd";

const AnswerVocCard = styled(Card)`
  border-radius: 8px;
  padding-top: 12px;
  margin-bottom: 16px;
  padding-top: 0px;

  & .ant-card-head-title {
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: black;
  }

  & .ant-card-body {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  & .ant-card-head {
    padding-left: 16px;
    padding-right: 16px;
  }

  && .ant-card-actions li {
    width: max-content !important;
    padding-left: 10px;
  }
`;

const AnswerWrapper = styled.div`
  display: flex;

  && > * + * {
    margin-left: 10px;
  }
`;

const VariantSection = ({ data, answers }) => {
  return (
    <>
      {data && (
        <List
          itemLayout="vertical"
          size="large"
          pagination={{
            defaultPageSize: 20,
          }}
          dataSource={data}
          renderItem={({ message }) => {
            const { attachment } = message;

            const { variant } = attachment;

            return (
              <AnswerVocCard
                actions={[`1 ответ`]}
              >
                {variant && (
                  <AnswerWrapper>
                    <Checkbox defaultChecked disabled />
                    <span>
                      {variant && answers[variant.split("answer")[1]]
                        ? answers[variant.split("answer")[1]].name
                        : variant}
                    </span>
                  </AnswerWrapper>
                )}
              </AnswerVocCard>
            );
          }}
        />
      )}
    </>
  );
};

export default VariantSection;
