import { gql } from "@apollo/client";

const FEEDBACK_QUERY = gql`
  query getFeedback($id: bigint!) {
    chatFeedback(id: $id) {
      id
      bill
      category
      comments_aggregate {
        aggregate {
          count
        }
      }
      chat_user {
        first_name
        full_name
        last_name
        nickname
      }
      cr
      feedback_date
      is_in_bar
      is_published
      photos
      message
      title
      tags
      rating
    }
  }
`;

const UPDATE_FEEDBACK_QUERY = gql`
  mutation updfeedback(
    $data: dialog_chat_feedback_sat_set_input!
    $id: bigint!
  ) {
    updateFeedback(_set: $data, pk_columns: { id: $id }) {
      id
      message
    }
  }
`;

const CHECK_FEEDBACK_QUERY = gql`
  query CheckFeedback($bot_id: uuid, $project_id: uuid) {
    chatFeedbacks_aggregate(
      where: {
        chat: {
          bot: {
            id: { _eq: $bot_id }
            project_link: { project_id: { _eq: $project_id } }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const ALL_FEEDBACKS_QUERY = gql`
  query MyQuery(
    $order_by: [dialog_chat_feedback_sat_order_by!]
    $search_by_string: String
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
    $bot_id: dialog_chat_hub_bool_exp
  ) {
    chatFeedbacks(
      order_by: $order_by
      where: {
        _and: [
          {
            chat: {
              bot: { project_link: { project_id: { _eq: $project_id } } }
            }
          }
          { chat: $bot_id }
        ]
        message: { _ilike: $search_by_string }
        cr: { _gte: $start_date, _lte: $end_date }
      }
    ) {
      id
      message
      cr
      is_in_bar
      is_published
      title
      chat_user {
        nickname
      }
      chat {
        bot {
          platform {
            name
          }
        }
      }
    }
  }
`;

export {
  ALL_FEEDBACKS_QUERY,
  CHECK_FEEDBACK_QUERY,
  FEEDBACK_QUERY,
  UPDATE_FEEDBACK_QUERY,
};
/* $and: [dialog_chat_feedback_sat_bool_exp] */
/* _and: $and,  */
