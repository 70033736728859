//основной костяк для variants, variant, rating  блоки

import React, { useMemo, useState } from "react";
import { Skeleton, Col, Row, Radio } from "antd";
import { FilterDropdown } from "./filter-dropdown";

import { PieChartOutlined, BarChartOutlined } from "@ant-design/icons";

import GraphicsChart from "./graphics-chart";

import { showBarConfigs, showPieConfigs } from "../helpers/graphics-config";

import { prepareDataAnswers } from "../helpers/prepare-dialog-answers";

/* Тип ответов — variant*/

const VariantsStructureBlock = ({
  answers,
  dialog_answers,
  loading,
  type = "variant",
}) => {
  /* стэйты для сортировки */
  const [filterSort, setFilterSort] = useState("default"); // default, desc, asc

  console.log("answers", answers);

  /* какой тип графика отображать */
  const [graphicsType, setGrapicsType] = useState("bar"); //bar, pie

  /* здесь основная обработка ответов и заведение их под каждый item */
  const updatedDialogAnswers = useMemo(() => {
    // Перепроверить позже, верно ли суммираются значения
    return prepareDataAnswers({
      items: dialog_answers,
      answers,
      type,
    });
  }, [answers, dialog_answers]);

  //настройки для барчарта
  const barConfig = useMemo(() => {
    return showBarConfigs({
      sort: filterSort,
      answers: updatedDialogAnswers,
      items: dialog_answers,
    });
  }, [updatedDialogAnswers, dialog_answers, filterSort]);

  //настройки для пайчарта
  const pieConfig = useMemo(() => {
    return showPieConfigs({ answers: updatedDialogAnswers });
  }, [updatedDialogAnswers]);

  return (
    <Skeleton active loading={loading}>
      <Row justify={"space-between"}>
        <Col>
          <FilterDropdown {...{ filterSort, setFilterSort }} />
        </Col>
        <Col>
          <Radio.Group value={graphicsType}>
            <Radio.Button
              icon={<BarChartOutlined />}
              value="bar"
              onClick={() => setGrapicsType("bar")}
            >
              Барчарт
            </Radio.Button>
            <Radio.Button
              icon={<PieChartOutlined />}
              value="pie"
              onClick={() => setGrapicsType("pie")}
            >
              Пайчарт
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <GraphicsChart
        barConfig={barConfig}
        pieConfig={pieConfig}
        currentType={graphicsType}
      />
    </Skeleton>
  );
};

export default VariantsStructureBlock;
