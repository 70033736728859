import React from "react";
import { StyledCard } from "../../Styled/styled";

const SectionWrapper = ({ children }) => {
  return (
    <div className="mt-25">
      <div className="rui-profile row">
        <div className="col-12">
          <StyledCard>{children}</StyledCard>
        </div>
      </div>
    </div>
  );
};

const HeaderWrapper = ({ children }) => {
  return (
    <div className="mt-25">
      <div className="rui-profile row vertical-gap">
        <div className="col-12">
          <StyledCard background={"#FBFBFB"} className="card">
            <div className="card-body">{children}</div>
          </StyledCard>
        </div>
      </div>
    </div>
  );
};

export {SectionWrapper, HeaderWrapper};
