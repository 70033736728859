import styled from "styled-components";
import { Typography, Button, Input, Row, Form } from "antd";

const { Title, Paragraph } = Typography;
const { Password } = Input;
const { Item } = Form;

const darkGrey = "#47464F";
const lightGrey = "#7C8496";
const lightPurple = "#DFE4FF";

const CItem = styled(Item)`
  && label {
    font-size: 12px;
  }

  && .ant-form-item-label {
    padding-bottom: 0;
  }
`;

const SInput = styled(Input)`
  && {
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;

    border-bottom: 1px solid ${lightPurple};

    padding-left: 0;
    padding-right: 0;

    font-size: 18px;
    font-weight: 500;

    color: ${darkGrey};

    &[disabled] {
      background: white;
      color: ${lightGrey};
    }

    &:focus {
      box-shadow: none;

      border-bottom: 1px solid ${darkGrey};
    }
  }
`;

const SPassword = styled(Password)`
  && {
    border-radius: 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;

    border-bottom: 1px solid ${lightPurple};

    padding-left: 0;
    padding-right: 0;

    &.ant-input-affix-wrapper-disabled {
      background-color: white;
    }

    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
      border-bottom: 1px solid ${darkGrey};
    }

    & input {
      font-size: 18px;
      font-weight: 500;
      color: ${darkGrey};

      &[disabled] {
        color: ${lightGrey};
      }
    }
  }
`;

const CTitle = styled(Title)`
  &&&& {
    font-weight: 700;
    color: ${darkGrey};
  }

  &[data-margin="nomargin"] {
    margin-bottom: 0;
  }

  &[data-size="24"] {
    font-size: 24px;
  }

  &[data-size="18"] {
    font-size: 18px;
  }

  &[data-size="14"] {
    font-size: 14px;
  }

  &[data-color="purple"] {
    color: #5c5ef9;
  }
`;

const CButton = styled(Button)`
  border-radius: 100px;

  height: 32px;
  padding-left: 16px;
  padding-right: 16px;

  & span {
    font-weight: 700;
    font-size: 14px;
  }

  &[data-type="wp"] {
    border: 1px solid #5c5ef9;
    background: white;
    color: black;
  }

  &&&[data-type="p"] {
    border: 1px solid #5c5ef9;
    background: #5c5ef9;
    color: white;

    &[disabled] {
      background: #e8e8e8;
      border: 1px solid #e8e8e8;
      min-width: 80px;
    }
  }

  &&[disabled],
  &&[disabled]:hover {
    & span {
      color: #a4a4a4;
    }

    color: #a4a4a4;
    background: white;
    border-color: #a4a4a4;
  }
`;

const CParagraph = styled(Paragraph)`
  font-size: 14px;
  color: #171717;

  &[data-type="link"] {
    &:hover {
      color: black;
    }
  }

  &[data-margin="nomargin"] {
    margin-bottom: 0px;
  }

  &[data-size="12"] {
    font-size: 12px;
  }

  &[data-color="lightgrey"] {
    color: ${lightGrey};
  }
`;

const Gap = styled.div`
  width: ${({ swidth }) => (swidth ? swidth : "100%")};
  height: ${({ sheight }) => (sheight ? sheight : "100%")};
`;

const Purple = styled.span`
  color: #5c5ef9;
`;

const ArrIcon = styled.div`
  width: 12px;
  height: 6px;

  background: url("/icons/billing/arrow.svg");
  background-size: cover;
`;

const SRow = styled(Row)`
  border-top: 1px solid #ebebff;

  &[data-active="disabled"] * {
    color: rgba(0, 0, 0, 0.5);
  }

  &[data-type="large"] {
    padding-top: 18px;
    padding-bottom: 32px;
  }

  &[data-type="small"] {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &[data-top="notop"] {
    padding-top: 0px;
    border-top: 1px solid white;
  }

  &&&[data-border="transparent"] {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }
`;

export {
  CTitle,
  CButton,
  SInput,
  SPassword,
  CParagraph,
  Gap,
  Purple,
  ArrIcon,
  SRow,
  CItem,
};
