/**
 * Styles
 */
import "./style.less";
import "./style.scss";

/**
 * External Dependencies
 */
const jQuery = require("jquery");

window.jQuery = jQuery;
window.$ = jQuery;

/*------------------------------------------------------------------

  RootUI Class

-------------------------------------------------------------------*/
class ROOTUI {}
window.RootUI = new ROOTUI();
