import React, { lazy, Suspense } from "react";

import PageLoader from "../../../components/page-loader";

const Recovery = lazy(() => import("./content"));

const RecoveryPage = () => (
  <Suspense fallback={<PageLoader full />}>
    <Recovery />
  </Suspense>
);

export default RecoveryPage;
