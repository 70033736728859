import { ContextHeader } from "../../../../../env";
import { useLazyQuery } from "@apollo/client";

export const queryHeader = {
  context: {
    headers: {
      "x-hasura-admin-secret": ContextHeader,
    },
  },
};

export const makeQuery = (queryType) => {
  return useLazyQuery(queryType, queryHeader);
};

