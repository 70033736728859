/**
 * Created by Stainwoortsel on 04.03.2017.
 */
import { appConfig } from "../env";
import axios from "axios";
import Tokenizer from "../helpers/tokenizer";

const directAxios = axios.create({
  baseURL: appConfig.api.base,
  responseType: appConfig.api.type,
});

export const authAxios = function (state) {
  const token = state.auth.access_token;

  return axios.create({
    baseURL: appConfig.api.base,
    responseType: appConfig.api.type,
    headers: {
      Authorization: Tokenizer.authHeader(token),
    },
  });
};

export const xlsxAxios = function (state) {
  const token = state.auth.access_token;

  return axios.create({
    baseURL: appConfig.api.chg_base,
    responseType: appConfig.api.type,
    headers: {
      Authorization: Tokenizer.authHeader(token),
    },
  });
};

export const xlsx1Axios = () => {
  return axios.create({
    baseURL: appConfig.api.chg_base,
    responseType: appConfig.api.type,
  });
};

export const dadataAxios = function () {
  return axios.create({
    baseURL: appConfig.api.base,
    responseType: appConfig.api.type,
    headers: {
      Authorization: `Token ${appConfig.auth.dadata}`,
    },
  });
};

export default directAxios;
