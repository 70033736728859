import { StyledH2, StyledCard } from "../Styled/styled";
import React from "react";
import { Space } from "antd";
import styled from "styled-components";

const StyledSpace = styled(Space)`
  justify-content: space-between;
  display: flex;

  && .ant-space-item:first-child {
    width: calc(100% - 300px);
  }

  && .ant-space-item:last-child {
    width: 270px;
  }
`;

const LeftCard = styled(StyledCard)``;

const CardsFlex = styled.div`
  display: ${({ type }) => (type === "full" ? "flex" : "grid")};
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
`;

const CardsBody = styled.div`
  width: 100%;
  height: 110px;
  background: ${({ type }) => (type === "full" ? "#5C5EF9" : "#d5ccf4")};
  border-radius: 8px;
  padding: 15px 15px;
  display: flex;
  align-items: end;
  justify-content: ${({ type }) => (type === "full" ? "center" : "start")};
  position: relative;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &&:hover {
    box-shadow: 0 0.25rem 1rem
      ${({ type }) => (type === "full" ? "#5C5EF9" : "#d5ccf4")};
    -webkit-transform: translateY(-0.25rem);
    -ms-transform: translateY(-0.25rem);
    transform: translateY(-0.25rem);
  }

  &&::before {
    content: "";
    position: absolute;
    top: 15px;
    right: 20px;
    width: 14px;
    height: 14px;
    background: url("/icons/info.svg");
    visibility: ${({ type }) => (type === "full" ? "hidden" : "initial")};
  }
`;

CardsBody.Title = styled.div`
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  line-height: 140%;
`;

const Maincards = () => {
  const GoToPage = (link, local) => {
    window.open(link, local ? "_self" : "_blank");
  };

  return (
    <>
      <div className="rui-profile row vertical-gap">
        <div className="col-12">
          <StyledSpace size={30} align={"baseline"}>
            <LeftCard className="card">
              <div className="card-body">
                <StyledH2 className="card-title mnb-6 mr-auto">
                  Панель управления
                </StyledH2>
                <div className="rui-profile-numbers row row vertical-gap">
                  <div className="col-12">
                    <div>
                      <CardsFlex>
                        <CardsBody
                          onClick={() =>
                            GoToPage(
                              "https://baykalsk.chg.one/admin/plugins/content-manager/collectionType/application::book-section.book-section?page=1&pageSize=10&_sort=id:ASC"
                            )
                          }
                        >
                          <CardsBody.Title>
                            Электронная книга цифрового мастер-плана
                          </CardsBody.Title>
                        </CardsBody>
                        <CardsBody
                          onClick={() =>
                            GoToPage(
                              "https://baykalsk.chg.one/admin/plugins/content-manager/collectionType/application::analysis-section.analysis-section?page=1&pageSize=10&_sort=title:ASC"
                            )
                          }
                        >
                          <CardsBody.Title>
                            Комплексный анализ города Байкальск
                          </CardsBody.Title>
                        </CardsBody>
                        <CardsBody
                          onClick={() =>
                            GoToPage(
                              "https://docs.google.com/spreadsheets/d/1FfiIRdqCviOFoULlrzRoNGYYFaEjPM_K1uDca1rh8Ys/edit?usp=sharing"
                            )
                          }
                        >
                          <CardsBody.Title>
                            Таблица мероприятий цифрового мастер-плана
                          </CardsBody.Title>
                        </CardsBody>
                        <CardsBody
                          onClick={() =>
                            GoToPage(
                              "https://baykalsk.chg.one/admin/plugins/content-manager/collectionType/application::post.post?page=1&pageSize=10&_sort=title:ASC"
                            )
                          }
                        >
                          <CardsBody.Title>Новости</CardsBody.Title>
                        </CardsBody>
                        <CardsBody
                          onClick={() =>
                            GoToPage(
                              "https://baykalsk.chg.one/admin/plugins/content-manager/collectionType/application::event.event?page=1&pageSize=10&_sort=url_location:ASC"
                            )
                          }
                        >
                          <CardsBody.Title>Мероприятия</CardsBody.Title>
                        </CardsBody>
                      </CardsFlex>
                    </div>
                  </div>
                </div>
              </div>
            </LeftCard>
            <StyledCard className="card">
              <div className="card-body">
                <StyledH2 className="card-title mnb-6 mr-auto">
                  Инструменты
                </StyledH2>
                <div className="rui-profile-numbers row row vertical-gap">
                  <div className="col-12">
                    <div>
                      <CardsFlex type="full">
                        <CardsBody
                          type="full"
                          onClick={() => GoToPage("/project/tools/", true)}
                        >
                          <CardsBody.Title>Все инструменты</CardsBody.Title>
                        </CardsBody>
                      </CardsFlex>
                    </div>
                  </div>
                </div>
              </div>
            </StyledCard>
          </StyledSpace>
        </div>
      </div>
    </>
  );
};

export default Maincards;
