/**
 * External Dependencies
 */
import {authAxios, xlsxAxios} from "../clients/directAxios";
import handleError from "../errors/handleError";
import {ApiEndpoint} from "../env";
import {pick} from "../helpers/fn";
import {getDataAction} from "./abs";
import client from "../clients/gql-client";
import {
    CREATES_SECTIONS_NODES_MUTATION,
    DELETE_SECTIONS_NODES_MUTATION,
    UPSERT_VOC_MUTATION,
    BOT_DIALOG_QUERY,
} from "../pages/Crowdfunding/Crowdsourcing/CreateTool/query";
import {
    CREATE_PROJECT_MUTATION,
    CREATE_USER_LINK_MUTATION,
} from "../pages/Crowdfunding/Crowdsourcing/CreateNewProject/query";
import Tokenizer from "../helpers/tokenizer";
import moment from "moment";

// import axios from 'axios';

// const CancelToken = axios.CancelToken;

const api = new ApiEndpoint("app");
const xlsx_api = new ApiEndpoint("xlsx");

export const APP_PROJECTS = "APP_PROJECTS";
export const loadProjects = () => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .get(api.endpoint("/projects"))
            .then((res) => {
                dispatch({
                    type: APP_PROJECTS,
                    payload: res.data,
                });

                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const APP_TOOLS = "APP_TOOLS";
let loadToolsCache = []
export const loadTools = (project_id) => (dispatch, getState) => {

    const existCache = loadToolsCache.find(item => item.project_id === project_id)

    if (existCache) {
        return existCache.promise
    }

    const promise = authAxios(getState())
        .get(api.endpoint(`/projects/${project_id}/tools`))
        .then((res) => {
            loadToolsCache = loadToolsCache.filter(item => item.project_id !== project_id)

            dispatch({
                type: APP_TOOLS,
                payload: res.data,
            });
            return Promise.resolve(res.data);
        })
        .catch((error) => {
            loadToolsCache = loadToolsCache.filter(item => item.project_id !== project_id)

            handleError(error, getState(), dispatch);
            return Promise.reject(error);
        });

    loadToolsCache.push({project_id, promise})

    return promise
}

export const SET_CURRENT_PROJECT_ID = "SET_CURRENT_PROJECT_ID";
export const APP_CURRENT_PROJECT = "APP_CURRENT_PROJECT";
export const setCurrentProject = (project_id) => {
    return {
        type: SET_CURRENT_PROJECT_ID,
        payload: project_id,
    };
};

export const APP_PROJECT_LOADING = "APP_PROJECT_LOADING";
export const APP_PROJECT_LOADED = "APP_PROJECT_LOADED";
export const appIsLoading = (isLoading = true) => {
    if (isLoading) {
        return {
            type: APP_PROJECT_LOADING,
        };
    }

    return {
        type: APP_PROJECT_LOADED,
    };
};

let loadCurrentProjectCache = []
export const loadCurrentProject = (project_id = null) => {
    return function (dispatch, getState) {
        let request_project_id = project_id;
        if (!project_id) {
            //const { app } = getState();
            //request_project_id = app.currentProjectId;
            request_project_id = localStorage.getItem("currentProjectId");
        }

        if (request_project_id === "null") {
            return Promise.resolve();
        }


        const existCache = loadCurrentProjectCache.find(item => item.project_id === request_project_id)

        if (existCache) {
            return existCache.promise
        }

        dispatch(appIsLoading(true));

        const promise = authAxios(getState())
            .get(api.endpoint("/projects/current"), {
                params: {
                    project_id: request_project_id,
                },
            })
            .then((res) => {
                loadCurrentProjectCache = loadCurrentProjectCache.filter(item => item.project_id !== request_project_id)

                dispatch({
                    type: APP_CURRENT_PROJECT,
                    payload: res.data,
                });

                dispatch(appIsLoading(false));

                dispatch(
                    setCurrentProject(
                        project_id ? project_id : pick("data.project_id", res, null)
                    )
                );

                return Promise.resolve(res.data);
            })
            .catch((error) => {
                loadCurrentProjectCache = loadCurrentProjectCache.filter(item => item.project_id !== request_project_id)

                dispatch(appIsLoading(false));
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });

        loadCurrentProjectCache.push({project_id: request_project_id, promise})

        return promise;
    };
};

// export const APP_SAVE_PROJECT = "APP_SAVE_PROJECT";
// export const saveProject = (formData) => {
//   return function (dispatch, getState) {
//     return authAxios(getState())
//       .post(api.endpoint("/projects/leadform"), formData)
//       .then((res) => {
//         dispatch({
//           type: APP_SAVE_PROJECT,
//           payload: res.data,
//         });
//
//         return Promise.resolve(res.data);
//       })
//       .catch((error) => {
//         handleError(error, getState(), dispatch);
//         return Promise.reject(error);
//       });
//   };
// };

export const APP_SAVE_PROJECT = "APP_SAVE_PROJECT";
export const saveProject =
    ({key, id, about, name}) =>
        (dispatch, getState) => {
            const cr = moment().format("YYYY-MM-DD");
            const token = getState().auth.access_token;
            return client
                .mutate({
                    mutation: CREATE_PROJECT_MUTATION,
                    variables: {id, key, name, about},
                    context: {
                        headers: {
                            Authorization: Tokenizer.authHeader(token),
                        },
                    },
                })
                .then((res) => {
                    dispatch({
                        type: APP_SAVE_PROJECT,
                        payload: res.data,
                    });

                    return client.mutate({
                        mutation: CREATE_USER_LINK_MUTATION,
                        variables: {cr},
                        context: {
                            headers: {
                                Authorization: Tokenizer.authHeader(token),
                            },
                        },
                    });
                })
                .then((res) => {
                    return Promise.resolve(res.data);
                })
                .catch((error) => {
                    if (
                        error?.message.includes(
                            'Uniqueness violation. duplicate key value violates unique constraint "client_user_link_client_id_user_id_key"'
                        )
                    ) {
                        //привязка уже была создана
                        return Promise.resolve(true);
                    } else {
                        handleError(error, getState(), dispatch);
                        return Promise.reject(error);
                    }
                });
        };

export const DELETE_SECTIONS_NODES = "DELETE_SECTIONS_NODES_MUTATION";
export const deleteSectionsNodes =
    ({bot_id}) =>
        (dispatch, getState) => {
            const token = getState().auth.access_token;
            return client
                .mutate({
                    mutation: DELETE_SECTIONS_NODES_MUTATION,
                    variables: {bot_id},
                    context: {
                        headers: {
                            Authorization: Tokenizer.authHeader(token),
                        },
                    },
                })
                .then((res) => Promise.resolve(res.data))
                .catch((error) => {
                    console.log(error);
                    handleError(error, getState(), dispatch);
                    return Promise.reject(error);
                });
        };

export const CREATE_NODE_SECTIONS = "CREATE_NODE_SECTIONS";
export const createNodeSections =
    ({sections, node_sections, nodes, answers}) =>
        (dispatch, getState) => {
            const token = getState().auth.access_token;
            return client
                .mutate({
                    mutation: CREATES_SECTIONS_NODES_MUTATION,
                    variables: {answers, sections, node_sections, nodes},
                    context: {
                        headers: {
                            Authorization: Tokenizer.authHeader(token),
                        },
                    },
                })
                .then((res) => Promise.resolve(res.data))
                .catch((error) => {
                    console.log(error);
                    handleError(error, getState(), dispatch);
                    return Promise.reject(error);
                });
        };

export const BOT_DIALOG_DATA_QUERY = "BOT_DIALOG_DATA_QUERY";
export const getBotDialogDataQuery =
    ({bot_id}) =>
        (dispatch, getState) =>
            client
                .query({
                    query: BOT_DIALOG_QUERY,
                    variables: {
                        bot_id,
                    },
                })
                .then((res) => {
                    dispatch({
                        type: BOT_DIALOG_DATA_QUERY,
                        payload: res.data,
                    });

                    return Promise.resolve(res?.data);
                })
                .catch((error) => {
                    console.log(error);
                    handleError(error, getState(), dispatch);
                    return Promise.reject(error);
                });

export const UPSERT_VOC = "UPSERT_VOC";
export const upsertVoc =
    ({bot_id}) =>
        (dispatch, getState) => {
            const token = getState().auth.access_token;
            return client
                .mutate({
                    mutation: UPSERT_VOC_MUTATION,
                    variables: {bot_id, key: bot_id},
                    context: {
                        headers: {
                            Authorization: Tokenizer.authHeader(token),
                        },
                    },
                })
                .then((res) => Promise.resolve(res.data))
                .catch((error) => {
                    console.log(error);
                    handleError(error, getState(), dispatch);
                    return Promise.reject(error);
                });
        };

export const APP_SAVE_TOOL = "APP_SAVE_TOOL";
export const saveTool = (formData, project_id) => (dispatch, getState) =>
    authAxios(getState())
        .post(api.endpoint(`/projects/${project_id}/tools/save`), formData)
        .then((res) => {
            dispatch({
                type: APP_SAVE_TOOL,
                payload: res.data,
            });

            return Promise.resolve(res.data);
        })
        .catch((error) => {
            handleError(error, getState(), dispatch);
            return Promise.reject(error);
        });

export const APP_DELETE_TOOL = "APP_DELETE_TOOL";
export const deleteTool = (project_id, tool_id) => (dispatch, getState) =>
    authAxios(getState())
        .delete(api.endpoint(`/projects/${project_id}/tools/${tool_id}`))
        .then((res) => {
            dispatch({
                type: APP_DELETE_TOOL,
                payload: res.data,
            });

            return Promise.resolve(res.data);
        })
        .catch((error) => {
            handleError(error, getState(), dispatch);
            return Promise.reject(error);
        });

export const GET_IDEA_CATEGORIES = "GET_IDEA_CATEGORIES";
export const getIdeaCategories = () => (dispatch, getState) =>
    authAxios(getState())
        .get(api.endpoint("/tools/ideas/categories"))
        .then((res) => {
            dispatch({
                type: GET_IDEA_CATEGORIES,
                payload: res.data,
            });

            return Promise.resolve(res.data);
        })
        .catch((error) => {
            handleError(error, getState(), dispatch);
            return Promise.reject(error);
        });

export const GET_MAP_IDEAS = "GET_MAP_IDEAS";
export const getMapIdeas = ({
                                project_id = null,
                                landing_id = undefined,
                                page = null,
                                page_size = null,
                            }) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .get(api.endpoint("/projects/map/ideas"), {
                params: {
                    project_id,
                    landing_id,
                    page,
                    page_size,
                },
            })
            .then((res) => {
                dispatch({
                    type: GET_MAP_IDEAS,
                    payload: res.data,
                });

                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const GET_IDEAS = "GET_IDEAS";
export const getIdeas = ({
                             project_id,
                             bot_id,
                             page,
                             page_size,
                             date_start,
                             date_end,
                             is_published,
                             search,
                         }) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .get(api.endpoint("/projects/ideas"), {
                params: {
                    project_id,
                    bot_id,
                    page,
                    page_size,
                    date_start,
                    date_end,
                    is_published,
                    search,
                },
            })
            .then((res) => {
                dispatch({
                    type: GET_IDEAS,
                    payload: res.data,
                });

                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const getIdeasXlsx = ({project_id, bot_id}) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .get(api.endpoint(`/projects/ideas/xls`), {
                responseType: "blob",
                params: {
                    project_id,
                    bot_id,
                },
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.id = "ideas_xls_download_" + new Date().getTime();
                link.href = url;
                link.setAttribute("download", "ideas.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();

                //window.location.href = response.url;
                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const GET_IDEA = "GET_IDEA";
export const getIdea = (id) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .get(api.endpoint("/projects/ideas/" + id))
            .then((res) => {
                dispatch({
                    type: GET_IDEA,
                    payload: res.data,
                });

                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const PUBLISH_IDEA = "PUBLISH_IDEA";
export const publishIdea = (id, is_published = true) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .post(api.endpoint("/projects/ideas/" + id + "/publish"), {
                is_published,
            })
            .then((res) => {
                dispatch({
                    type: PUBLISH_IDEA,
                    payload: res.data,
                });

                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const EDIT_IDEA = "EDIT_IDEA";
export const editIdea = (id, message) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .post(api.endpoint("/projects/ideas/" + id + "/edit"), {
                message,
            })
            .then((res) => {
                dispatch({
                    type: EDIT_IDEA,
                    payload: res.data,
                });

                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const checkUnique = (key, entity, project_id) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .post(api.endpoint(`/projects/${entity}/unique`), {
                key,
                project_id,
            })
            .then((res) => {
                dispatch({
                    type: EDIT_IDEA,
                    payload: res.data,
                });

                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const getMap = () => {
    const loc = api.endpoint(`/projects/map`);

    return function (dispatch, getState) {
        return authAxios(getState())
            .get(loc)
            .then((res) => {
                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

const dialogApi = new ApiEndpoint("dialog");
export const getProjectBots = (project_id) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .get(dialogApi.endpoint(`/project/${project_id}/bots`), {
                params: {
                    project_id,
                },
            })
            .then((res) => {
                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const getDialogs = (project_id, bot_id = null) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .get(api.endpoint(`/projects/dialogs`), {
                params: {
                    project_id,
                    bot_id,
                },
            })
            .then((res) => {
                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const getChatDialog = (chat_id) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .get(api.endpoint(`/projects/dialogs/chat`), {
                params: {
                    chat_id,
                },
            })
            .then((res) => {
                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const getDialogsCsv = (project_id, bot_id) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .get(api.endpoint(`/projects/dialogs/csv`), {
                responseType: "blob",
                params: {
                    project_id,
                    bot_id,
                },
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "dialog.csv"); //or any other extension
                document.body.appendChild(link);
                link.click();

                //window.location.href = response.url;
                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const getDialogsXlsx = (project_id, bot_id) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .get(api.endpoint(`/projects/dialogs/xls`), {
                responseType: "blob",
                params: {
                    project_id,
                    bot_id,
                },
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "dialog.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();

                //window.location.href = response.url;
                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const getAllDialogsXlsx = (bot_id, setDownloadStatus) => {
    return function (dispatch, getState) {
        return xlsxAxios(getState())
            .get(xlsx_api.endpoint(`/export/xlsx`), {
                responseType: "blob",
                params: {
                    bot_id,
                },
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "dialog.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();

                return Promise.resolve(res.data).then(() => {
                    if (setDownloadStatus) {
                        setDownloadStatus("ready");
                        setTimeout(() => setDownloadStatus(null), 3200);
                    } else {
                        return true;
                    }
                });
                /*console.log("Here we try to fetch download file");*/
            })
            .catch((error) => {
                if (setDownloadStatus) {
                    setDownloadStatus("error");
                    setTimeout(() => setDownloadStatus(null), 3200);
                }

                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};

export const getSummaryMetrics = (
    project_id = undefined,
    landing_id = undefined
) => {
    return getDataAction(api.endpoint(`/metrics/summary`), {
        project_id,
        landing_id,
    });
};

export const getNerMetrics = (project_id = undefined) => {
    return getDataAction(api.endpoint(`/metrics/ner`), {project_id});
};

export const getLadMetrics = (project_id = undefined) => {
    return getDataAction(api.endpoint(`/metrics/lad`), {project_id});
};

export const getLadFreqMetrics = (project_id = undefined) => {
    return getDataAction(api.endpoint(`/metrics/lad/frequency`), {project_id});
};

export const getSentMetrics = (project_id = undefined) => {
    return getDataAction(api.endpoint(`/metrics/sent`), {project_id});
};

export const getEmbedMetrics = (project_id = undefined) => {
    return getDataAction(api.endpoint(`/metrics/embed`), {project_id});
};

export const APP_CLEANUP = "APP_CLEANUP";
export const cleanupApp = () => ({
    type: APP_CLEANUP,
});

export const APP_SAVE_COMMENT = "APP_SAVE_COMMENT";
export const saveComment = (dialog_id, params) => {
    return function (dispatch, getState) {
        return authAxios(getState())
            .post(api.endpoint(`/projects/dialogs/${dialog_id}/save`), params)
            .then((res) => {
                dispatch({
                    type: APP_SAVE_COMMENT,
                    payload: res.data,
                });

                return Promise.resolve(res.data);
            })
            .catch((error) => {
                handleError(error, getState(), dispatch);
                return Promise.reject(error);
            });
    };
};
