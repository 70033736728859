/**
 * External Dependencies
 */
import React, { Component } from "react";
import { connect } from "react-redux";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../../components/async-component";
import PageWrap from "../../../../components/page-wrap";
import PageTitle from "../../../../components/page-title";
import PageContent from "../../../../components/page-content";
import { getIdeaCategories, loadCurrentProject } from "../../../../actions/app";
import { Spinner } from "reactstrap";

/**
 * Component
 */
class CreateProjectPage extends Component {
  _isMounted = false;

  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    const {
      loadCurrentProject,
      getIdeaCategories,
      ideaCategories
    } = this.props;

    this._isMounted = true;
    this.setState({
      isLoading: true
    });

    !ideaCategories?.length > 0 && getIdeaCategories();
    loadCurrentProject()
      .then(() => {
        this._isMounted &&
          this.setState({
            isLoading: false
          });
      })
      .catch(e => e)
      .finally(() => {
        this._isMounted &&
          this.setState({
            isLoading: false
          });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  preparingRender() {
    return (
      <div className="rui-main">
        <div className="rui-first-project align-items-center justify-content-center">
          <AsyncComponent component={() => import("./content")} />
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <PageWrap>
        <PageContent>
          <div className="rui-component-loading">
            <div className="rui-component-loading-spinner">
              <h3>Загрузка, подождите...</h3>
              <Spinner color="secondary" type="grow">
                Загрузка, подождите...
              </Spinner>
            </div>
          </div>
        </PageContent>
      </PageWrap>
    );
  }

  render() {
    const { is_preparing } = this.props;

    if (this.state.isLoading) {
      return this.renderLoading();
    }

    if (is_preparing) {
      return this.preparingRender();
    }

    return (
      <PageWrap>
        <PageTitle>
          <h1>Анкета проекта</h1>
        </PageTitle>
        <PageContent>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

export default connect(
  ({ app, auth, settings }) => {
    const { is_preparing } = { ...auth };
    const { currentProject, ideaCategories } = { ...app };

    return {
      app,
      settings,
      is_preparing,
      currentProject,
      ideaCategories
    };
  },
  {
    loadCurrentProject,
    getIdeaCategories
  }
)(CreateProjectPage);
