import React from "react";
import styled from "styled-components";
import { Spin } from "antd";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 24px;
  height: 100%;
  min-height: ${({ full }) => full && "100vh"};

  .ant-spin {
    &-dot {
      margin-bottom: 1rem;
    }

    &-text {
      font-weight: 500;
    }
  }
`;

const PageLoader = ({ full }) => (
  <Container {...{ full }}>
    <Spin size="large" tip="Загрузка..." spinning />
  </Container>
);

export default PageLoader;
