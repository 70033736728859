import styled from "styled-components";

const ArrowInWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(
    90deg,
    #fbfbfb 74.38%,
    rgba(251, 251, 251, 0) 100%
  );
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &&::before {
    content: "";
    width: 36px;
    height: 36px;
    background: url("/icons/arrow_wrap.svg");
    transform: scale(-1);
    opacity: 0.6;
    background-size: 10px 24px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }

  &&:hover::before {
    opacity: 1;
    background-color: #f1f1f1;
  }

  &&[data-pos="left"] {
    left: 0;
  }

  &&[data-pos="right"] {
    right: 0;
    transform: scale(-1);
  }
`;

export { ArrowInWrapper };
