import React, { useMemo } from "react";
import styled from "styled-components";

import TooltipMap from "../../../../../components/map-gl/tooltipmap";
import { IconLayer } from "@deck.gl/layers";
import { pick } from "../../../../../helpers/fn";
import iconAtlas from "../../../../../common-assets/images/icons/pins.png";

const MapWrapper = styled.div`
  width: 100%;
  height: 300px;
  background: grey;
  overflow: hidden;
`;

const iconMapping = {
  marker: { x: 0, y: 0, width: 512, height: 512 },
  remove: { x: 512, y: 0, width: 512, height: 512 },
};

const MapBlock = ({ bot_location, answers }) => {
  const initialViewState = useMemo(() => {
    let location = {};

    if (bot_location && typeof bot_location === "object") {
      const { map } = bot_location;
      location = map;
    }

    const _initialViewState = {
      longitude: pick("lng", location, 0),
      latitude: pick("lat", location, 0),
      zoom: pick("zoom", location, 15),
      pitch: 0,
      bearing: 0,
    };

    return _initialViewState;
  });

  let pinsData = useMemo(() => {
    if (answers) {
      let _pinsData = [];

      answers
        .filter(({ message }) => {
          if (message) {
            return message.type === "location";
          }
        })
        .map(({ message, chat, cr: vocCr }) => {
          const { attachment } = message;

          const { ideas = [] } = chat;

          let coeff = 1000 * 60 * 2;
          const cr1 = new Date(vocCr);
          const rounded1 = new Date(Math.floor(cr1.getTime() / coeff) * coeff);

          const filteredIdeas = ideas.filter(({ cr }) => {
            let coeff = 1000 * 60 * 2;
            const cr2 = new Date(cr);
            const rounded2 = new Date(
              Math.floor(cr2.getTime() / coeff) * coeff
            );

            return rounded1.getTime() === rounded2.getTime();
          });

          const realIdeaId =
            filteredIdeas &&
            Array.isArray(filteredIdeas) &&
            filteredIdeas.length > 0
              ? filteredIdeas[0].id
              : null;

          if (attachment) {
            const { location: pinLocation } = attachment;

            if (realIdeaId) {
              _pinsData.push({
                location: pinLocation,
                coordinates: [pinLocation.lng, pinLocation.lat],
                ideaId: realIdeaId,
                id: realIdeaId,
              });
            }
          }
        });
      return _pinsData;
    }
  }, [answers]);

  const iconLayer = useMemo(() => {
    if (pinsData) {
      return new IconLayer({
        id: "icon-layer",
        data: pinsData,
        pickable: true,
        iconAtlas,
        iconMapping,
        getIcon: () => "marker",
        getPosition: ({ coordinates }) => coordinates,
        onClick: ({ object }) => {
          const { ideaId } = object;

          if (ideaId) {
            window.open(`/project/ideas/${ideaId}`);
          }
          return true;
        },
        getSize: 96,
        autoHighlight: true,
        highlightColor: [255, 255, 255, 47],
      });
    }
  }, [pinsData]);

  return (
    <>
      <MapWrapper>
        <TooltipMap
          layers={[iconLayer]}
          initialViewState={initialViewState}
        ></TooltipMap>
      </MapWrapper>
    </>
  );
};

export default MapBlock;
