/**
 * External Dependencies
 */
import React from "react";
/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../../components/async-component";
import PageWrap from "../../../../components/page-wrap";
import PageContent from "../../../../components/page-content";

import { connect } from "react-redux";
import { loadCurrentProject } from "../../../../actions/app";

import NoRender from "../../../../helpers/noRender";

/**
 * Component
 */

const MetricPage = ({ currentProjectId }) => {
  if (currentProjectId) {
    return (
      <PageWrap>
        {/*<PageTitle
        /*
                    breadcrumbs={ {
                        '/': 'Home',
                        '/crowdsourcing': 'Краудсорсинг',
                        '/crowdsourcing/id01': 'Чего хочет Город',
                        '/crowdsourcing/id01/metrics': 'Метрики',
                        '/crowdsourcing/id01/metrics/metric01': {
                            title: 'Активность',
                            sub: 'apps',
                        },
                    } }

        >
          <StyledH1>Диалоги</StyledH1>
        </PageTitle>*/}
        <PageContent>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  } else {
    return <NoRender />;
  }
};

export default connect(
  ({ app }) => ({
    tools: app.tools,
    projects: app.projects,
    currentProject: app.currentProject,
    currentProjectId: app.currentProjectId,
  }),
  {
    loadCurrentProject,
  }
)(MetricPage);
