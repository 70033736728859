////////////////////////////////
///// Libraries
import React, { useEffect, useState } from "react";
import { Row, Col, Alert } from "antd";

import { Line } from "@ant-design/charts";

import styled from "styled-components";

import { ContextHeader } from "../../../../../env";

/////////////////////////////
/// Queries
import {
  ACTIVITY_GRAPHICS_QUERY,
  ACTIVITY_USERS_GRAPHICS_QUERY,
  ACTIVITY_QUESTIONNAIRE_GRAPHICS_QUERY,
  ACTIVITY_GRAPHICS_COMMENTS_QUERY,
  ACTIVITY_GRAPHICS_LIKES_QUERY,
} from "../../../../../queries/index";
import { useLazyQuery } from "@apollo/client";

////////////////////////////////////////////////
//// Helpers
import { _month, _thisDayEnd } from "../../../../../helpers/time-pack";
import { CardBody, ChartBlock } from "../../../../../helpers/body-card";

/////////////////////////////////////
// Styled Components

const BodyCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BodyRow = styled(Row)`
  margin-top: 20px;
`;

const LegendAlert = styled(Alert)`
  opacity: 0.7;
  font-size: 13px;

  & * {
    font-size: 13px;
  }

  &&:hover {
    opacity: 1;
  }
`;

/////////////////////////////////////
/// Card Component
function convertDate(date) {
  let yyyy = date.getFullYear().toString();
  let mm = (date.getMonth() + 1).toString();
  let dd = date.getDate().toString();

  let mmChars = mm.split("");
  let ddChars = dd.split("");

  return (
    yyyy +
    "/" +
    (mmChars[1] ? mm : "0" + mmChars[0]) +
    "/" +
    (ddChars[1] ? dd : "0" + ddChars[0])
  );
}

const MakeGraphicsFromData = (
  dataActivityGraphicQuery,
  selVisitsDateStart,
  selVisitsDateEnd,
  typeString
) => {
  let ActivityGraphicData = [];
  let currString = null;
  let currIndex = 0;

  let daylist = [];
  let daylistData = [];
  let daylistData1 = [];

  if (dataActivityGraphicQuery) {
    ///////////////////////////////
    /// Here We find count of messages for each day
    dataActivityGraphicQuery.map((key) => {
      if (key?.cr !== null) {
        if (currString === null) {
          currString = key?.cr?.split("T")[0];
          ActivityGraphicData[currIndex] = {
            date: new Date(currString),
            value: 1,
            type: typeString,
          };
        } else {
          if (currString === key?.cr?.split("T")[0]) {
            ActivityGraphicData[currIndex].value += 1;
          } else {
            currIndex += 1;
            currString = key?.cr?.split("T")[0];
            ActivityGraphicData[currIndex] = {
              date: new Date(currString),
              value: 1,
              type: typeString,
            };
          }
        }
      }
    });

    ActivityGraphicData = ActivityGraphicData.reverse();

    ////////////////////////////////////////
    /// Here we make array of days between dates

    const getDaysArray = (start, end) => {
      for (
        var arr = [], dt = new Date(start);
        dt <= end;
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push({ date: new Date(dt), value: 0, type: typeString });
      }
      return arr;
    };

    daylist = getDaysArray(selVisitsDateStart, selVisitsDateEnd);

    ///////////////////////////////////////////
    //// Here we merge array with days (that initially have y: 0) with array that has data
    daylist.map(({ date: v, value }, i) => {
      v.setUTCHours(0, 0, 0, 0);
      let timeCalendar = v.toISOString();
      let _y = value;

      daylistData[i] = {
        date: new Date(timeCalendar.substring(0, 10)),
        value: 0,
        type: typeString,
      };
      return { date: timeCalendar, value: _y, type: typeString };
    });

    daylistData1 = Object.values(
      []
        .concat(daylistData, ActivityGraphicData)
        .reduce(
          (r, c) => ((r[c.date] = Object.assign(r[c.date] || {}, c)), r),
          {}
        )
    );

    daylistData1.sort((a, b) =>
      a.date > b.date ? 1 : b.date > a.date ? -1 : 0
    );
  }

  return daylistData1;
};

const ActivityGraphicsMiniCard = ({ currentProjectId, botParent }) => {
  ///////////////////////////////
  ////States
  const [selVisitsDateType, setSelVisitsDateType] = useState(3);
  const [selVisitsDateStart, setSelVisitsDateStart] = useState(_month);
  const [selVisitsDateEnd, setSelVisitsDateEnd] = useState(_thisDayEnd);
  //const [selVisitsValue, setSelVisitsValue] = useState(null);

  const [data, setData] = useState([]);

  const [dataList1, setDataList1] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [dataList3, setDataList3] = useState([]);
  const [dataList4, setDataList4] = useState([]); //Комментарии
  const [dataList5, setDataList5] = useState([]); //Лайки
  //const [lineConfig, setLineConfig] = useState(null);

  /**
   *
   * Chart Config
   */

  //// Lazy Query
  const [getActivityGraphicQuery, { data: dataActivityGraphicQuery }] =
    useLazyQuery(ACTIVITY_USERS_GRAPHICS_QUERY, {
      context: {
        headers: {
          "x-hasura-admin-secret": ContextHeader,
        },
      },
    });

  const [getActivityGraphicIdeasQuery, { data: dataActivityGraphicIdeaQuery }] =
    useLazyQuery(ACTIVITY_GRAPHICS_QUERY, {
      context: {
        headers: {
          "x-hasura-admin-secret": ContextHeader,
        },
      },
    });

  const [
    getActivityGraphicQuestionnaireQuery,
    { data: dataActivityGraphicQuestionnaireQuery },
  ] = useLazyQuery(ACTIVITY_QUESTIONNAIRE_GRAPHICS_QUERY, {
    context: {
      headers: {
        "x-hasura-admin-secret": ContextHeader,
      },
    },
  });

  const [
    getActivityGraphicCommentsQuery,
    { data: dataActivityGraphicCommentsQuery },
  ] = useLazyQuery(ACTIVITY_GRAPHICS_COMMENTS_QUERY, {
    context: {
      headers: {
        "x-hasura-admin-secret": ContextHeader,
      },
    },
  });

  const [
    getActivityGraphicLikesQuery,
    { data: dataActivityGraphicLikesQuery },
  ] = useLazyQuery(ACTIVITY_GRAPHICS_LIKES_QUERY, {
    context: {
      headers: {
        "x-hasura-admin-secret": ContextHeader,
      },
    },
  });

  ///////////////////////////////
  ///// Use Effects
  useEffect(() => {
    getActivityGraphicQuery({
      variables: {
        start_date: selVisitsDateStart,
        end_date: selVisitsDateEnd,
        project_id: currentProjectId,
        bot_id: botParent,
      },
    });

    getActivityGraphicIdeasQuery({
      variables: {
        start_date: selVisitsDateStart,
        end_date: selVisitsDateEnd,
        idea: { id: { _is_null: false } },
        parent_id: { _is_null: true },
        project_id: currentProjectId,
        bot_id: botParent,
      },
    });

    getActivityGraphicCommentsQuery({
      variables: {
        start_date: selVisitsDateStart,
        end_date: selVisitsDateEnd,
        project_id: currentProjectId,
        bot_id: botParent,
      },
    });

    getActivityGraphicQuestionnaireQuery({
      variables: {
        start_date: selVisitsDateStart,
        end_date: selVisitsDateEnd,
        project_id: currentProjectId,
        bot_id: botParent,
      },
    });

    getActivityGraphicLikesQuery({
      variables: {
        start_date: selVisitsDateStart,
        end_date: selVisitsDateEnd,
        project_id: currentProjectId,
        bot_id: botParent,
      },
    });
  }, [currentProjectId, selVisitsDateStart, selVisitsDateEnd, botParent]);

  useEffect(
    () =>
      setDataList1(
        MakeGraphicsFromData(
          dataActivityGraphicQuery?.chats,
          selVisitsDateStart,
          selVisitsDateEnd,
          "Пользователи"
        )
      ),
    [dataActivityGraphicQuery]
  );

  useEffect(
    () =>
      setDataList2(
        MakeGraphicsFromData(
          dataActivityGraphicIdeaQuery?.chat_dialogs,
          selVisitsDateStart,
          selVisitsDateEnd,
          "Сообщения"
        )
      ),
    [dataActivityGraphicIdeaQuery]
  );

  useEffect(() => {
    setDataList4(
      MakeGraphicsFromData(
        dataActivityGraphicCommentsQuery?.chat_dialogs,
        selVisitsDateStart,
        selVisitsDateEnd,
        "Комментарии"
      )
    );
  }, [dataActivityGraphicCommentsQuery]);

  useEffect(
    () =>
      setDataList3(
        MakeGraphicsFromData(
          dataActivityGraphicQuestionnaireQuery?.chats,
          selVisitsDateStart,
          selVisitsDateEnd,
          "Анкеты"
        )
      ),
    [dataActivityGraphicQuestionnaireQuery]
  );

  useEffect(
    () =>
      setDataList5(
        MakeGraphicsFromData(
          dataActivityGraphicLikesQuery?.idea_ratings,
          selVisitsDateStart,
          selVisitsDateEnd,
          "Лайки"
        )
      ),
    [dataActivityGraphicLikesQuery]
  );

  const MergeAllData = (list1, list2, list3, list4, list5) => {
    const result = [...list1, ...list2, ...list3, ...list4, ...list5];

    const resultSorted = result.sort((a, b) => a.date - b.date);
    const resultWithDateFormatted = resultSorted.map(({ date, ...other }) => {
      return { date: convertDate(date), ...other };
    });

    return resultWithDateFormatted;
  };

  useEffect(() => {
    const MergedData = MergeAllData(
      dataList1,
      dataList2,
      dataList3,
      dataList4,
      dataList5
    );
    setData(MergedData);
  }, [dataList1, dataList2, dataList3, dataList4, dataList5]);

  ///////////////////////////////////
  /// Get Categories

  return (
    <CardBody className="card">
      <div className="card-body">
        <BodyCol>
          <Row>
            <CardBody.Subheader
              {...{
                selDateType: selVisitsDateType,
                setSelDateType: setSelVisitsDateType,
                DateStart: setSelVisitsDateStart,
                DateEnd: setSelVisitsDateEnd,
                LabelDateStart: selVisitsDateStart,
                LabelDateEnd: selVisitsDateEnd,
              }}
              title={"Активность"}
            />
          </Row>
          <BodyRow>
            <CardBody.Content>
              <ChartBlock dheight={230}>
                {data && (
                  <Line
                    {...{
                      smooth: true,
                      data: data,
                      xField: "date",
                      yField: "value",
                      offsetY: 20,
                      legend: {
                        layout: "horizontal",
                        label: {
                          style: {
                            fontSize: "12px",
                          },
                        },
                        position: "bottom-left",
                        itemSpacing: 20,
                        marker: {
                          spacing: 3,
                        },
                      },
                      yAxis: {
                        label: {
                          // 数值格式化为千分位
                          formatter: (v) =>
                            `${v}`.replace(
                              /\d{1,3}(?=(\d{3})+$)/g,
                              (s) => `${s},`
                            ),
                        },
                      },
                      xAxis: {
                        label: {
                          formatter: (v) => {
                            if (v) {
                              const dateArr = v.toString().split("/");
                              return `${dateArr?.[1]}/${dateArr?.[2]}`;
                            } else {
                              return v;
                            }
                          },
                        },
                      },
                      seriesField: "type",
                      color: ({ type }) => {
                        return type === "Пользователи"
                          ? "#5C5EF9"
                          : type === "Сообщения"
                          ? "#099DEC"
                          : type === "Анкеты"
                          ? "#6BC4F4"
                          : type === "Лайки"
                          ? "#F63463"
                          : "#C4C4C4";
                      },
                      lineStyle: () => {
                        return {
                          opacity: 0.6,
                        };
                      },
                    }}
                  />
                )}
              </ChartBlock>
            </CardBody.Content>
          </BodyRow>
          <Row>
            <LegendAlert
              showIcon={false}
              message="При клике на легенду, вы можете скрывать ненужные разделы в графике"
              closable
              type={"warning"}
            />
          </Row>
        </BodyCol>
      </div>
    </CardBody>
  );
};

export default ActivityGraphicsMiniCard;
