import React, { useState } from "react";
import { Space } from "antd";
import styled from "styled-components";

import { connect } from "react-redux";
import { loadCurrentProject } from "../../../../actions/app";

///////////////////////////////////////////////
/// Cards
import ActivityGraphicsMiniCard from "./cards/activity-graphics-mini-card";
import ActivityMetricsMiniCard from "./cards/activity-metrics-mini-card";
//import LACard from "./cards/la";

import MessagesCard from "./cards/messages-card";

import { StyledH2 } from "../Styled/styled";

import BotsList from "../Nuclear/bots/bots-list";

import NoRender from "../../../../helpers/noRender";
//import QuestionnaireCard from "./questionnaire-card";

//////////////////////////////////////////////
/// Styled Components

const StyledSpace = styled(Space)`
  width: 100%;
`;

const SpaceRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
`;

const StyledH2Section = styled(StyledH2)`
  &&& {
    margin-bottom: 0;
    padding-bottom: 14px;
    padding-bottom: 8px;
  }
`;

const Metrics = ({ currentProjectId }) => {
  // Стэйт для того, чтобы установить ответы какого бота отображать
  const [botParent, setBotParent] = useState(null);

  if (currentProjectId) {
    return (
      <>
        <StyledSpace size={30} direction="vertical">
          {/* Верхняя шапка со списком ботов */}
          {/*<QuestionnaireCard {...{ currentProjectId, currentProject }} />*/}
          <BotsList
            {...{
              botParent,
              setBotParent,
              currentProjectId,
            }}
          />

          {/* два блока раздела "Активность" */}
          <StyledSpace direction="vertical" size={0}>
            <StyledH2Section data-level="1">Активность</StyledH2Section>

            <SpaceRow>
              {/* общая статистика */}
              <ActivityMetricsMiniCard {...{ currentProjectId, botParent }} />
              {/* график */}
              <ActivityGraphicsMiniCard {...{ currentProjectId, botParent }} />
            </SpaceRow>
          </StyledSpace>

          {/* раздел "сообщения" */}
          <StyledSpace direction="vertical" size={0}>
            <StyledH2Section data-level="1">
              Последние сообщения
            </StyledH2Section>
            <MessagesCard {...{ currentProjectId, botParent }} />
          </StyledSpace>
        </StyledSpace>
      </>
    );
  } else {
    return <NoRender />;
  }
};

export default connect(
  ({ app }) => ({
    tools: app.tools,
    projects: app.projects,
    currentProject: app.currentProject,
    currentProjectId: app.currentProjectId,
    totalapp: app,
  }),
  {
    loadCurrentProject,
  }
)(Metrics);
