/**
 * Internal Dependencies
 */
// SignIn
import SignInPage from "./Auth/SignIn";
import SignUpPage from "./Auth/SignUp";
import ForgotPage from "./Auth/Forgot";
import RecoveryPage from "./Auth/Recovery";

// Crowdsourcing
// import CrowdsourcingMap from "./Crowdfunding/Crowdsourcing/Map";
import CrowdsourcingProject from "./Crowdfunding/Crowdsourcing/Project";
import CreateProjectPage from "./Crowdfunding/Crowdsourcing/CreateProject";
import MetricPage from "./Crowdfunding/Crowdsourcing/MetricPage";
import SummaryPage from "./Crowdfunding/Crowdsourcing/SummaryPage";
import DialogPage from "./Crowdfunding/Crowdsourcing/DialogPage";
import FeedbackPage from "./Crowdfunding/Crowdsourcing/Feedback";
import IdeasPage from "./Crowdfunding/Crowdsourcing/Ideas";
import IdeaPage from "./Crowdfunding/Crowdsourcing/Idea";
import ToolsPage from "./Crowdfunding/Crowdsourcing/Tools";
import ToolPage from "./Crowdfunding/Crowdsourcing/Tool";
import CreateToolPage from "./Crowdfunding/Crowdsourcing/CreateTool";
import Analytics from "./Crowdfunding/Crowdsourcing/Analytics";
import ProjectsPage from "./Crowdfunding/Crowdsourcing/Projects";
import TMPage from "./Crowdfunding/Crowdsourcing/TopicModelling";
import FeedbackIdea from "./Crowdfunding/Crowdsourcing/FeedbackIdea";

import SubscriptionPage from "./Crowdfunding/Crowdsourcing/Subscription";
// import FAQPage from "./Crowdfunding/Crowdsourcing/FAQ";

// Apps
import Messenger from "./Messenger";

export default {
  "/sign-in": SignInPage,
  "/sign-up": SignUpPage,
  "/forgot": ForgotPage,
  "/recovery": RecoveryPage,
  // "/": CrowdsourcingProject,
  "/projects": ProjectsPage,
  "/(project)?": CrowdsourcingProject,
  // "/map": CrowdsourcingMap,
  "/project/:page(create)": CreateProjectPage,
  "/project/feedback": FeedbackPage,
  "/project/analytics": Analytics,
  "/project/metrics/:metric?": MetricPage,
  "/project/summary/:summary?": SummaryPage,
  "/project/dialogs": DialogPage,
  "/project/dialogs/:bot": Messenger,
  "/project/ideas": IdeasPage,
  "/project/topicmodelling": TMPage,
  "/project/ideas/:idea": IdeaPage,
  "/project/feedback/:feedback": FeedbackIdea,
  "/project/tools": ToolsPage,
  "/project/:page(tools)/:action(create|edit)/:tool_id?": CreateToolPage,
  "/project/tools/:tool_id": ToolPage,
  "/billing": SubscriptionPage,
  // "/help": FAQPage
};
