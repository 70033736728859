import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Menu, Dropdown, Typography } from "antd";

const { Paragraph } = Typography;

import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { questionType } from "../helpers/question-type";
import { Alert } from "antd";

import { ANSWERS_DIALOG_HUB } from "../../../../../queries/index";

import { makeQuery } from "../helpers/make-query";

import { AnswersVocWrapper } from "../helpers/local-styled";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import TotalCount from "./total-count";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const DataStatusWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnswersVocType = (props) => {
  const { currentProjectId, bot_id, activeQuestion } = props;

  const type = activeQuestion && activeQuestion.type;
  const questionKey = activeQuestion && activeQuestion.key;
  const answers = activeQuestion && activeQuestion.answers;

  /* Lazy Query */
  const [getAnswersList, { data: AnswersList, loading: l_AnswersList }] =
    makeQuery(ANSWERS_DIALOG_HUB);


  useEffect(() => {
    getAnswersList({
      variables: {
        project_id: currentProjectId,
        bot_id,
        node_key: questionKey,
        //limit: type === "plain" || type === "photo" ? limitPerPage : null,
      },
    });
  }, [currentProjectId, bot_id, questionKey]);

  const messagesList = useMemo(() => {
    if (AnswersList) {
      const _list = AnswersList?.chat_dialogs;

      return _list;
    }
  }, [AnswersList]);

  return (
    <>
      <Row style={{ marginTop: "24px" }}>
        <Col span={3}>
          <TotalCount
            label={"Ответили"}
            count={messagesList && messagesList.length}
            {...{ currentProjectId, bot_id }}
          />
        </Col>
        <Col span={3}>
          <TotalCount
            missed
            label={"Пропустили"}
            count={messagesList && messagesList.length}
            {...{ currentProjectId, bot_id }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {type &&
            questionType?.[type] &&
            questionType?.[type]({
              data: messagesList,
              loading: l_AnswersList,
              answers,
            })}
        </Col>
      </Row>
    </>
  );
};

const AllQuestions = ({ data, activeQuestion, setActiveQuestion }) => {
  return (
    <Menu>
      {data.map(({ key, text, type, answers }, i) => (
        <Menu.Item
          key={`sel::${i}::${key}`}
          disabled={key === activeQuestion.key && true}
        >
          <a
            onClick={() =>
              setActiveQuestion({ key: key, label: text, type, answers })
            }
          >
            {text}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );
};

const DropdownWrapper = styled.div`
  border: 1px solid #dce1e8;
  border-radius: 8px;
  padding: 7px 10px;
  max-width: 70%;
  min-width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &&& .ant-typography {
    margin-bottom: 0;
  }
`;

/* Блок с выводом всех Ответов */

const QuestionVoc = (props) => {
  const { data, loading, error, bot_id } = props;
  const { currentProjectId } = props;

  const [activeQuestion, setActiveQuestion] = useState(null);

  useEffect(() => {
    if (data && data.length > 0) {
      setActiveQuestion({
        key: data[0]?.key,
        label: data[0]?.text,
        type: data[0]?.type,
        answers: data[0]?.answers,
      });
    }
  }, [data]);

  if (!loading) {
    if (data) {
      const nodes = data;

      // Общее количество вопросов

      return (
        <AnswersVocWrapper>
          {activeQuestion && (
            <Dropdown
              overlay={
                <AllQuestions
                  data={nodes}
                  {...{ activeQuestion, setActiveQuestion }}
                />
              }
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <DropdownWrapper>
                  <Paragraph ellipsis={{ rows: 1 }}>
                    {activeQuestion.label}
                  </Paragraph>{" "}
                  <DownOutlined />{" "}
                </DropdownWrapper>
              </a>
            </Dropdown>
          )}

          <AnswersVocType {...{ currentProjectId, bot_id, activeQuestion }} />
        </AnswersVocWrapper>
      );
    } else {
      return (
        <DataStatusWrapper>
          <div>No Data</div>
        </DataStatusWrapper>
      );
    }
  } else if (error) {
    return (
      <DataStatusWrapper>
        <Alert message="Произошла ошибка при загрузке данных" type="error" />
      </DataStatusWrapper>
    );
  } else {
    return (
      <DataStatusWrapper>
        <Spin indicator={antIcon} />
      </DataStatusWrapper>
    );
  }
};

export default QuestionVoc;
