/**
 * External Dependencies
 */
import React from "react";

/**
 * Internal Dependencies
 */
//import AsyncComponent from "../../../../components/async-component";
import PageWrap from "../../../../components/page-wrap";
//import PageTitle from "../../../../components/page-title";
import PageContent from "../../../../components/page-content";

import Metrics from "./metrics";

/**
 * Component
 */
const MetricPage = () => (
  <PageWrap>
    <PageContent>
      {/*<AsyncComponent component={() => import("./content")} />*/}
      <Metrics />
    </PageContent>
  </PageWrap>
);

export default MetricPage;
