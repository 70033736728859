import React, { lazy, Suspense } from "react";

import PageLoader from "../../../components/page-loader";

const SignUp = lazy(() => import("./content"));

const SignUpPage = () => (
  <Suspense fallback={<PageLoader full />}>
    <SignUp />
  </Suspense>
);

export default SignUpPage;
