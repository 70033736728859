import React from "react";

import PlainBlock from "../blocks/plain-block";
import PhotoBlock from "../blocks/photo-block";
import VariantBlock from "../blocks/variant-block";
import VariantsBlock from "../blocks/variants-block";
import RatingBlock from "../blocks/rating-block";
import MapBlock from "../blocks/map-block";
import PollingBlock from "../blocks/polling-block";

/* Компонент, которые фомирует запрос данных об ответах*/

export const vocType = {
  plain: (props) => <PlainBlock {...{ ...props }} />,
  photo: (props) => <PhotoBlock {...{ ...props }} />,
  variant: (props) => <VariantBlock {...{ ...props }} />,
  variants: (props) => <VariantsBlock {...{ ...props }} />,
  rating: (props) => <RatingBlock {...{ ...props }} />,
  location: (props) => <MapBlock {...{ ...props }} />,
  polling: (props) => <PollingBlock {...{ ...props }} />,
};
