/**
 * External Dependencies
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardText, CardTitle, Button } from "reactstrap";
import { Link } from "react-router-dom";

import "./loading.scss";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../../components/async-component";
import PageWrap from "../../../../components/page-wrap";
import PageContent from "../../../../components/page-content";
import { loadCurrentProject, loadProjects } from "../../../../actions/app";
import styled from "styled-components";
import { Spinner } from "reactstrap";
import Tokenizer from "../../../../helpers/tokenizer";
import Maincards from "./maincards";

const StyledPageWrap = styled(PageWrap)`
  background: #f5f5f5;
`;

const WelcomeBlock = styled.div`
  width: 100%;
  padding: auto 30px;
  background: white;

  && h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
  }

  && a {
    font-size: 14px;
    color: #3b6df6;
    text-decoration: underline;
  }
`;

WelcomeBlock.Content = styled.div`
  margin: 30px;
  width: 50%;
`;

const StyledH1 = styled.h1`
  &&& {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 900;
  }
`;

/**
 * Component
 */
class CrowdsourcingProject extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      isLoading: true,
      isNoProject: true,
      allProjects: null,
    });

    const { auth, loadCurrentProject, loadProjects } = this.props;

    if (Tokenizer.verify(auth.access_token)) {
      loadProjects()
        .then((data) => data && this.setState({ allProjects: data }))
        .catch((err) => err);
      loadCurrentProject()
        .then((data) => {
          this._isMounted &&
            this.setState({
              isLoading: false,
              isNoProject: data == null,
            });
        })
        .catch(() => {
          this._isMounted &&
            this.setState({
              isLoading: false,
              isNoProject: false,
            });
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderNoProject() {
    return (
      <PageWrap>
        <PageContent>
          <div className="rui-no-project">
            <div className="rui-no-project-block">
              <Card className="text-center">
                <CardBody>
                  <CardTitle className="h2">
                    У вас еще нет ни одного проекта
                  </CardTitle>
                  <CardText>
                    Для продолжения работы в системе вам необходимо создать ваш
                    первый проект.
                  </CardText>
                  <CardText>
                    Чтобы его создать, необходимо заполнить небольшую анкету с
                    информацией о проекте.
                  </CardText>
                  <Link to="/project/create">
                    <Button color="brand">Cоздать первый проект</Button>
                  </Link>
                </CardBody>
              </Card>
            </div>
          </div>
        </PageContent>
      </PageWrap>
    );
  }

  renderLoading() {
    return (
      <PageWrap>
        <PageContent>
          <div className="rui-component-loading">
            <div className="rui-component-loading-spinner">
              <h3>Загрузка, подождите...</h3>

              <Spinner color="secondary" type="grow">
                Загрузка, подождите...
              </Spinner>
            </div>
          </div>
        </PageContent>
      </PageWrap>
    );
  }

  render() {
    const { currentProject, currentProjectId } = this.props;
    if (this.state.isLoading) {
      return this.renderLoading();
    }

    if (this.state.isNoProject || !currentProject) {
      if (this.state.allProjects?.projects?.length) {
        window.location.href = "/projects";
      } else {
        return this.renderNoProject();
      }
    }

    const PageBaykalskContent = (
      <StyledPageWrap>
        <WelcomeBlock>
          <WelcomeBlock.Content>
            <h2>
              Добро пожаловать на страницу управления проектом «Цифровой
              мастер-план Байкальска»
            </h2>
            <a
              target="_blank"
              href="https://dmp-baykalsk.chg.one/"
              rel="noopener noreferrer"
            >
              https://dmp-baykalsk.chg.one/
            </a>
          </WelcomeBlock.Content>
        </WelcomeBlock>
        <PageContent>
          <Maincards />
        </PageContent>
      </StyledPageWrap>
    );

    const PageMainContent = (
      <PageWrap>
        <PageContent>
          <StyledH1>{currentProject.name}</StyledH1>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );

    return (
      <>
        {currentProjectId === "963ad36f-271c-1066-ffa1-dacf25855f0d"
          ? PageBaykalskContent
          : PageMainContent}
      </>
    );
  }
}

export default connect(
  ({ auth, app, settings }) => ({
    auth,
    settings,
    currentProject: app.currentProject,
    currentProjectId: app.currentProjectId,
  }),
  {
    loadCurrentProject,
    loadProjects,
  }
)(CrowdsourcingProject);
