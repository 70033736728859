/**
 *
 */
const base = process.env.REACT_APP_BASE_URL || "https://api.chg.one";
const chg_base = "https://api.chg.one";
const dadata_token = process.env.REACT_APP_DADATA_API_KEY || "";
const isProduction = process.env.NODE_ENV === "production";
const ws = base.replace("https://", "ws://");

const dbgEnv = {
  base,
  chg_base,
  ws,
  auth: "/v1/project",
  app: "/v1/project",
  dialog: "/v1/dialog",
  xlsx: "/v1/reporting",
  data: "/data",
  dadata: "https://suggestions.dadata.ru",
  jwt_secret: "kf90349j6ca7D5RfyHyQvdkYRRWrD4ro",
  h_secret: "M98i98jhdfnju342",
  dadata_token,
};

const prodEnv = {
  base,
  chg_base,
  ws,
  auth: "/v1/project",
  app: "/v1/project",
  data: "/data",
  dadata: "https://suggestions.dadata.ru",
  dialog: "/v1/dialog",
  xlsx: "/v1/reporting",
  jwt_secret: "kf90349j6ca7D5RfyHyQvdkYRRWrD4ro",
  h_secret: "M98i98jhdfnju342",
  dadata_token,
};
// console.log("isProduction", isProduction);
const env = isProduction ? prodEnv : dbgEnv;

export const filesUrl =
  "https://files.xn-----glccfbc4ebdaxw3bzag.xn--p1ai/categories/";
export const appConfig = {
  api: {
    base: env.base,
    chg_base: env.chg_base,
    ws: env.ws,
    type: "json",
    auth: env.base + env.auth,
    app: env.base + env.app,
    xlsx: env.chg_base + env.xlsx,
    data: env.base + env.data,
    dialog: env.base + env.dialog,
    dadata: env.dadata,
  },
  auth: {
    secret: env.jwt_secret,
    dadata: env.dadata_token,
  },
  isProduction,
  version: process.env.VERSION,
};

export const ContextHeader = env.h_secret;

export const ApiEndpoint = function (api) {
  this.apiName = api;
  this.apiBase = appConfig.api[this.apiName];
  this.endpoint = function (endpoint) {
    if (endpoint.length && endpoint[0] !== "/") {
      endpoint = "/" + endpoint;
    }
    return this.apiBase + endpoint;
  };
};

export const apiUrl = (api, endpoint) => {
  return appConfig.api[api] + endpoint;
};
