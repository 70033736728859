import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Space, Tag, Tooltip, Spin, Typography, Col, Row } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { VerticalRectSeries, XYPlot, XAxis } from "react-vis";

import DateInfo from "./date-info";

const { CheckableTag } = Tag;

/////////////////////////////
//// Extra Imported Functions and Components
import {
  StyledH2,
  StyledCard,
  StyledRangePicker,
  InfoIcon,
} from "../pages/Crowdfunding/Crowdsourcing/Styled/styled";
import { _year, _month, _week, _thisDayStart, _thisDayEnd } from "./time-pack";

////////////////////////////////
//// Styled Components

const CardBodyHeader = styled(Space)`
  justify-content: space-between;
  width: 100%;

  && > .ant-space-item {
    width: 100%;
  }
`;

const DateFilter = styled(Space)`
  &&[data-active="noactive"] {
    display: none;
  }
`;

const DateFilterSpace = styled.div`
  && {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

///////////////////////////////
///// Extra functions
const onDataRange = ({
  _id,
  date,
  date1,
  setSelDateType,
  DateStart,
  DateEnd,
  updateQuery,
}) => {
  if (DateStart) DateStart(date);
  if (DateEnd) DateEnd(date1);
  setSelDateType(_id);

  updateQuery && updateQuery();
};

const { Paragraph } = Typography;

/////////////////////////////////
//// CardBody Component

const CardBody = StyledCard;

CardBody.Header = ({
  updateQuery,
  title,
  selDateType,
  setSelDateType,
  DateStart,
  DateEnd,
  hideFilter,
}) => {
  const DateTypes = [
    {
      name: "День",
      key: "day",
      range: [_thisDayStart, _thisDayEnd],
    },
    {
      name: "Неделя",
      key: "week",
      range: [_week, _thisDayEnd],
    },
    {
      name: "Месяц",
      key: "month",
      range: [_month, _thisDayEnd],
    },
    {
      name: "Год",
      key: "year",
      range: [_year, _thisDayEnd],
    },
  ];

  return (
    <CardBodyHeader align={"start"}>
      <StyledH2 data-level={1}>{title}</StyledH2>
      <DateFilter
        data-active={hideFilter ? "noactive" : "active"}
        align="flex-start"
        size={9}
      >
        <Space size={0}>
          {DateTypes.map(({ name, key, range }, i) => (
            <CheckableTag
              data-color="yellow"
              key={key}
              {...(selDateType === i + 1
                ? { checked: true }
                : { checked: false })}
              onClick={() => {
                onDataRange({
                  _id: i + 1,
                  date: range[0],
                  date1: range[1],
                  DateStart,
                  DateEnd,
                  setSelDateType,
                  updateQuery,
                });
              }}
            >
              {name}
            </CheckableTag>
          ))}
          <StyledRangePicker
            onCalendarChange={(e) => {
              onDataRange({
                _id: 5,
                date: e[0]?._d,
                date1: e[1]?._d,
                DateStart,
                DateEnd,
                setSelDateType,
                updateQuery,
              });
            }}
          />
        </Space>
        <Tooltip placement="leftTop" title="Информация по разделу">
          <InfoIcon />
        </Tooltip>
      </DateFilter>
    </CardBodyHeader>
  );
};

CardBody.Subheader = ({
  updateQuery,
  title,
  selDateType,
  setSelDateType,
  DateStart,
  DateEnd,
  LabelDateStart,
  LabelDateEnd,
}) => {
  const DateTypes = [
    {
      name: "День",
      key: "day",
      range: [_thisDayStart, _thisDayEnd],
    },
    {
      name: "Неделя",
      key: "week",
      range: [_week, _thisDayEnd],
    },
    {
      name: "Месяц",
      key: "month",
      range: [_month, _thisDayEnd],
    },
    {
      name: "Год",
      key: "year",
      range: [_year, _thisDayEnd],
    },
  ];

  return (
    <CardBodyHeader direction="vertical" align={"start"}>
      <StyledH2 data-level={2}>
        <DateInfo
          label={"Активность"}
          dateStart={LabelDateStart}
          dateEnd={LabelDateEnd}
        >
          {title}
        </DateInfo>
      </StyledH2>
      <DateFilterSpace>
        <Space size={0}>
          {DateTypes.map(({ name, key, range }, i) => (
            <CheckableTag
              data-color="yellow"
              key={key}
              {...(selDateType === i + 1
                ? { checked: true }
                : { checked: false })}
              onClick={() => {
                onDataRange({
                  _id: i + 1,
                  date: range[0],
                  date1: range[1],
                  DateStart,
                  DateEnd,
                  setSelDateType,
                  updateQuery,
                });
              }}
            >
              {name}
            </CheckableTag>
          ))}
        </Space>
        <StyledRangePicker
          onCalendarChange={(e) => {
            onDataRange({
              _id: 5,
              date: e[0]?._d,
              date1: e[1]?._d,
              DateStart,
              DateEnd,
              setSelDateType,
              updateQuery,
            });
          }}
        />
      </DateFilterSpace>
    </CardBodyHeader>
  );
};

CardBody.Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: stretch;
  gap: 50px;
`;

const CardBodyHead = ({ title, sectionName, hideRightPanel }) => {
  return (
    <CardBodyHeader align={"start"}>
      <StyledH2 data-level={1}>{title}</StyledH2>
      <CardPopCatWrapper>
        <CardPopCarRow data-active={hideRightPanel ? "noactive" : "active"}>
          <span>Подробнее в разделе {sectionName}</span>
          <Tooltip placement="leftTop" title="Информация по разделу">
            <InfoIcon />
          </Tooltip>
        </CardPopCarRow>
      </CardPopCatWrapper>
    </CardBodyHeader>
  );
};

////////////////////
// Spinner Component

const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;
const SpinWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = () => (
  <SpinWrapper>
    <Spin indicator={antIcon} />
  </SpinWrapper>
);

/////////////////////////
/// ChartBlock

const ChartBlock = styled.div`
  width: 100%;
  height: ${({ dHeight }) => (dHeight ? `${dHeight}px` : "300px")};
`;

////////////////////
// Right Block in the Card with fixed width
const CardPopCatWrapper = styled(Space)`
  width: 320px;
  min-width: 320px;

  && > .ant-space-item {
    width: 100%;
  }
`;

const CardPopCarRow = styled(Space)`
  justify-content: space-between;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  width: 100%;

  &&[data-active="noactive"] {
    display: none;
  }

  &&[data-a="click"]:hover {
    transform: translateY(-0.25rem);
  }
`;

const CardPopNumber = styled.div`
  background-color: ${({ color }) => (color ? color : "#5C5EF9")};
  background-image: ${({ back }) => (back ? `url(${back})` : "")};
  background-size: cover;
  color: white;
  width: ${({ size }) => (size ? `${size}px` : "20px")};
  height: ${({ size }) => (size ? `${size}px` : "20px")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;

const CardPopParagraph = styled(Paragraph)`
  && {
    max-width: ${({ maxwidth }) => (maxwidth ? `${maxwidth}%` : "100%")};
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const CardPopCatOverflow = styled.div`
  width: 100%;
  height: ${({ maxheight }) => (maxheight ? `${maxheight}px` : `350px`)};
  max-height: ${({ maxheight }) => (maxheight ? `${maxheight}px` : `350px`)};
  overflow-y: scroll;
`;

const CardPopCat = ({
  name,
  maxwidth,
  color,
  hideIcon,
  cid,
  count,
  notHref,
  catIcon,
}) => {
  return (
    <a href={cid && `/project/ideas/${cid}`}>
      <CardPopCarRow data-a={!notHref && "click"}>
        <Space align="baseline">
          {
            <CardPopNumber
              {...{ size: hideIcon ? 5 : 20 }}
              color={catIcon ? catIcon.color : color}
              back={catIcon && catIcon.icon}
            ></CardPopNumber>
          }
          <CardPopParagraph maxwidth={maxwidth} ellipsis={{ rows: 2 }}>
            {name}
          </CardPopParagraph>
        </Space>
        <div>{count && count}</div>
      </CardPopCarRow>
    </a>
  );
};

//////////////////////////////
/// Legend

/* data = [
  {label: text, color: color}
]
*/

const LegendItem = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 1.1;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  max-width: 250px;

  &&&& {
    & .ant-typography {
      margin-bottom: 0;
    }
  }

  &&::before {
    content: "";
    min-width: 10px;
    width: 10px;
    height: 10px;
    background: ${({ color }) => (color ? color : "grey")};
    border-radius: 50%;
    margin-right: 6px;
  }
`;

const Legend = ({ data }) => {
  return (
    <Row gutter={[20, 10]}>
      {data &&
        data?.map(({ label, color }, i) => (
          <Col key={`legend${Math.random()}-${i}`}>
            <LegendItem key={`legendItem${i}`} color={color}>
              <Paragraph ellipsis={{ rows: 1 }}>{label}</Paragraph>
            </LegendItem>
          </Col>
        ))}
    </Row>
  );
};

////////////////////////////////
/// Mini React Vis
const StyledCardRow = styled(StyledCard)`
  width: 100%;
`;

const MiniXYPlot = ({ data, loading }) => {
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <AutoSizer>
          {({ width, height }) => (
            <div className="plot" style={{ width: "100%" }}>
              <XYPlot
                xDomain={[_month, _thisDayEnd]}
                xType="time"
                height={height}
                width={width}
              >
                <XAxis
                  tickSize={1}
                  style={{
                    line: { stroke: "rgba(0, 0, 0, .1)" },
                    ticks: { stroke: "rgba(0, 0, 0, .1)" },
                    text: { stroke: "none", fill: "#6b6b76", fontSize: "8px" },
                  }}
                  tickFormat={(date) => {
                    const _date = new Date(date);
                    const dateDay = _date.getDate();
                    let dateMonth =
                      _date.getMonth() < 9
                        ? "0" + (_date.getMonth() + 1)
                        : _date.getMonth() + 1;
                    return dateDay + "." + dateMonth;
                  }}
                />
                <VerticalRectSeries
                  data={data}
                  style={{ stroke: "#fff", fill: "#5C5EF9" }}
                />
              </XYPlot>
            </div>
          )}
        </AutoSizer>
      )}
    </>
  );
};

const MiniCols = styled.div`
  width: 100%;
  display: flex;

  && div[data-type="info"] {
    width: 80px;
  }

  && div[data-type="graphics"] {
    width: 100% !important;
    height: 150px !important;
  }
`;

const StyledH3 = styled.h3`
  font-weight: 500;
  font-size: 24px;
`;

const MiniMetricsBlock = ({ data, loading, total, day }) => {
  return (
    <MiniCols style={{ width: "100%" }}>
      <div data-type="info">
        <Space direction="vertical">
          <span>Сегодня</span>
          <StyledH3>{day ? day : 0}</StyledH3>
        </Space>
        <Space data-type="info" direction="vertical">
          <span>Общее</span>
          <h4>{total ? total : 0}</h4>
        </Space>
      </div>
      <div data-type="graphics">
        <MiniXYPlot loading={loading} data={data} />
      </div>
    </MiniCols>
  );
};

/////////////////////
// Export

export {
  CardBody,
  LoadingSpinner,
  ChartBlock,
  CardPopCatWrapper,
  CardPopCat,
  CardBodyHead,
  CardPopCatOverflow,
  Legend,
  StyledCardRow,
  MiniXYPlot,
  MiniMetricsBlock,
};
