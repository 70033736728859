/**
 * External Dependencies
 */
import React, { Component } from "react";
import { connect } from "react-redux";

import "./loading.scss";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../../components/async-component";
import PageWrap from "../../../../components/page-wrap";
import PageContent from "../../../../components/page-content";
import { loadCurrentProject, loadTools } from "../../../../actions/app";
import {
  Spinner,
  Button,
  Card,
  CardTitle,
  CardBody,
  CardText,
} from "reactstrap";
import { useParams } from "react-router-dom";

import styled from "styled-components";

const StyledH1 = styled.h1`
  &&& {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 900;
  }
`;

/**
 * Component
 */
class ToolPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    return;

    // const { loadCurrentProject, currentProject, loadTools } = this.props;
    // if (!currentProject) {
    //   this.setState({
    //     isLoading: true,
    //     isNoProject: true
    //   });
    //
    //   loadCurrentProject()
    //       .then(data => {
    //         this.setState({
    //           isLoading: false,
    //           isNoProject: data == null
    //         });
    //         const { project_id } = this.props?.currentProject;
    //
    //         project_id && loadTools(project_id);
    //       })
    //       .catch(() => {
    //         this.setState({
    //           isLoading: false,
    //           isNoProject: false
    //         });
    //       });
    // } else {
    //   this.setState({
    //     isLoading: false,
    //     isNoProject: false
    //   });
    //   const { project_id } = currentProject;
    //   project_id && loadTools(project_id);
    // }
  }

  renderNoProject() {
    return (
      <PageWrap>
        <PageContent>
          <div className="rui-no-project">
            <div className="rui-no-project-block">
              <Card className="text-center">
                <CardBody>
                  <CardTitle className="h2">
                    У вас еще нет ни одного проекта
                  </CardTitle>
                  <CardText>
                    Для продолжения работы в системе вам необходимо создать ваш
                    первый проект.
                  </CardText>
                  <CardText>
                    Чтобы его создать, необходимо заполнить небольшую анкету с
                    информацией о проекте.
                  </CardText>
                  <Button color="brand">Cоздать первый проект</Button>
                </CardBody>
              </Card>
            </div>
          </div>
        </PageContent>
      </PageWrap>
    );
  }
  renderLoading() {
    return (
      <PageWrap>
        <PageContent>
          <div className="rui-component-loading">
            <div className="rui-component-loading-spinner">
              <h3>Загрузка, подождите...</h3>

              <Spinner color="secondary" type="grow">
                Загрузка, подождите...
              </Spinner>
            </div>
          </div>
        </PageContent>
      </PageWrap>
    );
  }

  render() {
    // const { currentProject, params, tools } = this.props;
    const { params, tools } = this.props;
    const { tool_id } = params;
    const currentTool = tools.filter(({ id }) => id === tool_id)?.[0];

    // if (this.state.isLoading) {
    //   return this.renderLoading();
    // }
    //
    // if (this.state.isNoProject || !currentProject) {
    //   return this.renderNoProject();
    // }

    return (
      <PageWrap>
        <PageContent>
          <StyledH1>{currentTool?.data?.title}</StyledH1>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

const WrapperComponent = () => {
  const params = useParams();

  const ToolPageConnected = connect(
    ({ app }) => ({
      params,
      // currentProject: app.currentProject,
      tools: app.tools,
    }),
    {
      loadCurrentProject: loadCurrentProject,
      loadTools,
    }
  )(ToolPage);

  return <ToolPageConnected />;
};

export default WrapperComponent;
