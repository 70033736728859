import React from "react";

import PlainSection from "../sections/plain_section";
import VariantSection from "../sections/variant-section";
import VariantsSection from "../sections/variants-section";
import RatingSection from "../sections/rating-section";

export const questionType = {
  plain: (props) => <PlainSection {...props} />,
  photo: () => <></>,
  variant: (props) => <VariantSection {...props} />,
  variants: (props) => <VariantsSection {...props} />,
  rating: (props) => <RatingSection {...props} />,
  location: () => <></>,
};
