import React from "react";

import { Skeleton } from "antd";
import { SectionCard } from "../../../../../helpers/section-card";

const EmptyFormCard = () => {
  return (
    <SectionCard
      justifyContent="space-between"
      alignItems="baseline"
      minwidth={"216"}
      minheight={"105"}
    >
      <Skeleton loading active paragraph={{ rows: 2 }} />
    </SectionCard>
  );
};

export default EmptyFormCard;
