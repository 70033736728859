/**
 * External Dependencies
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import GlobalStyle from "./global-style";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../../components/async-component";
import PageWrap from "../../../../components/page-wrap";
import PageTitle from "../../../../components/page-title";
import PageContent from "../../../../components/page-content";
import {
  loadCurrentProject,
  loadTools,
  getIdeaCategories,
} from "../../../../actions/app";
import { Spinner } from "reactstrap";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";

/**
 * Styled
 */

const StyledPageTitle = styled(PageTitle)`
  h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    padding: 0;
    color: #323232;
  }
`;

/**
 * Component
 */
class CreateToolPage extends Component {
  _isMounted = false;

  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    const { loadCurrentProject, loadTools, getIdeaCategories, ideaCategories } =
      this.props;

    this._isMounted = true;
    this.setState({
      isLoading: true,
    });

    !ideaCategories?.length > 0 && getIdeaCategories();
    loadCurrentProject().then((project) => {
      const { project_id } = { ...project };
      project_id &&
        loadTools(project_id)
          .then(() => {
            this._isMounted &&
              this.setState({
                isLoading: false,
              });
          })
          .catch((e) => e)
          .finally(() => {
            this._isMounted &&
              this.setState({
                isLoading: false,
              });
          });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  preparingRender() {
    return (
      <div className="rui-main">
        <div className="rui-first-project align-items-center justify-content-center">
          <AsyncComponent component={() => import("./content")} />
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <PageWrap>
        <PageContent>
          <div className="rui-component-loading">
            <div className="rui-component-loading-spinner">
              <h3>Загрузка, подождите...</h3>
              <Spinner color="secondary" type="grow">
                Загрузка, подождите...
              </Spinner>
            </div>
          </div>
        </PageContent>
      </PageWrap>
    );
  }

  render() {
    const { is_preparing, params, location } = this.props;
    const { action } = { ...params };
    const { search } = { ...location };
    const { t = "1" } = { ...queryString.parse(search) };
    const type = parseInt(t);

    if (this.state.isLoading) {
      return this.renderLoading();
    }

    if (is_preparing) {
      return this.preparingRender();
    }

    const handleName = (type) => {
      switch (type) {
        case 1:
          return "сбор идей";
        case 2:
          return "опрос";
        case 3:
          return "голосвание";
        case 4:
          return "ветвистый опрос";
        default:
          return "инструмент";
      }
    };

    const name = handleName(type);

    return (
      <PageWrap>
        <GlobalStyle />
        <StyledPageTitle>
          <h1>
            {action === "edit"
              ? "Редактировать инструмент"
              : action === "create"
              ? `Нов${type === 3 ? "ое" : "ый"} ${name}`
              : "Анкета проекта"}
          </h1>
        </StyledPageTitle>
        <PageContent>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

const WrapperComponent = () => {
  const params = useParams();
  const location = useLocation();

  const CreateToolPageConnected = connect(
    ({ app, auth, settings }) => {
      const { is_preparing } = { ...auth };
      const { currentProject, ideaCategories } = { ...app };

      return {
        app,
        settings,
        params,
        location,
        is_preparing,
        currentProject,
        ideaCategories,
      };
    },
    {
      loadCurrentProject,
      loadTools,
      getIdeaCategories,
    }
  )(CreateToolPage);

  return <CreateToolPageConnected />;
};

export default WrapperComponent;
