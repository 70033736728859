import React, { useEffect, useMemo } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";

import { vocType } from "../helpers/voc-type";
import { vocTypeLabel } from "../helpers/voc-type-label";
import { Alert } from "antd";

import { ANSWERS_DIALOG_HUB } from "../../../../../queries/index";

import { makeQuery } from "../helpers/make-query";

import CompletedForms from "./completed-forms";

import {
  AnswersVocWrapper,
  StyleP,
  AnswerVocCard,
} from "../helpers/local-styled";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const DataStatusWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnswersVocType = (props) => {
  let { currentProjectId, bot_id, bot_location } = props;
  let { ordering, type, dialog_answers, nodeKey } = props;

  //пересортировать список вариантов ответа исходя из ordering
  let dialog_answers_sorted = [...dialog_answers];
  if (Array.isArray(dialog_answers))
    dialog_answers_sorted.sort((a, b) => a?.ordering - b?.ordering);

  /* Lazy Query */
  const [getAnswersList, { data: AnswersList, loading: l_AnswersList }] =
    makeQuery(ANSWERS_DIALOG_HUB);

  const limitPerPage = type === "plain" ? 8 : type === "photo" ? 15 : null; //лимит ответов для блоков типа plain и photo

  useEffect(() => {
    getAnswersList({
      variables: {
        project_id: currentProjectId,
        bot_id,
        node_key: nodeKey,
        //limit: type === "plain" || type === "photo" ? limitPerPage : null,
      },
    });
  }, [currentProjectId, bot_id, nodeKey]);

  const messagesList = useMemo(() => {
    if (AnswersList) {
      const _list = AnswersList?.chat_dialogs;

      return _list;
    }
  }, [AnswersList]);

  return (
    <Row>
      <Col span={24}>
        {vocType?.[type] &&
          vocType?.[type]({
            answers: messagesList,
            ordering,
            loading: l_AnswersList,
            dialog_answers: dialog_answers_sorted,
            limitPerPage,
            bot_location,
          })}
      </Col>
    </Row>
  );
};

/* Блок с выводом всех Ответов */

const AnswersVoc = (props) => {
  const { data, loading, error, bot_id, bot_location, ...other } = props;
  const { currentProjectId } = props;

  if (!loading) {
    if (data) {
      const nodes = data;

      // Общее количество вопросов
      const nodesCount = nodes?.length;

      return (
        <AnswersVocWrapper>
          <CompletedForms {...{ currentProjectId, bot_id }} />

          {nodes?.map(
            ({ text, answers: dialog_answers, type, ordering, key }, i) => {
              const nodeOrdering = i + 1;
              const nodeType = vocTypeLabel[type] && `(${vocTypeLabel[type]})`;
              const nodeQuestion = text;
              const nodeKey = key;

              return (
                <div key={`bot_question${i}:${ordering}`}>
                  <StyleP>{`Вопрос ${nodeOrdering} из ${nodesCount} ${nodeType}`}</StyleP>
                  <AnswerVocCard title={nodeQuestion}>
                    <AnswersVocType
                      {...{
                        dialog_answers,
                        ordering,
                        nodeKey,
                        type,
                        bot_id,
                        bot_location,
                        ...other,
                      }}
                    />
                  </AnswerVocCard>
                </div>
              );
            }
          )}
        </AnswersVocWrapper>
      );
    } else {
      return (
        <DataStatusWrapper>
          <div>No Data</div>
        </DataStatusWrapper>
      );
    }
  } else if (error) {
    return (
      <DataStatusWrapper>
        <Alert message="Произошла ошибка при загрузке данных" type="error" />
      </DataStatusWrapper>
    );
  } else {
    return (
      <DataStatusWrapper>
        <Spin indicator={antIcon} />
      </DataStatusWrapper>
    );
  }
};

export default AnswersVoc;
