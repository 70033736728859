/**
 * External Dependencies
 */
import React, { useState } from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { pick } from "../../helpers/fn";
import BaseMap from "./index";

const Popover = styled.div`
  width: 340px;
  min-height: 50px;
  border-radius: 3px;
  background: #fff;
  border: #222;
  box-shadow: 0 0 3px #111;

  box-sizing: border-box;
  padding: 10px 0;
  color: #000;

  transform: translate(-50%, 3rem);

  .header {
    padding: 0 10px 10px 10px;
    border-bottom: 1px solid #ddd;
    color: #0066ff;
  }

  .content {
    padding: 10px;
    max-height: 11rem;
    overflow: hidden;

    p {
      .lead {
        font-size: 1.385rem;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .controls {
    text-align: center;
    margin: 10px 0;
  }

  & .con {
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    box-sizing: border-box;
    transform: translate(-50%, -100%);
    width: 20px;
    height: 10px;

    &::before {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      box-sizing: border-box;

      top: 0;
      border-width: 0 10px 10px 10px;
      border-bottom-color: rgba(0, 0, 0, 0.25);
    }
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      box-sizing: border-box;

      top: 1px;
      border-width: 0 10px 10px 10px;
      border-bottom-color: #fff;
    }
  }
`;

const renderButton = ({ text, href = undefined, onClick = undefined }) => {
  const buttonText = text || "Перейти";

  if (href) {
    return (
      <Link
        to={href}
        className="btn btn-brand btn-long btn-round"
        onClick={onClick}
      >
        {buttonText}
      </Link>
    );
  }

  return (
    <Button className="btn-long btn-round" color="brand" onClick={onClick}>
      {buttonText}
    </Button>
  );
};

const Tooltip = props => {
  const showControls =
    props.button &&
    (pick("button.href", props) || pick("button.onClick", props));

  return props.isTooltip ? (
    <Popover
      placement="right"
      style={{
        position: "absolute",
        zIndex: 1,
        // pointerEvents: 'none',
        left: props.x,
        top: props.y
      }}
    >
      {props.header && <div className="header">{props.header}</div>}

      <div className="content">
        <p className="lead">{props.lead}</p>
        <p>{props.text}</p>
      </div>

      {showControls && (
        <div className="controls">{renderButton(props.button)}</div>
      )}

      <span className="con" />
    </Popover>
  ) : null;
};

const TooltipMap = ({ initialViewState, layers, getPopover }) => {
  const [tooltipState, setTooltipState] = useState({
    clickedObject: null,
    x: 0,
    y: 0,
    isTooltip: false
  });

  const [dragState, setDragState] = useState({
    startX: 0,
    startY: 0,
    isDragging: false
  });

  return (
    <>
      <BaseMap
        {...{ initialViewState }}
        layers={layers}
        onViewStateChange={({ viewState, oldViewState }) => {
          if (
            oldViewState?.zoom === viewState?.zoom + 1 ||
            oldViewState?.zoom === viewState?.zoom - 1
          ) {
            setTooltipState({
              isTooltip: false
            });
          }
        }}
        onDragStart={info => {
          if (tooltipState.isTooltip) {
            setDragState({
              startX: info.x - tooltipState.x,
              startY: info.y - tooltipState.y,
              isDragging: true
            });
          }
        }}
        onDrag={info => {
          if (tooltipState.isTooltip) {
            setTooltipState({
              ...tooltipState,
              x: info.x - dragState.startX,
              y: info.y - dragState.startY
            });
          }
        }}
        // onDragEnd={(info, e) => {
        //     //
        // }}
        onClick={e => {
          const { layer, object, x, y } = e;
          if (layer && object) {
            const popoverData = getPopover ? getPopover(object) : {};
            setTooltipState({
              clickedObject: object,
              ...popoverData,
              x,
              y,
              isTooltip: true
            });
          } else {
            setTooltipState({
              isTooltip: false
            });
          }
        }}
      />
      <Tooltip {...tooltipState} />
    </>
  );
};

export default TooltipMap;
