import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { StyledCard, Tally } from "../../Styled/styled";
import {
  VisitContent,
  VisitContentLine,
  VisitContentLineWrapper,
} from "./styled/common";
import { ArrowInWrapper } from "./styled/other";
import Tokenizer from "../../../../../helpers/tokenizer";

import EmptyFormCard from "./emptyCard";
import BotsWrapper from "./botsWrapper";

import { useLazyQuery } from "@apollo/client";
import { QUESTIONNAIRE_SUMMARY_QUERY1 } from "../../../../../queries/index";

/* список ботов */
const BotsList = ({
  botParent,
  setBotParent,
  currentProjectId,
  botLocation,
  setBotLocation,
  needsLocation = false,
}) => {
  /*  Стэйты для скролла блока с инструментами */
  const [isLeftArrowVisible, setLeftArrowVisible] = useState(false);
  const [isRightArrowVisible, setRightArrowVisible] = useState(false);

  const [localTransition, setLocalTransition] = useState(null);

  const { auth } = useSelector((state) => state);
  const token = auth.access_token;

  /* query запрос к получению всех ботов */
  const [
    getQuestionnaireList,
    {
      data: QuestionnaireList,
      loading: load_QuestionnaireList,
    },
  ] = useLazyQuery(QUESTIONNAIRE_SUMMARY_QUERY1, {
    context: {
      headers: {
        Authorization: Tokenizer.authHeader(token),
      },
    },
  });

  /* Use Effect */
  useEffect(() => {
    getQuestionnaireList({
      variables: {
        project_id: currentProjectId,
      },
    });
  }, [currentProjectId]);

  //пробует взять анкету первого бота из списка
  useEffect(() => {
    if (QuestionnaireList) {
      const { dialog_bot: botsList = [] } = QuestionnaireList;
      if (botsList?.length > 0) {
        const { id: localBotId, landing_link = {} } =
          botsList && botsList?.[0] ? botsList?.[0] : [];
        setBotParent(localBotId);

        //берем координаты карты, если требуется
        let loc;

        if (needsLocation) {
          const { landing = {} } = landing_link ? landing_link : {};

          const { data: landingData = {} } = landing ? landing : {};
          const { location = {} } = landingData;

          loc = location;
        }

        if (loc && needsLocation) setBotLocation(loc);
      }
    }
  }, [QuestionnaireList]);

  return (
    <div className="mt-25">
      <div className="rui-profile row vertical-gap">
        <div className="col-12">
          <StyledCard background={"#FBFBFB"} className="card">
            <div className="card-body">
              <Tally>Выберите источник данных для вывода ответов</Tally>
              <div className="mt-20">
                <VisitContent>
                  {isLeftArrowVisible && (
                    <ArrowInWrapper
                      data-pos="left"
                      background={"#FBFBFB"}
                      onClick={() => setLocalTransition(-216)}
                    />
                  )}
                  {isRightArrowVisible && (
                    <ArrowInWrapper
                      data-pos="right"
                      background={"#FBFBFB"}
                      onClick={() => setLocalTransition(216)}
                    />
                  )}
                  {load_QuestionnaireList ? (
                    <VisitContentLineWrapper>
                      <VisitContentLine>
                        <EmptyFormCard />
                        <EmptyFormCard />
                        <EmptyFormCard />
                        <EmptyFormCard />
                        <EmptyFormCard />
                        <EmptyFormCard />
                      </VisitContentLine>
                    </VisitContentLineWrapper>
                  ) : (
                    <BotsWrapper
                      data={QuestionnaireList}
                      {...{
                        botParent,
                        setBotParent,
                        setLeftArrowVisible,
                        setRightArrowVisible,
                        localTransition,
                        setLocalTransition,
                        botLocation,
                        setBotLocation,
                        needsLocation,
                      }}
                    />
                  )}
                </VisitContent>
              </div>
            </div>
          </StyledCard>
        </div>
      </div>
    </div>
  );
};

export default BotsList;
