import Tokenizer from "../helpers/tokenizer";
import { AUTH_UPDATE, AUTH_LOGOUT, AUTH_LOGIN } from "../actions/auth";

/**
 * External Dependencies
 */
const INITIAL_AUTH_STATE = {
  access_token: Tokenizer.load(),
  is_novice: false,
  is_preparing: false
};

export default function(state = INITIAL_AUTH_STATE, action) {
  switch (action.type) {
    case AUTH_LOGIN:
    case AUTH_UPDATE:
      // save token to cookies for 3 days.
      return {
        ...state,
        ...action.payload
      };
    case AUTH_LOGOUT:
      return {
        access_token: undefined,
        refresh_token: undefined
      };
    default:
      return state;
  }
}
