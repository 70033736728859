import Tokenizer from "../helpers/tokenizer";
import { authClient } from "../clients/gql-client";
import {
  USER_EXISTS_QUERY,
  SIGN_OUT_MUTATION,
  PASSWORD_RECOVERY_MUTATION,
  PASSWORD_UPDATE_MUTATION,
  SIGN_IN_MUTATION,
  SIGN_UP_MUTATION,
} from "../queries";
import { cleanupApp } from "./app";

const handleTypeName = (res) => {
  const { __typename, ...rest } = { ...res };

  return rest;
};

export const auth_loginError = () => ({
  type: "AUTH_LOGIN_ERROR",
});

export const AUTH_LOGIN = "AUTH_LOGIN";
export const authLogin = ({ email, password, remember }) => (dispatch) =>
  authClient
    .mutate({
      mutation: SIGN_IN_MUTATION,
      variables: { email, password },
    })
    .then((res) => {
      const token = handleTypeName(res?.data?.token);
      const { access_token, refresh_token } = { ...token };

      try {
        dispatch({
          type: AUTH_LOGIN,
          payload: {
            ...token,
            ...Tokenizer.decode(access_token),
          },
        });

        Tokenizer.store(access_token, refresh_token, remember && email);
      } catch (e) {
        dispatch(auth_loginError);

        return Promise.reject(res);
      }

      return Promise.resolve(token);
    })
    .catch((error) => {
      dispatch(auth_loginError);
      // handleError(error, getState(), dispatch);

      return Promise.reject(error);
    });

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const authLogout = () => (dispatch, getState) => {
  const { access_token: token } = { ...getState()?.auth };

  return authClient
    .mutate({
      mutation: SIGN_OUT_MUTATION,
      context: {
        headers: {
          Authorization: Tokenizer.authHeader(token),
        },
      },
    })
    .then((res) => {
      dispatch(cleanupApp());

      return Promise.resolve(res?.data?.response);
    })
    .catch((error) => Promise.reject(error))
    .finally(() => {
      dispatch({
        type: AUTH_LOGOUT,
      });
      Tokenizer.clear();
      authClient.clearStore().catch((e) => e);
    });
};

export const AUTH_CHECK = "AUTH_CHECK";
export const authCheck = () => (dispatch, getState) => {
  /**
   * Проверяет есть ли пользователь
   */

  const { access_token: token } = { ...getState()?.auth };

  return authClient
    .query({
      query: USER_EXISTS_QUERY,
      context: {
        headers: {
          Authorization: Tokenizer.authHeader(token),
        },
      },
    })
    .then((res) => {
      const response = handleTypeName(res?.data?.response);

      dispatch({
        type: AUTH_CHECK,
        payload: {
          ...response,
        },
      });

      return Promise.resolve(response);
    })
    .then((data) => {
      dispatch(authUpdate(data));

      return Promise.resolve(data);
    })
    .catch((error) => {
      dispatch(auth_error(error));

      // handleError(error, getState(), dispatch);
      return Promise.reject(error);
    });
};

export const AUTH_UPDATE = "AUTH_UPDATE";
export const authUpdate = (auth) => ({
  type: AUTH_UPDATE,
  payload: {
    ...auth,
  },
});

export const AUTH_ERROR = "AUTH_ERROR";
export const auth_error = (error) => ({
  type: AUTH_ERROR,
  error,
});

export const authSignUp = (variables) => (dispatch) =>
  authClient
    .mutate({
      mutation: SIGN_UP_MUTATION,
      variables,
    })
    .then((res) => {
      const token = handleTypeName(res?.data?.token);
      const { access_token, refresh_token } = { ...token };

      try {
        dispatch({
          type: AUTH_LOGIN,
          payload: {
            ...token,
            ...Tokenizer.decode(access_token),
          },
        });

        Tokenizer.store(access_token, refresh_token);
      } catch (e) {
        dispatch(auth_loginError);

        return Promise.reject(res);
      }

      return Promise.resolve(token);
    })
    .catch((error) => {
      dispatch(auth_loginError);
      // handleError(error, getState(), dispatch);
      return Promise.reject(error);
    });

export const authPasswordRecovery = (variables) => () =>
  authClient
    .mutate({
      mutation: PASSWORD_RECOVERY_MUTATION,
      variables,
    })
    .then((res) => Promise.resolve(res?.data?.response))
    .catch((error) => Promise.reject(error));

export const authPasswordUpdate = ({ password, token }) => () =>
  authClient
    .mutate({
      mutation: PASSWORD_UPDATE_MUTATION,
      variables: { password },
      context: {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      },
    })
    .then((res) => Promise.resolve(res?.data?.response))
    .catch((error) => Promise.reject(error));
