import React, { useEffect, useState } from "react";
import { makeQuery } from "../helpers/make-query";
import { completedFormsQuery } from "../../../../../queries/index";

import { Col, Row } from "antd";
import { StyleP, AnswerVocCard, CommonCount } from "../helpers/local-styled";

const CompletedForms = ({ currentProjectId, bot_id }) => {
  //стэйт с общим количеством анкет
  const [totalCount, setTotalCount] = useState(0);

  const [getForms, { data: forms, loading: l_forms }] =
    makeQuery(completedFormsQuery);

  useEffect(() => {
    getForms({
      variables: {
        project_id: currentProjectId,
        bot_id,
      },
    });
  }, [currentProjectId, bot_id]);

  // Суммируем количество анкет исходя их кол-ва раундов
  useEffect(() => {
    if (forms) {
      let updatedForms = forms.chats.reduce((sum, { round }) => {
        const updatedCount = round && round >= 2 ? round - 1 : 0;
        return sum + updatedCount;
      }, 0);

      setTotalCount(updatedForms);
    }
  }, [forms]);

  return (
    <Row>
      <Col span={5}>
        <StyleP>Всего заполненных анкет</StyleP>
        <AnswerVocCard>
          <CommonCount>{l_forms ? "-" : totalCount}</CommonCount>
        </AnswerVocCard>
      </Col>
    </Row>
  );
};

export default CompletedForms;
