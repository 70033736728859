/**
 * External Dependencies
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

//import { withRouter, BrowserRouter as Router, Route } from "react-router-dom";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../../components/async-component";
import PageWrap from "../../../../components/page-wrap";
import PageTitle from "../../../../components/page-title";
import PageContent from "../../../../components/page-content";
import { Card, CardBody, CardTitle, Spinner } from "reactstrap";
import { getIdea } from "../../../../actions/app";
import { loadCurrentProject } from "../../../../actions/app";

import styled from "styled-components";

const StyledH1 = styled.h1`
  &&& {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 900;
  }
`;

/**
 * Component
 */
class IdeaPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isNoData: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      isLoading: true,
      isNoData: true,
    });

    const { getIdea, params } = this.props;

    getIdea(params.idea)
      .then((data) => {
        this._isMounted &&
          this.setState({
            isLoading: false,
            isNoData: data == null,
          });
      })
      .catch(() => {
        this._isMounted &&
          this.setState({
            isLoading: false,
            isNoData: false,
          });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderNoData() {
    return (
      <PageWrap>
        <PageContent>
          <div className="rui-no-project">
            <div className="rui-no-project-block">
              <Card className="text-center">
                <CardBody>
                  <CardTitle className="h2">
                    К сожалению, идея №{this.props.params.idea} не найдена
                  </CardTitle>
                </CardBody>
              </Card>
            </div>
          </div>
        </PageContent>
      </PageWrap>
    );
  }
  renderLoading() {
    return (
      <PageWrap>
        <PageContent>
          <div className="rui-component-loading">
            <div className="rui-component-loading-spinner">
              <h3>Загрузка, подождите...</h3>

              <Spinner color="secondary" type="grow">
                Загрузка, подождите...
              </Spinner>
            </div>
          </div>
        </PageContent>
      </PageWrap>
    );
  }

  render() {
    // const { app } = this.props;

    const idea_id = this.props.params.idea;

    if (this.state.isLoading) {
      return this.renderLoading();
    }

    if (this.state.isNoData) {
      return this.renderNoData();
    }

    return (
      <PageWrap>
        <div className="rui-page-content">
          <div className="container-fluid">
            <PageTitle>
              <StyledH1>Идея №{idea_id}</StyledH1>
            </PageTitle>
          </div>
        </div>
        <PageContent>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </PageWrap>
    );
  }
}

const WrapperComponent = () => {
  const params = useParams();

  const IdeaPageConnected = connect(
    ({ app, settings }) => ({
      app,
      params,
      settings,
      currentProject: app.currentProject,
      currentProjectId: app.currentProjectId,
      idea: app.currentIdea,
    }),
    {
      getIdea,
      loadCurrentProject,
    }
  )(IdeaPage);

  return <IdeaPageConnected />;
};

export default WrapperComponent;
