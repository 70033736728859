import React, { useEffect, useState } from "react";
import PageWrap from "../../../../components/page-wrap";
import PageTitle from "../../../../components/page-title";
import PageContent from "../../../../components/page-content";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  FEEDBACK_QUERY,
  UPDATE_FEEDBACK_QUERY,
} from "../../../../queries/feedback";
import {
  CTitle as Title,
  Gap,
  CParagraph as Paragraph,
} from "../Subscription/components/styles";
import FancyBox from "../../../../components/fancybox";
import Icon from "../../../../components/icon";

import moment from "moment";

import styled from "styled-components";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  Statistic,
  Divider,
  Tabs,
  Skeleton,
  notification,
} from "antd";
const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  && .rui-tabs-link.active {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    font-size: 14px;
  }

  && .nav-link {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }

  && .rui-tabs-slide {
    border-bottom: 2px solid #5C5EF9;
  }
`;

const StyledH1 = styled.h1`
  &&& {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 900;
  }
`;

const WhiteBlockWrapper = styled.div`
  background: white;
  width: 100%;
  max-width: 1900px;
  border-radius: 8px;
  box-shadow: 1px 1px 5px 1px rgba(2, 18, 53, 0.09);
  position: relative;
  overflow: hidden;
  min-height: 150px;
  height: 100%;

  &[data-size="small"] {
    padding: 20px 30px;
  }

  &[data-size="large"] {
    padding: 30px 40px;
  }
`;

const successfulResponse = () => {
  notification.success({
    message: `Успешно!`,
    description: "Запись изменена",
    placement: "top",
  });
};

const errorResponse = () => {
  notification.error({
    message: "Ошибка!",
    description: "Запись не была добавлена",
    placement: "top",
  });
};

const WhiteBlock = ({ children, size = "large", type }) => {
  return (
    <WhiteBlockWrapper data-size={size} data-type={type}>
      {children}
    </WhiteBlockWrapper>
  );
};

const FeedbackIdea = ({}) => {
  const params = useParams();
  const { feedback: feedback_id } = params;

  const [editMode, setEditMode] = useState(false);

  const [getFeedback, { data, loading, error }] = useLazyQuery(FEEDBACK_QUERY);

  if (loading) console.log("loading", loading);
  if (error) console.log("error", error.message);

  const [
    updateMessage,
    { data: updMessage, loading: updMessage_loading, error: updMessage_error },
  ] = useMutation(UPDATE_FEEDBACK_QUERY, {
    update(cache, result) {
      console.log("cache", cache);
      console.log("result", result);
    },
  });

  useEffect(() => {
    if (updMessage) successfulResponse();
  }, [updMessage]);

  useEffect(() => {
    if (updMessage_error) errorResponse();
  }, [updMessage_error]);

  useEffect(() => {
    getFeedback({
      variables: {
        id: feedback_id,
      },
    });
  }, [feedback_id]);

  const { chatFeedback = {} } = data ? data : {};
  const {
    bill,
    /*category,*/
    /*feedback_date,*/
    chat_user = {},
    cr,
    id,
    is_in_bar,
    message,
    rating,
    photos,
  } = chatFeedback;

  const { nickname = null } = chat_user ? chat_user : {};

  const noMeta = <i>(данных нет)</i>;

  return (
    <PageWrap>
      <div className="rui-page-content">
        <div className="container-fluid">
          <PageTitle>
            <StyledH1>Фидбэк №{feedback_id}</StyledH1>
          </PageTitle>
        </div>
      </div>
      <PageContent>
        <Row gutter={[24, 24]}>
          <Col span={16}>
            <WhiteBlock>
              {data && message ? (
                <Form
                  layout="vertical"
                  onFinish={(e = {}) => {
                    {
                      const { message: formMessage } = e;

                      return updateMessage({
                        variables: { data: { message: formMessage }, id },
                      });
                    }
                  }}
                >
                  <Form.Item name="message" initialValue={message}>
                    <Input.TextArea
                      autoSize={{ minRows: 4, maxRows: 6 }}
                      disabled={!editMode}
                    ></Input.TextArea>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      disabled={updMessage_loading}
                      {...(!editMode && { htmlType: "submit" })}
                      onClick={() => setEditMode(!editMode)}
                    >
                      {editMode ? <>Сохранить</> : <>Редактировать</>}
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Skeleton active />
              )}
            </WhiteBlock>
          </Col>
          <Col span={8}>
            <WhiteBlock size="small">
              {data && message ? (
                <>
                  <Space direction="vertical" size={8}>
                    <Title
                      style={{ marginTop: 0 }}
                      data-margin="nomargin"
                      data-size="18"
                    >
                      Мета данные
                    </Title>

                    <Gap sheight="18px" />

                    <Space>
                      <Title
                        style={{ marginTop: 0 }}
                        data-margin="nomargin"
                        data-size="14"
                      >
                        Никнейм:{" "}
                      </Title>
                      <Paragraph data-margin="nomargin">
                        {nickname ? nickname : noMeta}
                      </Paragraph>
                    </Space>

                    <Space>
                      <Title
                        style={{ marginTop: 0 }}
                        data-margin="nomargin"
                        data-size="14"
                      >
                        Счет:{" "}
                      </Title>
                      <Paragraph data-margin="nomargin">
                        {bill ? bill : noMeta}
                      </Paragraph>
                    </Space>

                    {/* категория пока не готова. Добавить позже */}
                    {/*<Space>
                      <Title
                        style={{ marginTop: 0 }}
                        data-margin="nomargin"
                        data-size="14"
                      >
                        Категория:{" "}
                      </Title>
                      <Paragraph data-margin="nomargin">
                        {category ? category : noMeta}
                      </Paragraph>
                    </Space>*/}

                    <Space>
                      <Title
                        style={{ marginTop: 0 }}
                        data-margin="nomargin"
                        data-size="14"
                      >
                        Дата создания:{" "}
                      </Title>
                      <Paragraph data-margin="nomargin">
                        {cr ? moment().to(cr) : noMeta}
                      </Paragraph>
                    </Space>

                    <Space>
                      <Title
                        style={{ marginTop: 0 }}
                        data-margin="nomargin"
                        data-size="14"
                      >
                        Статус:{" "}
                      </Title>
                      <Paragraph data-margin="nomargin">
                        {is_in_bar ? "В баре" : "Не в баре"}
                      </Paragraph>
                    </Space>
                  </Space>

                  <Divider />

                  <Row gutter={[24, 24]} style={{ marginBottom: 0 }}>
                    <Col span={24} style={{ marginBottom: 0 }}>
                      <Statistic title="Рейтинг" value={rating ? rating : 0} />
                    </Col>
                  </Row>
                </>
              ) : (
                <Skeleton paragraph={{ rows: 8 }} active />
              )}
            </WhiteBlock>
          </Col>
        </Row>

        <StyledTabs defaultActiveKey="photos" sliding>
          <TabPane tab="Фотографии" key="photos">
            <Row>
              <Col span={24}>
                <WhiteBlock>
                  {photos
                    ? photos.map((item, i) => (
                        <Col key={i} xs={6} lg={3}>
                          <FancyBox
                            tagName="a"
                            className="rui-gallery-item"
                            href={item?.url}
                            closeExisting
                            popupClassName="rui-popup"
                            galleryId="idea-gallery"
                          >
                            <span className="rui-gallery-item-overlay rui-gallery-item-overlay-md">
                              <Icon name="maximize" />
                            </span>
                            <img src={item?.url} className="rui-img" alt="" />
                          </FancyBox>
                        </Col>
                      ))
                    : noMeta}
                </WhiteBlock>
              </Col>
            </Row>
          </TabPane>
        </StyledTabs>
      </PageContent>
    </PageWrap>
  );
};

export default FeedbackIdea;
