import React, { useMemo } from "react";
import { Skeleton, Col, Row, List } from "antd";
import styled from "styled-components";

import { Alert, Image } from "antd";

/**
 *
 * Тип ответов — photo
 *
 */

const PhotoImg = styled(Image)`
  background-color: grey;
  background-size: cover;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  transition: all 0.3s ease-in-out;

  &&:hover {
    transform: translateX(-50%) translateY(-50%) scale(1.1, 1.1);
    opacity: 0.8;
  }
`;

const PhotoWrapper = styled.div`
  min-width: 208px;
  min-height: 120px;

  max-width: 208px;
  max-height: 120px;

  background-color: grey;
  overflow: hidden;

  position: relative;

  cursor: pointer;
`;

const PhotoBlock = ({ answers, loading, limitPerPage }) => {
  let photoArr = useMemo(() => {
    const _arr = [];
    if (answers) {
      answers?.map(({ message }) => {
        const { attachment } = message;
        const { photo } = attachment;

        photo?.map(({ url }) => {
          _arr.push({ url });
        });
      });
    }

    return _arr;
  }, [answers]);

  return (
    <Row>
      <Col span={24}>
        {loading && (
          <Alert
            message="Блок может грузиться дольше обычного, потому что работает в тестовом режиме..."
            type="warning"
            showIcon
          />
        )}
        <Skeleton active loading={loading}>
          <List
            grid={{ gutter: 16, column: 5 }}
            dataSource={photoArr}
            pagination={{
              defaultPageSize: limitPerPage,
            }}
            renderItem={({ url }) => (
              <List.Item>
                {
                  <PhotoWrapper>
                    <PhotoImg
                      width={208}
                      src={url}
                      key={`answer${Math.random()}`}
                      {...{ url }}
                    />
                  </PhotoWrapper>
                }
              </List.Item>
            )}
          />
        </Skeleton>
      </Col>
    </Row>
  );
};

export default PhotoBlock;
