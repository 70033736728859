export const UPDATE_AUTH = 'UPDATE_AUTH';
export const UPDATE_FIRST_TIME = 'UPDATE_FIRST_TIME';
export const updateAuth = ( auth ) => ( {
    type: UPDATE_AUTH,
    auth,
} );

export const updateFirstTime = ( is_novice ) => ( {
    type: UPDATE_AUTH,
    auth: {
        is_novice,
    },
} );

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const updateSettings = ( settings ) => ( {
    type: UPDATE_SETTINGS,
    settings,
} );

export const ADD_TOAST = 'ADD_TOAST';
export const addToast = ( data ) => ( {
    type: ADD_TOAST,
    data,
} );

export const REMOVE_TOAST = 'REMOVE_TOAST';
export const removeToast = ( id ) => ( {
    type: REMOVE_TOAST,
    id,
} );

export const UPDATE_APP = 'UPDATE_APP';
export const updateApp = (payload) => ({
    type: UPDATE_APP,
    payload,
});

