import React from "react";
import { Bar, Pie } from "@ant-design/charts";

/* компонент по отрисовки barChart и pieChart для секции "Общее" */
const GraphicsChart = ({
  barConfig = {},
  pieConfig = {},
  currentType = "bar",
}) => {
  barConfig = barConfig || {};
  pieConfig = pieConfig || {};

  //проверяем на существование data внутри config. Иначе выдаст typeError
  const { data: barData = null } = barConfig;
  const { data: pieData = null } = pieConfig;

  //необходимые условия для отображения
  const showBar = currentType === "bar" && barData;
  const showPie = currentType === "pie" && pieData;

  return showBar ? (
    <Bar {...barConfig} />
  ) : showPie ? (
    <Pie {...pieConfig} />
  ) : null;
};

export default GraphicsChart;
