import { gql } from "@apollo/client";

const PROJECT_QUESTIONNAIRE_QUERY = gql`
  query MyQuery($project_id: uuid) {
    chats(
      where: {
        bot: { landing_link: { landing: { project_id: { _eq: $project_id } } } }
      }
      order_by: { cr: desc_nulls_last }
    ) {
      id
      key
      scenario
      node
      cr
      dialogs {
        user {
          full_name
        }
      }
      referer
      round
    }
  }
`;

const UPDATE_IDEAS_QUERY = gql`
  mutation MyQuery(
    $item_id: uuid
    $bot_id: uuid
    $project_id: uuid
    $state: smallint
  ) {
    updateDialogs(
      where: {
        id: { _eq: $item_id }
        chat: {
          bot: {
            landing_link: { landing: { project_id: { _eq: $project_id } } }
          }
          bot_id: { _eq: $bot_id }
        }
      }
      _set: { state: $state }
    ) {
      affected_rows
      returning {
        id
        state
      }
    }
  }
`;

const DELETE_IDEAS_QUERY = gql`
  mutation MyQuery($item_id: uuid!, $bot_id: uuid!, $project_id: uuid!) {
    deleteDialogs(
      where: {
        id: { _eq: $item_id }
        chat: {
          bot: {
            landing_link: { landing: { project_id: { _eq: $project_id } } }
          }
          bot_id: { _eq: $bot_id }
        }
      }
    ) {
      affected_rows
    }
  }
`;

const PROJECT_IDEAS_QUERY_ALL = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
    $parent_id: Boolean
    $search_by_string: String
    $order_by: [dialog_chat_dialog_sat_order_by!]
    $sentiment: dialog_chat_idea_sat_bool_exp
    $published: Boolean
    $limit: Int
    $idea_id: dialog_chat_idea_sat_bool_exp
    $bot_id: dialog_chat_hub_bool_exp
    $user_fake: dialog_chat_user_sat_bool_exp
    $message_is_null: String_comparison_exp
    $_and: [dialog_chat_dialog_sat_bool_exp]
  ) {
    chat_dialogs(
      where: {
        _or: [
          { _and: $_and }
          {
            _and: [
              { user: $user_fake }
              { chat: $bot_id }
              { idea: { is_published: { _eq: $published } } }
              { idea: $sentiment }
              { idea: { message: { _ilike: $search_by_string } } }
              { parent_id: { _is_null: $parent_id } }
              { cr: { _gte: $start_date } }
              { cr: { _lte: $end_date } }
              { idea: { message: $message_is_null } }
              {
                chat: {
                  bot: {
                    landing_link: {
                      landing: { project_id: { _eq: $project_id } }
                    }
                  }
                }
              }
            ]
          }
        ]
      }
      order_by: $order_by
      limit: $limit
    ) {
      idea {
        met_sents {
          sentiment
        }
      }
      cr
      dialog_node {
        text
      }
      id
      node
      chat {
        bot {
          landing_link {
            landing {
              data(path: "title")
            }
          }
          id
        }
      }
      chat {
        bot {
          platform {
            name
          }
        }
      }
      message
      user {
        full_name
      }
      comment_of_solution {
        description
      }
      comment_of_idea {
        message
      }
      state
      parent_id
      idea {
        id
        message
        is_published
      }
    }
  }
`;

const PROJECT_IDEAS_QUERY = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
    $parent_id: Boolean
    $search_by_string: String
    $order_by: [dialog_chat_dialog_sat_order_by!]
    $sentiment: dialog_chat_idea_sat_bool_exp
    $published: Boolean
    $limit: Int
    $idea_id: dialog_chat_idea_sat_bool_exp
    $bot_id: dialog_chat_hub_bool_exp
    $user_fake: dialog_chat_user_sat_bool_exp
    $message_is_null: String_comparison_exp
  ) {
    chat_dialogs(
      where: {
        _and: [
          { user: $user_fake }
          { chat: $bot_id }
          { idea: { is_published: { _eq: $published } } }
          { idea: $sentiment }
          { idea: { message: { _ilike: $search_by_string } } }
          { parent_id: { _is_null: $parent_id } }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
          { idea: { message: $message_is_null } }
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
        ]
      }
      order_by: $order_by
      limit: $limit
    ) {
      idea {
        met_sents {
          sentiment
        }
      }
      cr
      dialog_node {
        text
      }
      id
      node
      chat {
        bot {
          landing_link {
            landing {
              data(path: "title")
            }
          }
          id
        }
      }
      chat {
        bot {
          platform {
            name
          }
        }
      }
      message
      user {
        full_name
      }
      idea {
        id
        message
        is_published
      }
    }
  }
`;

/*const PROJECT_IDEAS_QUERY = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $search_by_string: String
    $order_by: [metric_met_sen_rep_order_by!]
    $project_id: uuid
    $sentiment_id: Int
    $parent_id: Boolean
  ) {
    metric_met_sen_rep(
      where: {
        _and: [
          { dialog: { parent_id: { _is_null: $parent_id } } }
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { token: { _ilike: $search_by_string } }
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
          { sentiment: { _eq: $sentiment_id } }
        ]
      }
      order_by: $order_by
    ) {
      bot_id
      cnt
      dialog_id
      is_published
      negative
      platform_id
      positive
      project_id
      sentiment
      token
      bot {
        landing_link {
          landing {
            data(path: "title")
          }
        }
      }
      dialog {
        cr
      }
      project {
        id
        name
      }
    }
  }
`;*/

export {
  PROJECT_IDEAS_QUERY,
  PROJECT_QUESTIONNAIRE_QUERY,
  PROJECT_IDEAS_QUERY_ALL,
  UPDATE_IDEAS_QUERY,
  DELETE_IDEAS_QUERY,
};
