const _year = new Date(new Date().setFullYear(new Date().getFullYear() - 1)); // year
  const _month = new Date(new Date().setMonth(new Date().getMonth() - 1)); //month
  const _week = new Date(new Date().setDate(new Date().getDate() - 7)); //week


  const _thisDayStart = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0,
    0
  );
  const _thisDayEnd = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    23,
    59,
    59
  );

  export {
    _year,
    _month,
    _week,
    _thisDayStart,
    _thisDayEnd
  }