import React, { useEffect, useState, useMemo } from "react";
import { Menu } from "antd";

import styled from "styled-components";

/* Redux */
import { connect } from "react-redux";
import { makeQuery } from "./helpers/make-query";

import { ANSWERS_DIALOG_VOC_HUB as queryQuestions } from "../../../../queries/index";

import NoRender from "../../../../helpers/noRender";

import AnswersVoc from "./components/answers-voc-list";
//import BotsLine from "./components/bots-line";

import BotsList from "../Nuclear/bots/bots-list";

import { SectionWrapper } from "./components/wrappers";
import QuestionVoc from "./components/question-voc_list";

const ContentWrapper = styled.div`
  min-width: 600px;
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
`;

const Metrics = ({ currentProjectId }) => {
  //const currentProjectId = "eb45070e-3b44-81d6-15b0-14ccfdeefac9"; //убрать потом
  //const currentProjectId = "813307cc-9913-d316-b7f1-3f672802030b";

  if (currentProjectId) {
    // Стэйт для того, чтобы установить ответы какого бота отображать
    /* "6cd0fa47-3320-badb-4400-46554f1fc708" */
    const [botParent, setBotParent] = useState(null);
    const [botLocation, setBotLocation] = useState({ lat: 0, lng: 0, zoom: 0 });
    const [nodes, setNodes] = useState(null);

    const [metricSection, setMetricSection] = useState("common");

    //лэйзи запросы к списку ботов и вопросов из анкет
    const [
      getQuestions,
      { data: questions, loading: l_questions, error: err_questions },
    ] = makeQuery(queryQuestions);

    /** Получить список со всеми вопросами */
    useEffect(() => {
      if (botParent) {
        getQuestions({
          variables: {
            project_id: currentProjectId,
            bot_id: botParent,
          },
        });
      }
    }, [currentProjectId, botParent]);

    useEffect(() => {
      if (questions) {
        const nodes = questions?.vocs?.[0]?.nodes;

        setNodes(nodes);
      }
    }, [questions]);

    /** */

    const VocSettings = useMemo(
      () => ({
        data: nodes,
        loading: l_questions,
        error: err_questions,
        currentProjectId,
        bot_id: botParent,
        bot_location: botLocation,
      }),
      [
        nodes,
        l_questions,
        err_questions,
        currentProjectId,
        botParent,
        botLocation,
      ]
    );

    return (
      <>
        <BotsList
          needsLocation={true}
          {...{
            botParent,
            setBotParent,
            currentProjectId,
            botLocation,
            setBotLocation,
          }}
        />

        <SectionWrapper>
          <Menu mode="horizontal" selectedKeys={[metricSection]}>
            <Menu.Item
              key={"common"}
              onClick={() => setMetricSection("common")}
            >
              Общее
            </Menu.Item>
            <Menu.Item
              key={"questions"}
              onClick={() => setMetricSection("questions")}
            >
              По вопросам
            </Menu.Item>
          </Menu>

          <ContentWrapper>
            {metricSection === "common" ? (
              <AnswersVoc {...VocSettings} />
            ) : (
              <QuestionVoc {...VocSettings} />
            )}
          </ContentWrapper>
        </SectionWrapper>
      </>
    );
  } else {
    return <NoRender />;
  }
};

export default connect(({ app }) => ({
  tools: app.tools,
  projects: app.projects,
  currentProject: app.currentProject,
  currentProjectId: app.currentProjectId,
}))(Metrics);
