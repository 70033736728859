/**
 * Created by root on 04.08.2019.
 */

import jwt from "jsonwebtoken";
import { appConfig } from "../env";

//const sessionStorageInstance = sessionStorage || {};
const localStorageInstance = localStorage || {};
const tokenKey = "cwd-access-token";
const lastLoginKey = "cwd-last-login";
const refreshTokenKey = "cwd-refresh-token";

export default class Tokenizer {
  static authHeader(token) {
    return token ? `Bearer ${token}` : "";
  }

  static decode(token) {
    return jwt.decode(token);
  }

  static isExpired(token) {
    const decoded = Tokenizer.decode(token);
    const currentTs = new Date().getTime() / 1000;

    return decoded["exp"] - currentTs <= 0;
  }

  static get(variable) {
    return localStorageInstance[variable];
  }

  static verify(token) {
    try {
      return jwt.verify(token, appConfig.auth.secret);
    } catch (e) {
      return false;
    }
  }

  static lastLogin(defaultValue = null) {
    return lastLoginKey in localStorageInstance
      ? localStorageInstance[lastLoginKey]
      : defaultValue;
  }

  static store(token, refresh_token = null, login = null) {

    localStorageInstance['something'] = 'key'

    localStorageInstance[tokenKey] = token;
    if (login) {
      localStorageInstance[lastLoginKey] = login;
    } else {
      localStorageInstance.removeItem(lastLoginKey);
    }
    if (refresh_token) {
      localStorageInstance[refreshTokenKey] = refresh_token;
    }
  }

  static isStored() {
    return (
      localStorageInstance[tokenKey] !== undefined &&
      localStorageInstance[tokenKey] != null
    );
  }

  static load() {

    if (Tokenizer.isStored()) {
      return localStorageInstance[tokenKey];
    }

    return undefined;
  }

  static clear() {
    localStorageInstance[tokenKey] = null;
  }
}
