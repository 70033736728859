import { gql } from "@apollo/client";

const METRICS_ACTIVITY_LIKES_TIMERANGE = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    idea_rating_aggregate(
      where: {
        _and: [
          {
            idea: {
              chat: {
                bot: {
                  landing_link: {
                    landing: { project_id: { _eq: $project_id } }
                  }
                }
              }
            }
          }
          { idea: { cr: { _gte: $start_date } } }
          { idea: { cr: { _lte: $end_date } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const METRICS_ACTIVITY_COMMENTS_TIMERANGE = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    chat_dialog_aggregate(
      where: {
        _and: [
          { parent_id: { _is_null: false } }
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const METRICS_ACTIVITY_IDEAS_TIMERANGE = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
    $bot_id: uuid
  ) {
    chat_dialog_aggregate(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { chat: { bot_id: { _eq: $bot_id } } }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
          { idea: { id: { _is_null: false } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const METRICS_ACTIVITY_IDEASLIST = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    chat_dialogs(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
          { idea: { id: { _is_null: false } } }
        ]
      }
      order_by: { cr: asc }
    ) {
      cr
      idea {
        category
      }
    }
  }
`;

/* Сделать анкету */
/* Врея */
/* Популярный сообщения Заголовок /  Популярные темы */

/**
 *
 * Пользователи на проекте
 *
 */

const METRICS_ACTIVITY_USERS = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    project_hub(where: { _and: [{ id: { _eq: $project_id } }] }) {
      project_clients_aggregate(
        where: {
          _and: [{ cr: { _gte: $start_date } }, { cr: { _lte: $end_date } }]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

/**
 *
 * Заполненные анкеты
 *
 */

const METRICS_ACTIVITY_QUESTIONNAIRE = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    chat_aggregate(
      where: {
        _and: [
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 *
 * Список идей
 */

const METRICS_ACTIVITY_IDEASLIST1 = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
    $limit: Int
  ) {
    ideas(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
        ]
      }
      limit: $limit
      order_by: { cr: desc_nulls_last }
    ) {
      id
      cr
      category
      message
      rating
    }
  }
`;

const METRICS_ACTIVITY_IDEASLIST2 = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
    $limit: Int
  ) {
    chat_dialogs(
      where: {
        _and: [
          { parent_id: { _is_null: true } }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
          { idea: { id: { _is_null: false } } }
        ]
      }
      limit: $limit
      order_by: { cr: desc_nulls_last }
    ) {
      id
      cr
      node
      message
      idea {
        id
        message
        is_published
      }
    }
  }
`;

const METRICS_SENREP = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    metric_met_sen_rep(
      where: {
        _and: [
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { positive: { _eq: 0 } }
          { negative: { _eq: 0 } }
        ]
      }
      order_by: { dialog: { cr: asc } }
    ) {
      bot_id
      cnt
      dialog_id
      is_published
      negative
      platform_id
      positive
      project_id
      sentiment
      token
      dialog {
        cr
      }
    }
  }
`;

const METRICS_SENREPPOSNEG = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
    $positive: Int
    $negative: Int
  ) {
    metric_met_sen_rep(
      where: {
        _and: [
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { positive: { _eq: $positive } }
          { negative: { _eq: $negative } }
        ]
      }
      order_by: { dialog: { cr: asc } }
    ) {
      bot_id
      cnt
      dialog_id
      is_published
      negative
      platform_id
      positive
      project_id
      sentiment
      token
      dialog {
        cr
        idea {
          id
        }
      }
    }
  }
`;

/**
 *
 * Сортировать пользователей по ботам.
 * Страница "Общая сводка"
 *
 */

const USERS_SORTBY_BOTS = gql`
  query MyQuery($project_id: uuid) {
    dialog_bot(
      where: { landing_link: { landing: { project_id: { _eq: $project_id } } } }
    ) {
      id
      chats_aggregate {
        aggregate {
          count
        }
      }
      landing_link {
        landing {
          data(path: "title")
        }
      }
    }
  }
`;

/**
 *
 * Сортировать идеи по ботам
 */

const IDEAS_SORTBY_BOTS = gql`
  query MyQuery($project_id: uuid, $bot_id: uuid) {
    dialog_bot(
      where: {
        _and: [
          { landing_link: { landing: { project_id: { _eq: $project_id } } } }
          { id: { _eq: $bot_id } }
        ]
      }
    ) {
      id
      chats {
        dialogs_aggregate(
          where: {
            parent_id: { _is_null: true }
            idea: { id: { _is_null: false } }
          }
        ) {
          aggregate {
            count
          }
        }
      }
      landing_link {
        landing {
          data(path: "title")
        }
      }
    }
  }
`;

export {
  METRICS_ACTIVITY_LIKES_TIMERANGE,
  METRICS_ACTIVITY_COMMENTS_TIMERANGE,
  METRICS_ACTIVITY_IDEAS_TIMERANGE,
  METRICS_ACTIVITY_IDEASLIST,
  METRICS_ACTIVITY_IDEASLIST1,
  METRICS_ACTIVITY_IDEASLIST2,
  METRICS_SENREP,
  METRICS_SENREPPOSNEG,
  METRICS_ACTIVITY_QUESTIONNAIRE,
  METRICS_ACTIVITY_USERS,
  //
  USERS_SORTBY_BOTS,
  //
  IDEAS_SORTBY_BOTS,
};
