import React, {  } from "react";
import { Skeleton, Typography, Col, Row, List } from "antd";
import styled from "styled-components";

const { Paragraph } = Typography;

/**
 *
 * Тип ответов - plain
 *
 */

const RowDialog = styled(Row)`
  background: #f5f6f8;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;

  &&& .ant-typography-expand {
    color: blue;
  }

  &&& .ant-typography {
    width: 100%
  }
`;

const PlainBlock = ({ answers, loading, limitPerPage }) => {
  return (
    <Row>
      <Col span={24}>
        <Skeleton active loading={loading}>
          {answers && (
            <List
              itemLayout="vertical"
              dataSource={answers}
              pagination={{
                defaultPageSize: limitPerPage,
              }}
              renderItem={({ message, id }) => {
                const { text } = message;
                return (
                  <RowDialog key={`answer${id}`}>
                    {text ? (
                      <Paragraph ellipsis={{ rows: 4, expandable: true }}>
                        {text}
                      </Paragraph>
                    ) : (
                      <i>(Текст отсутствует)</i>
                    )}
                  </RowDialog>
                );
              }}
            />
          )}
        </Skeleton>
      </Col>
    </Row>
  );
};

export default PlainBlock;
