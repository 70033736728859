import React from "react";

const DateInfo = ({ label, dateStart, dateEnd, hideDateRange }) => {
  const monthA =
    "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря".split(
      ","
    );

  const _label = label ? label : "Данные";

  if (!hideDateRange) {
    const _dateStart = dateStart
      ? String(`${dateStart.getDate()} ${monthA[dateStart.getMonth()]}`)
      : "-";
    const _dateEnd = dateEnd
      ? String(`${dateEnd.getDate()} ${monthA[dateEnd.getMonth()]}`)
      : "-";

    return <span>{`${_label} за период с ${_dateStart} по ${_dateEnd}`}</span>;
  } else {
    return (<span>{_label} за весь период</span>);
  }
};

export default DateInfo;
