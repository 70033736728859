import { gql } from "@apollo/client";
const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject(
    $id: uuid
    $key: String
    $name: String!
    $about: String
  ) {
    # создаем проект
    createProject(object: { id: $id, key: $key }) {
      id
    }

    # создаем мета информацию проекта
    createProjectMetas(
      objects: { project_id: $id, name: $name, about: $about }
    ) {
      affected_rows
    }

    # привязываем проект к пользователю
    createProjectClientLink(object: { project_id: $id }) {
      client_id
    }
  }
`;

// вспомогательная мутация при создании проекта для того, чтобы подвязать проект к пользователю
const CREATE_USER_LINK_MUTATION = gql`
  mutation CreateUserClientLink($cr: timestamptz) {
    createClientUserLink(object: { cr: $cr }) {
      client_id
    }
  }
`;

export { CREATE_PROJECT_MUTATION, CREATE_USER_LINK_MUTATION };
