import { Card } from "antd";
import styled from "styled-components";

const AnswersVocWrapper = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 36px;
  padding-bottom: 36px;
`;

const StyleP = styled.p`
  font-size: 14px;
  color: black;
  margin-bottom: 4px;
  font-weight: 500;
`;

const AnswerVocCard = styled(Card)`
  border-radius: 8px;
  padding-top: 12px;
  margin-bottom: 56px;
  padding-top: 0px;

  & .ant-card-head-title {
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: black;
  }

  & .ant-card-body {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  & .ant-card-head {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const CommonCount = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

export {
    AnswersVocWrapper,
    StyleP,
    AnswerVocCard,
    CommonCount
}