/**
 * External Dependencies
 */
import React from "react";
import Loadable from "react-loadable";
import { Spinner } from "reactstrap";

const AsyncComponent = ({ component }) => {
  const LoadableComponent = Loadable({
    loader: component,
    loading: () => (
      <Spinner color="secondary" type="grow">
        Loading...
      </Spinner>
    )
  });

  return <LoadableComponent />;
};

export default AsyncComponent;
