import { sortData } from "../components/filter-dropdown";

/* все настройки для отображения графиков в секции Общее */

//допнастройки для barChart
const showBarConfigs = ({ sort = "default", answers = null, items = null }) => {
  if (answers && items) {
    //общее кол-во
    const totalCount = answers.reduce((a, { value = 0 }) => a + value, 0);

    return {
      data: sortData[sort]?.sort(answers),
      xField: "value",
      yField: "option",
      height: 30 * items.length,
      barWidthRatio: 0.6,
      color: "#5C5EF9",
      label: {
        position: "left",
        offset: 24,
        content: ({ value = 0 }) =>
          `${((value / totalCount) * 100).toFixed(0)}%`,
      },
      barStyle: {
        radius: [2, 2, 0, 0],
      },
    };
  }

  return null;
};

//допнастройки для pieChart
const showPieConfigs = ({ answers = null }) => {
  if (answers) {
    return {
      data: answers,
      appendPadding: 10,
      height: 300,
      angleField: "value",
      colorField: "option",
      radius: 0.9,
      label: {
        type: "inner",
        offset: "-30%",
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: "center",
        },
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };
  }

  return null;
};

export { showBarConfigs, showPieConfigs };
