/**
 * External Dependencies
 */
import React from "react";

import { connect } from "react-redux";
import { loadCurrentProject } from "../../../../actions/app";

import NoRender from "../../../../helpers/noRender";

/**
 * Internal Dependencies
 */
import AsyncComponent from "../../../../components/async-component";
import PageWrap from "../../../../components/page-wrap";
import PageContent from "../../../../components/page-content";

import styled from "styled-components";

const StyledPageWrap = styled(PageWrap)`
  background: #f5f5f5 !important;
`;

/**
 * Component
 */
const Analytics = ({ currentProjectId }) => {
  if (currentProjectId) {
    return (
      <StyledPageWrap>
        <PageContent>
          <AsyncComponent component={() => import("./content")} />
        </PageContent>
      </StyledPageWrap>
    );
  } else {
    return <NoRender />;
  }
};

export default connect(
  ({ app }) => ({
    tools: app.tools,
    projects: app.projects,
    currentProject: app.currentProject,
    currentProjectId: app.currentProjectId,
  }),
  {
    loadCurrentProject,
  }
)(Analytics);
