import React from "react";
import PageWrap from "../../../../components/page-wrap";
import PageContent from "../../../../components/page-content";
import AsyncComponent from "../../../../components/async-component";

const SubscriptionPage = () => {
  return (
    <PageWrap>
      <PageContent>
        <AsyncComponent component={() => import("./content")} />
      </PageContent>
    </PageWrap>
  );
};

export default SubscriptionPage;
