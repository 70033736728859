import React, { lazy, Suspense } from "react";

import PageLoader from "../../../components/page-loader";

const Forgot = lazy(() => import("./content"));

const ForgotPage = () => (
  <Suspense fallback={<PageLoader full />}>
    <Forgot />
  </Suspense>
);

export default ForgotPage;
