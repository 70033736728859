import React, { lazy, Suspense } from "react";

import PageLoader from "../../../components/page-loader";

const SignIn = lazy(() => import("./content"));

const SignInPage = () => (
  <Suspense fallback={<PageLoader full />}>
    <SignIn />
  </Suspense>
);

export default SignInPage;
