////////////////////////////////
///// Libraries
import React, { useEffect } from "react";
import styled from "styled-components";
import { Space, Typography, Empty } from "antd";
import { Link } from "react-router-dom";

import stc from "string-to-color";

/////////////////////////////
/// Queries
import { PROJECT_IDEAS_QUERY } from "../../../../../queries/ideas";
import { useLazyQuery } from "@apollo/client";

import { SectionCard } from "../../../../../helpers/section-card";

import { ContextHeader } from "../../../../../env";

////////////////////////////////////////////////
//// Helpers
import { CardBody } from "../../../../../helpers/body-card";

const { Paragraph } = Typography;

///////////////////////////
// Styled Components
const VisitContent = styled.div`
  width: 100%;
  //display: grid;
  //grid-template-columns: 1fr auto auto;
  //column-gap: 50px;
  display: flex;
  justify-content: stretch;
  gap: 50px;
`;

const VisitContentLine = styled(Space)`
  width: 100%;
  overflow-x: scroll;
  align-items: stretch;
  padding-top: 6px;
  padding-bottom: 10px;
`;

const StyledSpace = styled(Space)`
  width: 100%;
`;

////////////////////////////////////
//// Message Card Component

const MessageSecBlock = ({ message, cid, cr, bottitle }) => {
  const date = new Date(cr);
  const FDate = date.toISOString().split("T")[0];

  return (
    <Link to={cid ? `/project/ideas/${cid}` : "/"}>
      <SectionCard
        data-type="clickable"
        alignItems="baseline"
        justifyContent="flex-start"
        minwidth={"290"}
        minheight={"180"}
      >
        <StyledSpace direction="vertical" size={16}>
          <StyledSpace direction="vertical" size={5}>
            <SectionCard.Flex justifyContent="space-between">
              <SectionCard.Label color={stc(bottitle)}>
                {bottitle ? bottitle : <i>Без названия</i>}
              </SectionCard.Label>
              <SectionCard.Label>{FDate && FDate}</SectionCard.Label>
            </SectionCard.Flex>
            <SectionCard.Hr />

            <SectionCard.Flex justifyContent="space-between">
              <SectionCard.Label>
                Опишите ваше предложение подробно
              </SectionCard.Label>
            </SectionCard.Flex>
          </StyledSpace>

          <Paragraph ellipsis={{ rows: 4 }}>{message}</Paragraph>
        </StyledSpace>
      </SectionCard>
    </Link>
  );
};

/////////////////////////////////////
/// Card Component

const MessagesCard = ({ currentProjectId, botParent }) => {
  ///////////////////////////////
  //// Lazy Query
  const [getMessagesList, { data: MessagesList }] = useLazyQuery(
    PROJECT_IDEAS_QUERY,
    {
      context: {
        headers: {
          "x-hasura-admin-secret": ContextHeader,
        },
      },
    }
  );

  ///////////////////////////////
  ///// Use Effects
  useEffect(() => {
    getMessagesList({
      variables: {
        project_id: currentProjectId,
        start_date: null,
        end_date: null,
        limit: 15,
        order_by: { cr: "desc_nulls_last" },
        bot_id: { bot: { id: { _eq: botParent } } },
        parent_id: true,
        message_is_null: { _is_null: false },
        idea_id: { id: { _is_null: false } },
      },
    });
  }, [currentProjectId, botParent]);

  ///////////////////////////////////
  /// Get Categories

  //////////////////////////////
  /// Render

  return (
    <CardBody className="card">
      <div className="card-body">
        {/*<DateInfo label={"Данные"} hideDateRange />*/}
        <VisitContent>
          <VisitContentLine style={{ width: "100%", overflowX: "scroll" }}>
            {MessagesList && MessagesList?.chat_dialogs?.length > 0 ? (
              MessagesList.chat_dialogs?.map(({ idea, cr, node, chat }, i) => (
                <MessageSecBlock
                  key={`message${i}`}
                  message={idea?.message}
                  cid={idea?.id}
                  bottitle={chat?.bot?.landing_link?.landing?.data}
                  cr={cr}
                  node={node}
                />
              ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </VisitContentLine>
        </VisitContent>
      </div>
    </CardBody>
  );
};

export default MessagesCard;
