import React from "react";

import { Card, CardBody, CardText, CardTitle, Button } from "reactstrap";
import { Link } from "react-router-dom";

import PageWrap from "../components/page-wrap";
//import PageContent from "../components/page-content";

const NoRender = () => {
  return (
    <PageWrap>
        <div className="rui-no-project">
          <div className="rui-no-project-block">
            <Card className="text-center">
              <CardBody>
                <CardTitle className="h2">
                  У вас еще нет ни одного проекта
                </CardTitle>
                <CardText>
                  Для продолжения работы в системе вам необходимо создать ваш
                  первый проект.
                </CardText>
                <CardText>
                  Чтобы его создать, необходимо заполнить небольшую анкету с
                  информацией о проекте.
                </CardText>
                <Link to="/project/create">
                  <Button color="brand">Cоздать первый проект</Button>
                </Link>
              </CardBody>
            </Card>
          </div>
        </div>
    </PageWrap>
  );
};

export default NoRender;
