/**
 * Styles
 */
// import './style.scss';

/**
 * External Dependencies
 */
import React from "react";
import DeckGL from "@deck.gl/react";
import { MapController } from "@deck.gl/core";
import ReactMapGL, {
  _MapContext as MapContext,
  NavigationControl,
} from "react-map-gl";
import styled from "styled-components";

// const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1Ijoic3RhaW53b29ydHNlbCIsImEiOiJjazlqNjIwdmIwMzRxM2VtaHIxZHFwZXJkIn0.u1vdFsRvuWW-gAmOsflYpQ";

export const NavigationContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 50;
  padding: 1rem;
`;

export const mapStyle = "mapbox://styles/mapbox/light-v9"; // "mapbox://styles/mapbox/satellite-streets-v11";

const BaseMap = ({ children, controller, ...props }) => (
  <DeckGL
    ContextProvider={MapContext.Provider}
    controller={
      controller || {
        type: MapController,
        scrollZoom: false,
        doubleClickZoom: false,
      }
    }
    {...props}
  >
    <NavigationContainer>
      <NavigationControl showCompass={true} />
    </NavigationContainer>
    <ReactMapGL
      {...{ mapStyle }}
      mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
      attributionControl={true}
      preventStyleDiffing
    />
    {children}
  </DeckGL>
);

export default BaseMap;
