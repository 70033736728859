import styled from "styled-components";

import { Space } from "antd";

const VisitContent = styled.div`
  position: relative;
  width: 100%;
  //display: grid;
  //grid-template-columns: 1fr auto auto;
  //column-gap: 50px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
`;

const VisitContentLine = styled(Space)`
  width: 100%;
  overflow-x: scroll;
  align-items: stretch;
  padding-top: 6px;
  padding-bottom: 10px;
  position: relative && > * + * {
    margin-left: 8px;
  }
`;

const VisitContentLineWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  align-items: stretch;
  position: relative;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &&::-webkit-scrollbar {
    display: none;
  }
`;

const StyledSpace = styled(Space)`
  width: 100%;
`;

export { StyledSpace, VisitContent, VisitContentLine, VisitContentLineWrapper };
