/**
 * External Dependencies
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/**
 * Internal Dependencies
 */
import FirstProject from "../CreateProject";
import PageWrap from "../../../../components/page-wrap";
//import PageTitle from "../../../../components/page-title";
import PageContent from "../../../../components/page-content";
import AsyncComponent from "../../../../components/async-component";
import { loadTools } from "../../../../actions/app";

/**
 * Component
 */

const ToolsPage = () => {
  const { auth, app } = useSelector(state => state);
  const dispatch = useDispatch();
  const { is_novice, is_preparing } = auth;
  const { currentProject } = app ?? {};
  const { project_id } = currentProject ?? {};

  useEffect(() => {
    project_id && dispatch(loadTools(project_id));
  }, [dispatch, project_id]);

  return is_novice || is_preparing ? (
    <FirstProject />
  ) : (
    <PageWrap>
      <PageContent>
        <AsyncComponent component={() => import("./content")} />
      </PageContent>
    </PageWrap>
  );
};

export default ToolsPage;
