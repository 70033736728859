import { Input, DatePicker } from "antd";
import React from "react";
import styled from "styled-components";
import { globals } from "./globals";

const { RangePicker } = DatePicker;

const { desktop } = globals;

const { Search } = Input;

const LabelCol = styled.div`
  display: inline-block;
  min-width: ${({ type }) => (type === "space" ? "initial" : "90px")};
  color: var(--text-gray-color);
  font-size: 14px;
`;

const ColorIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ color }) => (color ? color : "grey")};
`;

const InfoIcon = styled.div`
  width: 16px;
  height: 16px;
  background: url("/icons/info.svg");
  background-size: cover;
  cursor: pointer;
`;

const ProfileIcon = styled.div`
  width: 16px;
  height: 16px;
  background: url("/icons/profile.svg");
`;

const CalendarIcon = styled.div`
  width: 16px;
  height: 16px;
  background: url("/icons/calendar.svg");
`;

const TimeIcon = styled.div`
  width: 16px;
  height: 16px;
  background: url("/icons/time.svg");
`;

const Tally = ({ children, icon, color, ...props }) => {
  let _icon;
  if (color) _icon = <ColorIcon color={color} />;
  if (icon) _icon = icon;

  return (
    <TallyBlock {...{ ...props }}>
      {_icon}
      <span>{children}</span>
    </TallyBlock>
  );
};

const TallyBlock = styled.div`
  &&& {
    display: flex;
    align-items: center;
    font-size: @content-font;
    color: #979797;
    font-weight: 400;
  }

  &&& * {
    font-size: ${desktop.level2_size};
    font-size: ${desktop.level2_height};
  }

  && > * + * {
    margin-left: 8px;
  }
`;

const StyledH2 = styled.h2`
  &&&&& {
    font-weight: 700;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: ${({ bottom }) =>
      bottom || bottom === 0 ? `${bottom}px` : "30px"};
  }

  &&&&&[data-level="1"] {
    font-size: 20px;
  }

  &&&&&&[data-level="2"] {
    font-weight: 500;
    padding-bottom: 8px;
    margin-bottom: 0;
  }
`;

const StyledCard = styled.div`
  && {
    border-radius: 8px;
    border: 0px;
    box-shadow: 3px 3px 5px 1px rgba(12, 27, 58, 0.09);
    background: ${({ background }) => (background ? background : "white")};
  }

  &&& .card-body {
    padding: 15px 15px;
  }

  &&&& .ant-tag-checkable-checked[data-color="yellow"] {
    background: none;
    padding: 0px 7px;
    color: #5C5EF9 !important;
  }

  &&&& .ant-tag-checkable-checked {
    background: #e1e1e1;
    padding: 0px 7px;
    color: rgba(88, 88, 88, 1);
  }

  &&& .ant-tag-checkable {
    color: var(--text-gray-color);
  }

  && .ant-tag {
    border-radius: 8px;
    border: 0px;
    font-size: 14px;
    font-weight: 500;
  }

  && .ant-tag[data-color="yellow"]:hover {
    background: none;
  }

  && .ant-tag:hover {
    background: #f6f6f6;
  }

  && .ant-tag[color="success"],
  && .ant-tag-success {
    background: #f3f9ec;
    color: #79a342;
    border: 1px solid rgba(0, 0, 0, 0);
  }

  &&&&& .ant-tag-checkable-checked[color="success"] {
    background: #f3f9ec;
    color: #79a342;
    border: 1px solid #79a342;
  }

  && .ant-tag[color="success"]:hover,
  && .ant-tag-success:hover {
    background: #e8f3d9;
  }

  && .ant-tag[color="error"]:hover,
  && .ant-tag-error:hover {
    background: #fae7e3;
  }

  && .ant-tag[color="error"],
  && .ant-tag-error {
    background: #fcf3f1;
    color: #d1644e;
    border: 1px solid rgba(0, 0, 0, 0);
  }

  &&&&& .ant-tag-checkable-checked[color="error"] {
    background: #fcf3f1;
    color: #d1644e;
    border: 1px solid #d1644e;
  }

  && .ant-tag[color="default"],
  && .ant-tag-default {
    background: #f8f8f8;
    color: #585858;
    border: 1px solid rgba(0, 0, 0, 0);
  }

  &&&&& .ant-tag-checkable-checked[color="default"] {
    background: #f8f8f8;
    color: #585858;
    border: 1px solid #585858;
  }
`;

const StyledRangePicker = styled(RangePicker)`
  && {
    border-radius: 8px;
    margin-right: 8px;
  }

  &&& input {
    font-size: 14px;
  }

  &&& input::placeholder {
    font-size: 14px;
  }

  .ant-picker-cell .ant-picker-cell-inner {
    background: #fde1ac !important;
  }
`;

const StyledSearch = styled(Search)`
  &&&& .ant-input-affix-wrapper {
    border-radius: 50px 0 0 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-right: 0px;
  }

  &&&& button {
    background: #ffc634;
    border-radius: 0 50px 50px 0;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &&&&& button.ant-btn[disabled] {
    background: #e7e7e7;
  }
`;

export {
  LabelCol,
  StyledH2,
  StyledCard,
  StyledRangePicker,
  StyledSearch,
  InfoIcon,
  Tally,
  ProfileIcon,
  CalendarIcon,
  TimeIcon,
};
