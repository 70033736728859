////////////////////////////////
///// Libraries
import React, { useEffect } from "react";
import styled from "styled-components";
import {
  SectionCard,
  SectionCardWrapper,
} from "../../../../../helpers/section-card";

import { useSelector, useDispatch } from "react-redux";

import { loadTools } from "../../../../../actions/app";
import stc from "string-to-color";

import {
  METRICS_ACTIVITY_IDEAS_TIMERANGE,
  METRICS_ACTIVITY_QUESTIONNAIRE,
  USERS_SORTBY_BOTS,
  IDEAS_SORTBY_BOTS,
} from "../../../../../queries/metrics-queries";

import { ACTIVITY_USERS_METRICS } from "../../../../../queries/index";

import { _thisDayEnd, _thisDayStart } from "../../../../../helpers/time-pack";

/////////////////////////////
/// Queries
import { useLazyQuery } from "@apollo/client";
import { ContextHeader } from "../../../../../env";

////////////////////////////////////////////////
//// Helpers
import { CardBody } from "../../../../../helpers/body-card";

const GridColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  margin-bottom: 16px;
  height: 100%;
`;

const SectionTitle = styled.h3`
  && {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
  }
`;

const ColumnCard = ({ day, total, sectionName }) => {
  return (
    <SectionCardWrapper data-type={'stretch'}>
      <SectionTitle>{sectionName}</SectionTitle>
      <SectionCard>
        <SectionCard.Flex>
          <SectionCard.Label>новых:</SectionCard.Label>
        </SectionCard.Flex>
        <SectionCard.Flex justifyContent="center">
          <SectionCard.Number>{day}</SectionCard.Number>
        </SectionCard.Flex>
        <SectionCard.Hr marginBottom={19} />
        <SectionCard.Flex>
          <SectionCard.Label>всего:</SectionCard.Label>
        </SectionCard.Flex>

        <SectionCard.Flex justifyContent="center">
          <SectionCard.Number>{total}</SectionCard.Number>
        </SectionCard.Flex>
      </SectionCard>
    </SectionCardWrapper>
  );
};

/////////////////////////////////////
/// Card Component

const ActivityMetricsMiniCard = ({ currentProjectId, botParent }) => {
  const { currentProject } = useSelector(({ app }) => app);

  const dispatch = useDispatch();
  const { project_id } = currentProject ?? {};

  useEffect(() => {
    project_id && dispatch(loadTools(project_id));
  }, [dispatch, project_id]);
  /**
   *
   * Пользователи
   */

  const [getActivityUsersTotal, { data: ActivityUsersTotal }] = useLazyQuery(
    ACTIVITY_USERS_METRICS,
    {
      context: {
        headers: {
          "x-hasura-admin-secret": ContextHeader,
        },
      },
    }
  );

  const [getActivityUsersTimerange, { data: ActivityUsersTimerange }] =
    useLazyQuery(ACTIVITY_USERS_METRICS, {
      context: {
        headers: {
          "x-hasura-admin-secret": ContextHeader,
        },
      },
    });

  const [getUsersSortbyBots, { data: UsersSortbyBots }] = useLazyQuery(
    USERS_SORTBY_BOTS,
    {
      context: {
        headers: {
          "x-hasura-admin-secret": ContextHeader,
        },
      },
    }
  );

  /**
   * Анкеты
   */

  const [getActivityQuestionnaireTotal] = useLazyQuery(
    METRICS_ACTIVITY_QUESTIONNAIRE,
    {
      context: {
        headers: {
          "x-hasura-admin-secret": ContextHeader,
        },
      },
    }
  );

  const [getActivityQuestionnaireTimerange] = useLazyQuery(
    METRICS_ACTIVITY_QUESTIONNAIRE,
    {
      context: {
        headers: {
          "x-hasura-admin-secret": ContextHeader,
        },
      },
    }
  );

  /**
   * Идеи
   */

  const [getActivityIdeasTotal, { data: ActivityIdeasTotal }] = useLazyQuery(
    METRICS_ACTIVITY_IDEAS_TIMERANGE,
    {
      context: {
        headers: {
          "x-hasura-admin-secret": ContextHeader,
        },
      },
    }
  );

  const [getActivityIdeasTimerange, { data: ActivityIdeasTimerange }] =
    useLazyQuery(METRICS_ACTIVITY_IDEAS_TIMERANGE, {
      context: {
        headers: {
          "x-hasura-admin-secret": ContextHeader,
        },
      },
    });

  const [getIdeasSortbyBots, { data: IdeasSortbyBots }] = useLazyQuery(
    IDEAS_SORTBY_BOTS,
    {
      context: {
        headers: {
          "x-hasura-admin-secret": ContextHeader,
        },
      },
    }
  );

  useEffect(() => {
    /**
     * Users
     */

    getActivityUsersTotal({
      variables: {
        start_date: null,
        end_date: null,
        project_id: currentProjectId,
      },
    });

    getActivityUsersTimerange({
      variables: {
        start_date: _thisDayStart,
        end_date: _thisDayEnd,
        project_id: currentProjectId,
        bot_id: botParent
      },
    });

    getUsersSortbyBots({
      variables: {
        project_id: currentProjectId,
        bot_id: botParent
      },
    });

    /**
     * Questionnaire
     */
    getActivityQuestionnaireTotal({
      variables: {
        start_date: null,
        end_date: null,
        project_id: currentProjectId,
      },
    });

    getActivityQuestionnaireTimerange({
      variables: {
        start_date: _thisDayStart,
        end_date: _thisDayEnd,
        project_id: currentProjectId,
      },
    });

    //Ideas
    getActivityIdeasTotal({
      variables: {
        start_date: null,
        end_date: null,
        project_id: currentProjectId,
        bot_id: botParent
      },
    });
    getActivityIdeasTimerange({
      variables: {
        start_date: _thisDayStart,
        end_date: _thisDayEnd,
        project_id: currentProjectId,
        bot_id: botParent
      },
    });

    getIdeasSortbyBots({
      variables: {
        project_id: currentProjectId,
      },
    });
  }, [currentProjectId, botParent]);

  /**
   *
   * Диаграмма: сортировка пользователей по ботам
   */

  let diagramUsers = [];

  if (UsersSortbyBots)
    diagramUsers = UsersSortbyBots?.dialog_bot?.map((key) => {
      const count = key?.chats_aggregate?.aggregate?.count;
      const localTitle = key?.landing_link?.landing?.data;

      return {
        angle: count,
        color: stc(localTitle),
      };
    });

  /*let diagramBots = [];

  if (UsersSortbyBots)
    diagramBots = UsersSortbyBots?.dialog_bot?.map((key) => {
      const localTitle = key?.landing_link?.landing?.data;

      return {
        angle: 1,
        color: stc(localTitle),
      };
    });*/

  let diagramIdeas = [];
  if (IdeasSortbyBots)
    diagramIdeas = IdeasSortbyBots?.dialog_bot?.map((key) => {
      const localTitle = key?.landing_link?.landing?.data;

      let count = 0;
      key?.chats?.map((item) => {
        count += item?.dialogs_aggregate?.aggregate?.count;
      });

      return {
        angle: count,
        color: stc(localTitle),
      };
    });

  //////////////////////////////
  /// Render

  return (
    <CardBody className="card">
      <div className="card-body">
        {/*<DateInfo label={"Данные"} hideDateRange />*/}
        <GridColumns>
          <ColumnCard
            day={
              ActivityUsersTimerange
                ? ActivityUsersTimerange?.chat_aggregate?.aggregate.count
                : "-"
            }
            total={
              ActivityUsersTotal
                ? ActivityUsersTotal?.chat_aggregate?.aggregate.count
                : "-"
            }
            diagram={diagramUsers}
            sectionName={"Пользователи"}
          />
          <ColumnCard
            day={
              ActivityIdeasTimerange
                ? ActivityIdeasTimerange?.chat_dialog_aggregate?.aggregate.count
                : "-"
            }
            total={
              ActivityIdeasTotal
                ? ActivityIdeasTotal?.chat_dialog_aggregate?.aggregate.count
                : "-"
            }
            diagram={diagramIdeas}
            sectionName={"Анкеты"}
          />
          <ColumnCard
            day={
              ActivityIdeasTimerange
                ? ActivityIdeasTimerange?.chat_dialog_aggregate?.aggregate.count
                : "-"
            }
            total={
              ActivityIdeasTotal
                ? ActivityIdeasTotal?.chat_dialog_aggregate?.aggregate.count
                : "-"
            }
            diagram={diagramIdeas}
            sectionName={"Сообщения"}
          />
        </GridColumns>
      </div>
    </CardBody>
  );
};

export default ActivityMetricsMiniCard;
