import React, { useEffect, useRef } from "react";
import { Empty } from "antd";

import { VisitContentLine, VisitContentLineWrapper } from "./styled/common";

import FormCard from "./formCard";

const BotsWrapper = ({
  data,
  botParent,
  setBotParent,
  setLeftArrowVisible,
  setRightArrowVisible,
  localTransition,
  setLocalTransition,
  botLocation,
  setBotLocation,
  needsLocation = false,
}) => {
  //просчитывает нужно ли показывать стрелочки для горизонтального скролла
  const visitContentRef = useRef();
  const visitContentLineRef = useRef();

  useEffect(() => {
    if (localTransition) {
      const LineScrollLeft = visitContentRef.current.scrollLeft;

      visitContentRef.current.scrollTo({
        left: LineScrollLeft + localTransition,
        behavior: "smooth",
      });

      setLocalTransition(null);
    }
  }, [localTransition]);

  const detectScrollPos = () => {
    const LineWrapperWidth = visitContentRef.current.offsetWidth;
    const LineWidth = visitContentLineRef.current.offsetWidth;

    const LineScrollLeft = visitContentRef.current.scrollLeft;

    if (LineWidth > LineWrapperWidth + LineScrollLeft) {
      setRightArrowVisible(true);
    } else {
      setRightArrowVisible(false);
    }

    if (LineScrollLeft > 0) {
      setLeftArrowVisible(true);
    } else {
      setLeftArrowVisible(false);
    }
  };

  useEffect(() => detectScrollPos(), [data]);

  const { dialog_bot = [] } = data ? data : {};

  return (
    <VisitContentLineWrapper ref={visitContentRef} onScroll={detectScrollPos}>
      <div ref={visitContentLineRef} style={{ width: "max-content" }}>
        <VisitContentLine>
          {dialog_bot?.length > 0 ? (
            dialog_bot?.map((botsProps = {}, i) => {
              const { name: bot_name } = botsProps;
              const { landing_link = {}, /*project_link = [],*/ id } =
                botsProps;
              const { landing = {}, landing_id: link_id } = landing_link
                ? landing_link
                : {};

              const { data: landingData = {} } = landing ? landing : {};

              const { url: url_domain, type_id: type } = landing ? landing : {};

              //const firstProject = project_link?.[0];
              //const { project = {} } = firstProject ? firstProject : {};
              //const { name: project_name } = project;

              const { location, title: landing_name } = landingData;

              let title;
              title = landing_name ? landing_name : bot_name;

              return (
                <FormCard
                  key={`KeyCard${i}`}
                  category={type}
                  description={title}
                  domain={url_domain}
                  id={link_id}
                  original_bot_id={id}
                  {...{
                    botParent,
                    setBotParent,
                    botLocation,
                    setBotLocation,
                  }}
                  location={location}
                  needsLocation={needsLocation}
                />
              );
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </VisitContentLine>
      </div>
    </VisitContentLineWrapper>
  );
};

export default BotsWrapper;
