const prepareDataAnswers = ({
  items = null, //опции для каждого вопросы
  answers = null, //ответы
  type = "variant",
}) => {
  if (items && answers) {
    //для type = 'rating'
    let answersCats;
    if (type === "rating") answersCats = items?.map(({ name }) => name);

    //подготовить основные данные для item
    let dialog_answers = items?.map(({ name }, i) => {
      let config = {
        option: name,
        value: 0,
        ordering: i,
      };

      if (type === "variants" || type === "rating" || type === 'polling')
        config = { ...config, itemCount: 0 };

      return config;
    });

    //убрать потом !!
    let orderNumbers = [];

    //суммировать value для каждого item
    answers?.map(({ message }) => {
      if (message?.attachment) {
        const { attachment } = message;

        //разветвление для {variant} и {variants, rating}
        if (type === "variant") {
          //
          const { attachment } = message;
          const { variant } = attachment;

          const orderNumber = Number(variant?.split("answer")?.[1]);

          if (!orderNumbers.includes(orderNumber))
            orderNumbers.push(orderNumber);

          if (dialog_answers[orderNumber]) {
            dialog_answers[orderNumber].value += 1;
          }
          //
        } else {
          if (attachment?.variants) {
            const { variants } = attachment;

            if (variants) {
              //разветвление для variants и rating
              if (type === "variants" || type === 'polling') {
                //
                variants?.map((tag) => {
                  if (typeof tag === "string") {
                    const orderNumber = Number(tag?.split("answer")?.[1]);
                    if (dialog_answers[orderNumber])
                      dialog_answers[orderNumber].value += 1;
                  }
                });
                //
              } else if (type === "rating") {
                //
                variants?.map(({ text, rate }) => {
                  const indexOfText = answersCats.indexOf(text);

                  dialog_answers[indexOfText].value += rate;
                });
                //
              }
            }
          }
        }
      }
    });

    return dialog_answers;
  }

  return null;
};

export { prepareDataAnswers };
