import styled from "styled-components";
import { Space } from "antd";

const SectionCardWrapper = styled(Space)`
  width: 100%;
  display: block;

  &&[data-type="stretch"] {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }
`;

const SectionCard = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  border: 1px solid #DCE1E8;
  padding: 7px;
  width: 100%;
  min-width: ${({ minwidth }) => (minwidth ? `${minwidth}px` : "auto")};
  max-width: ${({ minwidth }) => (minwidth ? `${minwidth}px` : "auto")};
  min-height: ${({ minheight }) => (minheight ? `${minheight}px` : "auto")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};

  &&&&& * {
    line-height: 1.42;
  }

  &&&[data-selected="active"] {
    border: 1px solid black;
    translateY(-.15rem)
  }

  &&&[data-type="button"] {
    border: 1px solid white;
  }

  &&&[data-type="button"],
  &&&[data-type="clickable"] {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
  }

  &&&[data-type="button"]:hover,
  &&&[data-type="clickable"]:hover {
    transform: translateY(-0.25rem);
    cursor: pointer;
  }
`;

SectionCard.AddNew = styled(Space)`
  && * {
    color: #2d2d2d;
    font-weight: 500;
    text-align: center;
  }

  &&::before {
    content: "";
    width: 28px;
    height: 28px;
    background: url("/icons/plus_.svg");
    margin-bottom: 11px;
  }
`;

SectionCard.Flex = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
`;

SectionCard.Label = styled.div`
  display: flex;
  color: #989898;
  align-items: center;

  &&::before {
    content: "";
    margin-right: ${({ color }) => (color ? "8px" : "0")};
    background: ${({ color }) => (color ? color : "none")};
    border-radius: 50%;
    width: ${({ color }) => (color ? "16px" : "none")};
    height: ${({ color }) => (color ? "16px" : "none")};
  }
`;

SectionCard.Number = styled.div`
  font-size: 24px;
`;

SectionCard.Hr = styled.hr`
  && {
    width: 100%;
    margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : "4px")};
    margin-bottom: ${({ marginBottom }) =>
      marginBottom ? `${marginBottom}px` : "4px"};
  }
`;

export { SectionCard, SectionCardWrapper };
