import React, {  } from "react";
import stc from "string-to-color";

import { Typography } from "antd";
const { Paragraph } = Typography;

import { SectionCard } from "../../../../../helpers/section-card";

import { StyledSpace } from "./styled/common";

const types = {
  1: { name: "Сбор идей", color: "#FF876E" },
  2: { name: "Опросник", color: "#FFC355" },
  3: { name: "Голосование", color: "#1EC198" },
  default: { name: "Инструмент", color: "#ececec" },
};

/**
 *
 * Visit Line
 *
 */

const FormCard = ({
  category,
  description,
  original_bot_id,
  botParent,
  setBotParent,
  location,
  setBotLocation,
  needsLocation = false,
}) => {
  const { name } = types?.[category] || types.default;

  const ItemColor = description ? stc(description) : "grey";

  return (
    <SectionCard
      justifyContent="space-between"
      alignItems="baseline"
      minwidth={"216"}
      minheight={"105"}
      data-selected={botParent === original_bot_id && "active"}
      data-type="clickable"
      onClick={() => {
        setBotParent(original_bot_id);
        if (location && needsLocation) setBotLocation(location);
      }}
    >
      <StyledSpace direction="vertical" size={16}>
        <SectionCard.Flex justifyContent="space-between">
          <SectionCard.Label color={ItemColor}>{name}</SectionCard.Label>
        </SectionCard.Flex>

        <Paragraph ellipsis={{ rows: 2 }}>{description}</Paragraph>
      </StyledSpace>
    </SectionCard>
  );
};

export default FormCard;
