import { gql } from "@apollo/client";

//Сохранение секций
const CREATES_SECTIONS_NODES_MUTATION = gql`
  mutation CreateNodes(
    $sections: [dialog_voc_segment_insert_input!]!
    $node_sections: [dialog_voc_segment_node_link_insert_input!]!
    $nodes: [dialog_voc_node_sat_insert_input!]!
    $answers: [dialog_voc_answer_sat_insert_input!]!
  ) {
    insert_sections(objects: $sections) {
      affected_rows
    }
    insert_node_sections(objects: $node_sections) {
      affected_rows
    }
    insert_nodes(
      objects: $nodes
      on_conflict: {
        constraint: voc_node_sat_key_voc_id_key
        update_columns: id
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
    insert_answers(objects: $answers) {
      affected_rows
    }
  }
`;

// Удаление секций
const DELETE_SECTIONS_NODES_MUTATION = gql`
  mutation DeleteMutation($bot_id: uuid) {
    delete_answers(where: { voc: { bot_link: { bot_id: { _eq: $bot_id } } } }) {
      affected_rows
    }
    delete_nodes(where: { voc: { bot_link: { bot_id: { _eq: $bot_id } } } }) {
      affected_rows
    }
    delete_sections(
      where: { voc: { bot_link: { bot_id: { _eq: $bot_id } } } }
    ) {
      affected_rows
    }
    delete_node_sections(
      where: { voc: { bot_link: { bot_id: { _eq: $bot_id } } } }
    ) {
      affected_rows
    }
  }
`;

// Создание словаря для ветвистого опроса
const UPSERT_VOC_MUTATION = gql`
  mutation UpsertVoc($bot_id: uuid, $key: String) {
    createVoc(
      object: { id: $bot_id, key: $key }
      on_conflict: { constraint: voc_hub_pk, update_columns: id }
    ) {
      id
    }
    createBotVocLink(
      object: { bot_id: $bot_id, voc_id: $bot_id }
      on_conflict: {
        constraint: bot_voc_link_bot_id_voc_id_key
        update_columns: voc_id
      }
    ) {
      voc_id
    }
  }
`;

const BOT_DIALOG_QUERY = gql`
  query BotDialog($bot_id: uuid) {
    dialog_bot(where: { id: { _eq: $bot_id } }) {
      id
      voc_link {
        voc {
          sections(order_by: { segment: asc }) {
            segment
            name
            lead
            nodes_link(order_by: { node: { ordering: asc } }) {
              node {
                uischema
                schema
                key
                id
                text
                type
                is_required
                ordering
                answers(order_by: { ordering: asc }) {
                  id
                  name
                  value
                  goto
                  ordering
                }
              }
            }
          }
        }
      }
    }
  }
`;

export {
  CREATES_SECTIONS_NODES_MUTATION,
  DELETE_SECTIONS_NODES_MUTATION,
  UPSERT_VOC_MUTATION,
  BOT_DIALOG_QUERY,
};
