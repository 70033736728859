import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { loadProjects } from "../../../../actions/app";
import FirstProject from "../CreateProject";
import PageWrap from "../../../../components/page-wrap";
//import PageTitle from "../../../../components/page-title";
import PageContent from "../../../../components/page-content";
import AsyncComponent from "../../../../components/async-component";

const ProjectsPage = () => {
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { is_novice, is_preparing } = auth;

  useEffect(() => {
    dispatch(loadProjects());
  }, [dispatch]);

  return is_novice || is_preparing ? (
    <FirstProject />
  ) : (
    <PageWrap>
      <PageContent>
        <AsyncComponent component={() => import("./content")} />
      </PageContent>
    </PageWrap>
  );
};

export default ProjectsPage;
