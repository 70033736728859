import { gql } from "@apollo/client";

const PLACE_QUERY = gql`
  query placeQuery($query: String) {
    suggestions(
      input: {
        query: $query
        count: 10
        from_bound: { value: "city" }
        to_bound: { value: "settlement" }
      }
    ) @rest(type: "Suggestion", path: "", endpoint: "address", method: "POST") {
      value
      data @type(name: "Place") {
        country
        region
        city
        geo_lat
        geo_lon
      }
    }
  }
`;

const SIGN_UP_MUTATION = gql`
  mutation signUpMutation($name: String, $email: String, $password: String) {
    token(
      input: {
        name: $name
        email: $email
        password: $password
        inn: ""
        company: { value: "", data: { inn: "" } }
      }
    ) @rest(type: "Token", path: "", endpoint: "signUp", method: "POST") {
      access_token
      refresh_token
    }
  }
`;

const SIGN_IN_MUTATION = gql`
  mutation signInMutation($email: String, $password: String) {
    token(input: { email: $email, password: $password })
      @rest(type: "Token", path: "", method: "POST") {
      access_token
      refresh_token
    }
  }
`;

const SIGN_OUT_MUTATION = gql`
  mutation signOutMutation {
    response(input: {})
      @rest(type: "LogoutResponse", path: "", endpoint: "signOut") {
      success
    }
  }
`;

const USER_EXISTS_QUERY = gql`
  query userExistsQuery($query: String) {
    response @rest(type: "UserExistsResponse", path: "", endpoint: "check") {
      exists
    }
  }
`;

const PASSWORD_RECOVERY_MUTATION = gql`
  mutation passwordRecoveryMutation($email: String) {
    response(input: { email: $email })
      @rest(
        type: "RecoveryResponse"
        path: "/send"
        endpoint: "recovery"
        method: "POST"
      ) {
      success
      count
    }
  }
`;

const PASSWORD_UPDATE_MUTATION = gql`
  mutation passwordUpdateMutation($password: String) {
    response(input: { password: $password })
      @rest(
        type: "UpdateResponse"
        path: "/update"
        endpoint: "recovery"
        method: "POST"
      ) {
      success
    }
  }
`;

// const USER_EXISTS_MUTATION = gql`
//   mutation userExistsMutation($email: String) {
//     user(input: { email: $email })
//       @rest(type: "User", path: "", endpoint: "exists", method: "POST") {
//       exists
//     }
//   }
// `;
//
// const USER_QUERY = gql`
//   query userQuery {
//     user {
//       meta {
//         email
//         first_name
//       }
//       id
//     }
//   }
// `;

const METRICS_SEN_SUMMARY_QUERY = gql`
  query SentimentSummary($project_id: [uuid!]) {
    pos: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { positive: { _eq: 1 } }
          { negative: { _eq: 0 } }
          { project_id: { _in: $project_id } }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
    neg: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { positive: { _eq: 0 } }
          { negative: { _eq: 1 } }
          { project_id: { _in: $project_id } }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
    posneg: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { positive: { _eq: 1 } }
          { negative: { _eq: 1 } }
          { project_id: { _in: $project_id } }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
    neutral: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { positive: { _eq: 0 } }
          { negative: { _eq: 0 } }
          { project_id: { _in: $project_id } }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
  }
`;
// $sent_filter:
//  -1 = negative
//   0 = neutral
//   1 = positive
//   2 = negative + positive

// $order_by
// {
//   "order_by": {
//     "dialog": {"cr": "asc"/"desc"} # sort by date asc or desc
//     "sentiment": "asc_nulls_last" # negative first, then positive, then negative+positive, neutral last
//     "sentiment": "desc_nulls_last" # negative+positive first, then positive, then negative, neutral last
//      хз как сортировать, чтоб первыми шли позитивные
//   }
// }

const METRICS_SEN_QUERY = gql`
  query MyQuery(
    $project_id: [uuid!]
    $start_date: timestamptz
    $end_date: timestamptz
    $sent_filter: Int
    $order_by: [metric_met_sen_rep_order_by!]
  ) {
    metric_met_sen_rep(
      where: {
        _and: [
          { project_id: { _in: $project_id } }
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { sentiment: { _eq: $sent_filter } }
        ]
      }
      order_by: $order_by
    ) {
      bot {
        id
        name
      }
      dialog {
        id
        cr
      }
      project {
        id
        name
      }
      token
      is_published
      positive
      negative
    }
  }
`;

const METRICS_SENTABLE_QUERY = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $search_by_string: String
    $order_by: [metric_met_sen_rep_order_by!]
    $project_id: uuid
    $sentiment_id: Int
    $parent_id: Boolean
  ) {
    metric_met_sen_rep(
      where: {
        _and: [
          { dialog: { parent_id: { _is_null: $parent_id } } }
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { token: { _ilike: $search_by_string } }
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
          { sentiment: { _eq: $sentiment_id } }
        ]
      }
      order_by: $order_by
    ) {
      bot_id
      cnt
      dialog_id
      is_published
      negative
      platform_id
      positive
      project_id
      sentiment
      token
      bot {
        landing_link {
          landing {
            data(path: "title")
          }
        }
      }
      dialog {
        cr
      }
      project {
        id
        name
      }
    }
  }
`;

const METRICS_SENGRAPHICS_SUMMARY_QUERY = gql`
  query SentimentSummary(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    all_pos: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { positive: { _eq: 1 } }
          { negative: { _eq: 0 } }
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
    all_neg: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { positive: { _eq: 0 } }
          { negative: { _eq: 1 } }
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
    all_neutral: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { positive: { _eq: 0 } }
          { negative: { _eq: 0 } }
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
    comments_pos: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { dialog: { parent_id: { _is_null: false } } }
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { positive: { _eq: 1 } }
          { negative: { _eq: 0 } }
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
    comments_neg: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { dialog: { parent_id: { _is_null: false } } }
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { positive: { _eq: 0 } }
          { negative: { _eq: 1 } }
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
    comments_neutral: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { dialog: { parent_id: { _is_null: false } } }
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { positive: { _eq: 0 } }
          { negative: { _eq: 0 } }
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
    baykalsk_pos: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { dialog: { parent_id: { _is_null: true } } }
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { positive: { _eq: 1 } }
          { negative: { _eq: 0 } }
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
    baykalsk_neg: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { dialog: { parent_id: { _is_null: true } } }
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { positive: { _eq: 0 } }
          { negative: { _eq: 1 } }
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
    baykalsk_neutral: metric_met_sen_rep_aggregate(
      where: {
        _and: [
          { dialog: { parent_id: { _is_null: true } } }
          { dialog: { cr: { _gte: $start_date } } }
          { dialog: { cr: { _lte: $end_date } } }
          { positive: { _eq: 0 } }
          { negative: { _eq: 0 } }
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
        ]
      }
    ) {
      aggregate {
        count(columns: positive)
      }
    }
  }
`;

/* Metrics Page */
const FORMS_GRAPHICS_QUERY = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
  ) {
    chat_dialogs(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
          { idea: { id: { _is_null: true } } }
        ]
      }
      order_by: { cr: desc }
    ) {
      cr
    }
  }
`;

const FORMS_TOTAL_QUERY = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    chat_dialog_aggregate(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { idea: { id: { _is_null: true } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const FORMS_DAY_QUERY = gql`
  query MyQuery(
    $project_id: uuid
    $start_day: timestamptz
    $end_day: timestamptz
  ) {
    chat_dialog_aggregate(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { cr: { _gte: $start_day } }
          { cr: { _lte: $end_day } }
          { idea: { id: { _is_null: true } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const COMMENTS_GRAPHICS_QUERY = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
  ) {
    chat_dialogs(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
          { parent_id: { _is_null: false } }
        ]
      }
      order_by: { cr: desc }
    ) {
      cr
    }
  }
`;

/**
 *
 * Общая сводка.
 * График. Статистика активности пользоателей по дням
 */

const ACTIVITY_USERS_GRAPHICS_QUERY = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
    $bot_id: uuid
  ) {
    chats(
      where: {
        _and: [
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
          { bot_id: { _eq: $bot_id } }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
        ]
      }
      order_by: { cr: desc_nulls_last }
    ) {
      cr
    }
  }
`;

const ACTIVITY_GRAPHICS_QUERY = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
    $parent_id: uuid_comparison_exp
    $idea: dialog_chat_idea_sat_bool_exp
    $bot_id: uuid
  ) {
    chat_dialogs(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { chat: { bot_id: { _eq: $bot_id } } }
          { parent_id: $parent_id }
          { idea: $idea }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
        ]
      }
      order_by: { cr: desc }
    ) {
      cr
    }
  }
`;

/**
 *
 *  Комменты
 */

const ACTIVITY_GRAPHICS_COMMENTS_QUERY = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
    $bot_id: uuid
  ) {
    chat_dialogs(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { chat: { bot_id: { _eq: $bot_id } } }
          { parent_id: { _is_null: false } }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
        ]
      }
      order_by: { cr: desc }
    ) {
      cr
    }
  }
`;

/**
 *
 * лайки
 */

const ACTIVITY_GRAPHICS_LIKES_QUERY = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
    $bot_id: uuid
  ) {
    idea_ratings(
      where: {
        _and: [
          {
            idea: {
              chat: {
                bot: {
                  landing_link: {
                    landing: { project_id: { _eq: $project_id } }
                  }
                }
              }
            }
          }
          { idea: { chat: { bot_id: { _eq: $bot_id } } } }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
        ]
      }
      order_by: { cr: desc }
    ) {
      cr
    }
  }
`;

/**
 *
 * Инструменты / Анкеты в Общей сводке
 */

const QUESTIONNAIRE_SUMMARY_QUERY = gql`
  query MyQuery($project_id: uuid) {
    dialog_bot(
      where: { landing_link: { landing: { project_id: { _eq: $project_id } } } }
    ) {
      id
      name
      platform {
        name
      }
      options
      domain
      landing_link {
        landing {
          type_id
          data(path: "title")
          url
        }
        landing_id
      }
    }
  }
`;

//Запрос ботов для страницы "Статистика" для получения полной инфы о боте
const QUESTIONNAIRE_SUMMARY_QUERY1 = gql`
  query MyQuery($project_id: uuid) {
    dialog_bot(where: { project_link: { project_id: { _eq: $project_id } } }) {
      id
      name
      cr
      platform {
        name
      }
      options
      domain
      project_link {
        project {
          name
        }
      }
      landing_link {
        landing {
          type_id
          data
          url
        }
        landing_id
      }
    }
  }
`;

/**
 *
 * Кол-во пользователей прошедших анкету.
 * Считается на основе кол-ва диалогов. Некоторые пользователей проходят анкету несколько раз
 */

const ACTIVITY_USERS_METRICS = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
    $bot_id: uuid
  ) {
    chat_aggregate(
      where: {
        _and: [
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
          { bot_id: { _eq: $bot_id } }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 *
 * Все вопросы из анкеты, относящиеся к определенному боту
 *
 */

const ANSWERS_DIALOG_VOC_HUB = gql`
  query MyQuery($project_id: uuid, $bot_id: uuid) {
    vocs(
      where: {
        nodes: {
          bots_link: {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
              id: { _eq: $bot_id }
            }
          }
        }
      }
    ) {
      id
      nodes(order_by: { ordering: asc_nulls_last }) {
        key
        text
        type
        answers {
          name
          ordering
        }
        ordering
        id
      }
    }
  }
`;

const ANSWERS_DIALOG_HUB = gql`
  query MyQuery(
    $project_id: uuid
    $bot_id: uuid
    $limit: Int
    $node_key: String
  ) {
    chat_dialogs(
      limit: $limit
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
                id: { _eq: $bot_id }
              }
            }
          }
          { message: { _contains: { is_answer: true } } }
          { node: { _eq: $node_key } }
        ]
      }
    ) {
      id
      cr
      message
      chat {
        ideas {
          id
          cr
        }
      }
    }
  }
`;

/* Суммарное количество анкет, выводимое из количества раундов */
const completedFormsQuery = gql`
  query MyQuery($project_id: uuid, $bot_id: uuid) {
    chats(
      where: {
        bot: {
          landing_link: { landing: { project_id: { _eq: $project_id } } }
          id: { _eq: $bot_id }
        }
      }
    ) {
      round
    }
  }
`;

/**
 *
 * Activity graphics Query
 *
 */

const ACTIVITY_QUESTIONNAIRE_GRAPHICS_QUERY = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
    $bot_id: uuid
  ) {
    chats(
      where: {
        _and: [
          {
            bot: {
              landing_link: { landing: { project_id: { _eq: $project_id } } }
            }
          }
          { bot_id: { _eq: $bot_id } }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
        ]
      }
      order_by: { cr: desc_nulls_last }
    ) {
      cr
    }
  }
`;

/**
 *
 *
 */

const COMMENTS_TOTAL_QUERY = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    chat_dialog_aggregate(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { parent_id: { _is_null: false } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const COMMENTS_DAY_QUERY = gql`
  query MyQuery(
    $project_id: uuid
    $start_day: timestamptz
    $end_day: timestamptz
  ) {
    chat_dialog_aggregate(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { cr: { _gte: $start_day } }
          { cr: { _lte: $end_day } }
          { parent_id: { _is_null: false } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const MESS_GRAPHICS_QUERY = gql`
  query MyQuery(
    $start_date: timestamptz
    $end_date: timestamptz
    $project_id: uuid
  ) {
    chat_dialogs(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { cr: { _gte: $start_date } }
          { cr: { _lte: $end_date } }
          { parent_id: { _is_null: true } }
        ]
      }
      order_by: { cr: desc }
    ) {
      cr
    }
  }
`;

const MESS_TOTAL_QUERY = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    chat_dialog_aggregate(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { idea: { id: { _is_null: false } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const MESS_DAY_QUERY = gql`
  query MyQuery(
    $project_id: uuid
    $start_day: timestamptz
    $end_day: timestamptz
  ) {
    chat_dialog_aggregate(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { cr: { _gte: $start_day } }
          { cr: { _lte: $end_day } }
          { idea: { id: { _is_null: false } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const ACTIVITY_TOTAL_QUERY = gql`
  query MyQuery(
    $project_id: uuid
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    chat_dialog_aggregate(
      where: {
        chat: {
          bot: {
            landing_link: { landing: { project_id: { _eq: $project_id } } }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const ACTIVITY_DAY_QUERY = gql`
  query MyQuery(
    $project_id: uuid
    $start_day: timestamptz
    $end_day: timestamptz
  ) {
    chat_dialog_aggregate(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { cr: { _gte: $start_day } }
          { cr: { _lte: $end_day } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const ACTIVITY_WEEK_QUERY = gql`
  query MyQuery(
    $project_id: uuid
    $start_week: timestamptz
    $end_week: timestamptz
  ) {
    chat_dialog_aggregate(
      where: {
        _and: [
          {
            chat: {
              bot: {
                landing_link: { landing: { project_id: { _eq: $project_id } } }
              }
            }
          }
          { cr: { _gte: $start_week } }
          { cr: { _lte: $end_week } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export {
  PLACE_QUERY,
  SIGN_IN_MUTATION,
  SIGN_UP_MUTATION,
  SIGN_OUT_MUTATION,
  USER_EXISTS_QUERY,
  // USER_QUERY,
  // USER_EXISTS_MUTATION,
  PASSWORD_RECOVERY_MUTATION,
  PASSWORD_UPDATE_MUTATION,
  METRICS_SEN_SUMMARY_QUERY,
  METRICS_SENGRAPHICS_SUMMARY_QUERY,
  METRICS_SEN_QUERY,
  METRICS_SENTABLE_QUERY,
  //
  ACTIVITY_GRAPHICS_QUERY,
  ACTIVITY_TOTAL_QUERY,
  ACTIVITY_DAY_QUERY,
  ACTIVITY_WEEK_QUERY,
  //
  COMMENTS_GRAPHICS_QUERY,
  COMMENTS_TOTAL_QUERY,
  COMMENTS_DAY_QUERY,
  //
  MESS_GRAPHICS_QUERY,
  MESS_TOTAL_QUERY,
  MESS_DAY_QUERY,
  //
  FORMS_GRAPHICS_QUERY,
  FORMS_TOTAL_QUERY,
  FORMS_DAY_QUERY,
  //
  ACTIVITY_QUESTIONNAIRE_GRAPHICS_QUERY,
  QUESTIONNAIRE_SUMMARY_QUERY,
  QUESTIONNAIRE_SUMMARY_QUERY1,
  //
  ACTIVITY_USERS_METRICS,
  //
  ACTIVITY_USERS_GRAPHICS_QUERY,
  //
  ACTIVITY_GRAPHICS_COMMENTS_QUERY,
  //
  ACTIVITY_GRAPHICS_LIKES_QUERY,
  //
  ANSWERS_DIALOG_VOC_HUB,
  ANSWERS_DIALOG_HUB,
  //
  completedFormsQuery,
};
