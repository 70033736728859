/**
 * External Dependencies
 */
import React, { Component } from "react";
import { withRouter, BrowserRouter as Router, Route } from "react-router-dom";
import { Provider, connect } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ReduxThunk from "redux-thunk";
import { ConfigProvider, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ru from "antd/es/locale/ru_RU";
import moment from "moment";
import "moment/locale/ru";

/**
 * Internal Dependencies
 */
import "./components/animated-route";
import client from "./clients/gql-client";
import reducers from "./reducers";
import Routes from "./Routes";
import PageYaybar from "./components/page-yaybar";
import PageNavbar from "./components/page-navbar";
import PageToasts from "./components/page-toasts";

import { loadProjects, loadCurrentProject } from "./actions/app";

import { authCheck, authUpdate } from "./actions/auth";
import Tokenizer from "./helpers/tokenizer";
import { ApolloProvider } from "@apollo/client";

const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore);
const $html = window.jQuery("html");
const $body = window.jQuery("body");

/**
 * Component PageWrap
 */
class PageWrap extends Component {
  constructor(props) {
    super(props);

    this.maybeCheckAuth = this.maybeCheckAuth.bind(this);
    this.maybeUpdateGlobalSettings = this.maybeUpdateGlobalSettings.bind(this);
    this.maybeScrollPageToTop = this.maybeScrollPageToTop.bind(this);
  }

  componentDidMount() {
    const { authCheck, history } = this.props;

    // check auth
    !this.isSignPage() &&
      authCheck()
        .then(() => {
          this.preloadData();
          // this.isSignPage() && history.push("/");
        })
        .catch(() => history.push("/sign-in"));

    this.maybeUpdateGlobalSettings();
  }

  preloadData() {
    const { loadProjects, loadCurrentProject } = this.props;

    loadProjects().then(() => {});

    loadCurrentProject().then(() => {});
  }

  componentDidUpdate(prevProps) {
    this.maybeCheckAuth(prevProps);
    this.maybeUpdateGlobalSettings(prevProps);
    this.maybeScrollPageToTop(prevProps);
  }

  isSignPage(check) {
    const { location } = this.props;
    if (!check) {
      check = location.pathname;
    }
    const authPaths = ["/sign-in", "/sign-up", "/forgot", "/recovery"];

    return authPaths.includes(check);
  }

  maybeCheckAuth(prevProps) {
    const { auth, authUpdate, history, location } = this.props;

    let newRedirect = false;

    const referrerUrl = !this.isSignPage(auth.referrer) ? auth.referrer : "/";

    // Redirect from SignIn page.
    if (this.isSignPage() && Tokenizer.verify(auth.access_token)) {
      newRedirect = referrerUrl;

      // Redirect to SignIn page.
    } else if (!this.isSignPage() && !Tokenizer.verify(auth.access_token)) {
      newRedirect = "/sign-in";

      // Check if use logged out or logged in.
    } else if (prevProps && auth.access_token !== prevProps.auth.access_token) {
      newRedirect = Tokenizer.verify(auth.access_token)
        ? referrerUrl
        : "/sign-in";
    }

    // Redirect.
    if (newRedirect) {
      authUpdate({
        referrer: location.pathname,
      });

      history.push(newRedirect);
    }
  }

  maybeUpdateGlobalSettings(prevProps) {
    const { settings } = this.props;

    // night mode.
    if (prevProps && prevProps.settings.night_mode !== settings.night_mode) {
      if (settings.night_mode) {
        $html.addClass("rui-night-mode");
        import("./style-night.scss");
      } else {
        $html.removeClass("rui-night-mode");
      }
    }
    if (!prevProps && settings.night_mode) {
      $html.addClass("rui-night-mode");
      import("./style-night.scss");
    }

    // spitlight mode.
    if (
      prevProps &&
      prevProps.settings.spotlight_mode !== settings.spotlight_mode
    ) {
      if (settings.spotlight_mode) {
        $body.addClass("rui-spotlightmode");
      } else {
        $body.removeClass("rui-spotlightmode");
      }
    }
    if (!prevProps && settings.spotlight_mode) {
      $body.addClass("rui-spotlightmode");
    }

    // section lines.
    if (
      prevProps &&
      prevProps.settings.show_section_lines !== settings.show_section_lines
    ) {
      if (settings.show_section_lines) {
        $body.addClass("rui-section-lines");
      } else {
        $body.removeClass("rui-section-lines");
      }
    }
    if (!prevProps && settings.show_section_lines) {
      $body.addClass("rui-section-lines");
    }

    // sidebar small.
    if (
      prevProps &&
      prevProps.settings.sidebar_small !== settings.sidebar_small
    ) {
      if (settings.sidebar_small) {
        $body.addClass("yay-hide");
      } else {
        $body.removeClass("yay-hide");
      }
    }
    if (!prevProps && settings.sidebar_small) {
      $body.addClass("yay-hide");
    }
  }

  maybeScrollPageToTop(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  render() {
    const { auth, location } = this.props;

    return (
      <TransitionGroup>
        {!this.isSignPage() && <PageToasts />}
        <Route>
          {Tokenizer.verify(auth.access_token) && (
            <>
              {!auth.is_preparing && <Route component={PageYaybar} />}
              <Route component={PageNavbar} />
            </>
          )}
        </Route>
        <CSSTransition
          key={location.pathname}
          timeout={this.isSignPage() ? 0 : 300}
          classNames="rui-router-transition"
          unmountOnExit
        >
          <Routes location={location} />
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

const PageWrapWithState = connect(
  ({ auth, settings }) => ({
    auth,
    settings,
  }),
  {
    loadProjects,
    loadCurrentProject,
    authCheck,
    authUpdate,
  }
)(withRouter(PageWrap));

/**
 * Component App
 */
class App extends Component {
  constructor(props) {
    super(props);

    // create redux store.
    this.store = createStoreWithMiddleware(reducers);

    moment.locale("ru");
    Spin.setDefaultIndicator(<LoadingOutlined />);
  }

  render() {
    const locale = {
      ...ru,
      DatePicker: {
        ...ru.DatePicker,
        lang: {
          ...ru.DatePicker.lang,
          rangePlaceholder: ["Начало", "Окончание"],
        },
      },
    };

    return (
      <Provider store={this.store}>
        <ApolloProvider {...{ client }}>
          <ConfigProvider {...{ locale }}>
            <Router>
              <PageWrapWithState />
            </Router>
          </ConfigProvider>
        </ApolloProvider>
      </Provider>
    );
  }
}

export default App;
