import { createGlobalStyle } from "styled-components";

const colors = {
  accent: "#FE9C08",
  grayText: "#979797",
  blackText: "#171717",
  grayBorder: "#d9d9d9",
};

const { accent, grayText, blackText, grayBorder } = colors;

const GlobalStyle = createGlobalStyle`
  :root {
    --color-accent: ${accent};
    --font-fallback: "Roboto", 'Helvetica Neue', Arial, sans-serif;
    --font-inter: Inter, 'Helvetica Neue', Arial, sans-serif;
    --font-roboto: Roboto, 'Helvetica Neue', Arial, sans-serif;
    --heading-color: #323232;
    --text-gray-color: ${grayText};
    --text-black-color: ${blackText};
    --border-gray-color: ${grayBorder};

  }

  html, body, #__next {
    height: 100%;
    font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;
  }
  
  .rui-page-title {
    padding: 16px 30px 0 !important;
  }

.ant-btn {
  border-radius: 49px;

}
  .ant-btn.ant-btn-secondary {
    color: rgba(0, 0, 0, 0.85);
    background-color: white;
    border: 1px solid #5C5EF9;
    //box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      color: rgba(0, 0, 0, 0.85);
      background-color: white;
      border: 1px solid #5C5EF9;
    }
  }

  .ant-checkbox-inner {
    border-radius: 4px;
  }

  .ant-btn.ant-btn-primary {
    color: white;
    background-color: #5C5EF9;
    border: 1px solid #5C5EF9;
    border-radius: 49px;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      color: white;
      background-color: #5C5EF9;
      border: 1px solid #5C5EF9;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: transparent !important;
    -webkit-animation-name: autofill !important;
    -webkit-animation-fill-mode: both !important;
  }
  
  .custom-antd-popover {
    background-color: white;
    width: 291px;
    border: 0;
    border-radius: 8px;
    font-size: 12px;
    padding: 2px;
    .ant-popover-arrow {
      opacity: 0;
    }
    
    .ant-popover-inner {
      background-color: white;
      border: 0;
      border-radius: 8px;
    }
  }
`;

export default GlobalStyle;
