/**
 * Created by i.chernyakov on 30.01.2019.
 */
import { addToast } from "../actions";

const recoveryTries = "Recovery tries";
const userExists = "Пользователь с таким email уже есть в системе";
const invalidToken = "Недействительный токен";
const errorVocabulary = {
  "User exists": userExists,
  "User is exists": userExists,
  "User not found": "Пользователь не найден",
  "Wrong password": "Вы ввели неверный пароль",
  "JWT user not found": "Пожалуйста, авторизуйтесь",
  "User is not exist": "Пользователь не найден",
  "Empty password provided": "Отправлен пустой пароль",
  "No token given": invalidToken,
  "Not enough segments": invalidToken,
  "Signature has expired": invalidToken,
  "There is no recovery record for this user": invalidToken,
  [recoveryTries]: "Слишком много попыток смены пароля, пожалуйста, подождите",
};

const translateError = (errorText) =>
  errorVocabulary?.[
    errorText.includes(recoveryTries) ? recoveryTries : errorText
  ] || errorText;

const getErrorStruct = (errorData) => {
  const { error, message, fields } = { ...errorData };
  const errorMessage = error || message;

  const errorStruct = {
    error: "Ошибка на стороне сервера",
    fields: [],
  };

  if (errorData) {
    return {
      ...errorStruct,
      ...(errorMessage
        ? {
            error: translateError(errorMessage),
          }
        : {}),
      ...(fields
        ? {
            fields,
          }
        : {}),
    };
  } else {
    return errorStruct;
  }
};

export const recognizeError = (error) => {
  const { response, networkError } = { ...error };
  const commonError = response?.data;
  const graphQLError = networkError?.result;

  return getErrorStruct(
    ...(graphQLError ? [graphQLError] : commonError ? [commonError] : [])
  );
};

export default (error, state, dispatch) => {
  // const errorText = R.path(['reason'], error);

  if (!dispatch) return;

  const errorStruct = recognizeError(error, state);

  dispatch(
    addToast({
      title: "Внимание",
      content: errorStruct.error,
      time: new Date(),
      duration: 4000,
      color: "danger",
    })
  );
};
